import React, { useEffect, useContext, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Modal,
} from "@mui/material";
import menuitem from "../../images/defualtimage.avif";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import CategoryCreation from "../CategoryCreation";
import AWS from "aws-sdk";
import imageCompression from "browser-image-compression";
import CloseIcon from "@mui/icons-material/Close";
import { BusinessContext } from "../BusinessContext";
import axiosInstance from "../axios";
import { Skeleton } from "@mui/material";
import { CircularProgress } from "@mui/material";

function MenuContent() {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    endpoint: process.env.REACT_APP_AWS_BASE_URL,
    s3ForcePathStyle: true,
    signatureVersion: "v4",
  });
  const s3 = new AWS.S3();

  const [activeIndex, setActiveIndex] = useState(0);
  const [categoriesData, setCategoriesData] = useState([]);
  const [menuItemsData, setMenuItemsData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddCategory, setShowAddCategory] = useState(false);
  // const [showFull, setShowFull] = useState(false);
  const [open, setOpen] = useState(false);
  // const [addItem, setAddItem] = useState(false);
  const [newItem, setNewItem] = useState({
    menu_name: "",
    menu_image: "",
    menu_description: "",
    price: "",
    imagePreview: null,
    isPreviewMode: false,
  });
  const [expandedItems, setExpandedItems] = useState({});
  const [editItem, setEditItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addMenuLoader , setAddMenuLoader] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [menuitemsloading, setMenuItemsloading] = useState(true);

  const { selectedBusinessId } = useContext(BusinessContext);

  useEffect(() => {
    if (selectedBusinessId) {
      fetchCategories(selectedBusinessId);
      menuItems(selectedBusinessId);
      setActiveIndex(0);
    }
  }, [selectedBusinessId]);

  useEffect(() => {
    if (!showAddCategory) {
      fetchCategories();
    }
  }, [showAddCategory]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem((prev) => ({
      ...prev,
      [name]: value, // Ensure the field updates correctly
    }));
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      return file;
    }
  };

  const uploadToS3 = async (file) => {
    const fileName = `${Date.now()}-${file.name}`;
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileName,
      Body: file,
      ContentType: file.type,
      ACL: "public-read",
    };

    try {
      const result = await s3.upload(params).promise();
      return `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.sfo3.digitaloceanspaces.com/${fileName}`;
    } catch (error) {
      console.error("Error uploading to S3:", error);
      throw error;
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true);
      try {
        // Compress the image
        const compressedFile = await compressImage(file);

        // Upload to S3
        const imageUrl = await uploadToS3(compressedFile);

        // Create preview URL
        const previewUrl = URL.createObjectURL(file);
        setNewItem((prev) => ({
          ...prev,
          menu_image: imageUrl,
          imagePreview: previewUrl,
          isPreviewMode: true,
        }));
      } catch (error) {
        console.error("Error handling file upload:", error);
      }
      setLoading(false);
    }
  };
  const handleRemoveImage = () => {
    setNewItem((prev) => ({
      ...prev,
      menu_image: "",
      imagePreview: null,
      isPreviewMode: false,
    }));
    setLoading(false);
  };

  const fetchCategories = async () => {
    setCategoriesLoading(true);
    try {
      const response = await axiosInstance.post(
        "payments.php?method=menu_categories",
        {
          business_id: selectedBusinessId,
        }
      );
      setCategoriesData(response.data.categories || []);
    } catch (error) {
      console.log("Error fetching categories", error);
    }
    setCategoriesLoading(false);
  };

  const menuItems = async (category_id) => {
    setSearchTerm("");
    setMenuItemsloading(true);
    try {
      const itemResponse = await axiosInstance.post(
        "payments.php?method=category_menu_items",
        {
          business_id: selectedBusinessId,
          business_menu_category_id: category_id,
        }
      );
      setMenuItemsData(itemResponse.data?.items || []);
    } catch (error) {
      console.log("Error fetching menu items", error);
    }
    setMenuItemsloading(false);
  };

  const addMenuItem = async () => {
    setAddMenuLoader(true);
    try {
      const selectedCategory = categoriesData[activeIndex];
      const payload = {
        menu_name: newItem.menu_name,
        business_id: selectedBusinessId,
        menu_image: newItem.menu_image,
        menu_description: newItem.menu_description,
        price: newItem.price,
        business_menu_category_id: selectedCategory.category_id,
      };
      const response = await axiosInstance.post(
        "places.php?method=add_menu_item",
        payload
      );
      if (response.data.status === "success") {
        // Refetch the menu items to ensure it's updated
        await menuItems(selectedCategory.category_id);
      }
      setNewItem({
        menu_name: "",
        menu_description: "",
        price: "",
        menu_image: "",
        imagePreview: null,
      });

      setOpen(false);
    } catch (error) {
      console.error("Error adding menu item", error);
      setAddMenuLoader(false);
    } finally {
      setAddMenuLoader(false);
    }
  };

  const handleEditSubmit = async () => {
    try {
      if (!selectedItem) return;
      const selectedCategory = categoriesData[activeIndex];
      const response = await axiosInstance.post(
        "places.php?method=edit_menu_item",
        {
          id: selectedItem.id, // Item ID
          menu_name: selectedItem.menu_name,
          menu_description: selectedItem.menu_description,
          price: selectedItem.price,
          image: selectedItem.menu_image || "",
          business_menu_category_id: selectedCategory.category_id,
        }
      );
      const updatedItem = response.data.updated_item || selectedItem;
      // Update the list of items after editing
      setMenuItemsData((prevItems = []) =>
        prevItems.map((item) =>
          item.id === selectedItem.id
            ? { ...item, ...updatedItem, id: item.id }
            : item
        )
      );
      await menuItems(selectedCategory.category_id);
      handleEditClose();
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  useEffect(() => {
    if (categoriesData.length > 0) {
      menuItems(categoriesData[0].category_id);
    }
  }, [categoriesData]);

  const displayedMenuItems = searchTerm
    ? menuItemsData.filter(
        (item) =>
          item.menu_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.menu_description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : menuItemsData;

  const handleDeleteModal = (item) => {
    setSelectedDeleteItem(item);
    setDeleteItem(true);
  };

  const handleDeleteClose = () => {
    setDeleteItem(false);
    setSelectedDeleteItem(null);
  };

  const handleToggle = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const openAddItemModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewItem((prev) => ({
      ...prev,
      menu_name: "",
      menu_description: "",
      price: "",
      menu_image: "",
      imagePreview: null,
      isPreviewMode: false,
    }));
    setPreviewModalOpen(false);
    setPreviewImage(null);
    setLoading(false);
  };

  const handleEditOpen = (item) => {
    setSelectedItem({
      ...item,
      menu_image: item.image,
      imagePreview: item.image || menuitem,
      isPreviewMode: Boolean(item.image),
    });
    setEditItem(true);
  };

  const handleEditClose = () => {
    setEditItem(false);
    setSelectedItem(null);
    setEditLoading(false);
  };

  const handleEditFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditLoading(true);
      try {
        // Compress the image
        const compressedFile = await compressImage(file);

        // Upload to S3
        const imageUrl = await uploadToS3(compressedFile);

        // Create preview URL
        const previewUrl = URL.createObjectURL(file);

        setSelectedItem((prev) => ({
          ...prev,
          menu_image: imageUrl,
          imagePreview: previewUrl,
          isPreviewMode: true,
        }));
      } catch (error) {
        console.error("Error handling file upload:", error);
      }
    }
  };
  const handleEditRemoveImage = () => {
    setSelectedItem((prev) => ({
      ...prev,
      menu_image: "",
      imagePreview: null,
      isPreviewMode: false,
    }));
    setEditLoading(false);
  };
  const handleEditImageClick = (e) => {
    e.preventDefault();
    setPreviewImage(selectedItem.imagePreview);
    setPreviewModalOpen(true);
  };

  const handleImageClick = (e) => {
    e.preventDefault();
    setPreviewImage(newItem.imagePreview);
    setPreviewModalOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewModalOpen(false);
    setPreviewImage(null);
  };

  const handleDelete = async (itemId) => {
    try {
      const response = await axiosInstance.post(
        "places.php?method=delete_menu_item",
        { id: itemId }
      );
      if (response.data.status === "success") {
        // Update the UI by filtering out the deleted item
        setMenuItemsData((prevItems) =>
          prevItems.filter((item) => item.id !== itemId)
        );
      } else {
        console.error("Failed to delete item:", response.data);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  if(!selectedBusinessId){
    return(
      <>
      <Typography sx={{textAlign:"center",pt:"50px",fontFamily:"Nunito",fontSize:"20px",fontWeight:"600"}}>
      No businesses available at the moment.
      </Typography>
      </>
    )
  }

  return (
    <Box>
      {showAddCategory ? (
        <CategoryCreation onDone={() => setShowAddCategory(false)} />
      ) : (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            mb={2}
            sx={{ px: { xs: "10px" } }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontSize: { xs: "20px", sm: "28px" } }}
            >
              Menu Management
            </Typography>
            {categoriesData.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowAddCategory(true)}
                sx={{
                  p: { xs: "6px", sm: "8px" },
                  fontSize: { xs: "12px", sm: "14px" },
                }}
              >
                <AddIcon /> Add Category
              </Button>
            )}
          </Box>
          { !categoriesLoading && categoriesData?.length === 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: 4,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "16px",
                  color: "#7A7A7A",
                  textAlign: "center",
                  mb: 2,
                }}
              >
                There are no categories yet. Click 'Add Category' to create one
                and get started!
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowAddCategory(true)}
                sx={{
                  p: { xs: "6px", sm: "8px" },
                  fontSize: { xs: "12px", sm: "14px" },
                }}
              >
                <AddIcon /> Add Category
              </Button>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              overflowX: "auto",
              whiteSpace: "nowrap",
              gap: "5px",
              px: 1.6,
              py: 1.3,
              "&::-webkit-scrollbar": { display: "none" },
              borderBottom: categoriesData.length > 0 ? "1px solid #DDDDDD" : "none",
            }}
          >
           { categoriesLoading ? (
  <Box sx={{ display: 'flex', gap: 5 }}>
           {[...Array(5)].map((_, index) => (
             <Box
               key={index}
               sx={{
                 width: "100px",
                 height: "22px",
                 background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                 backgroundSize: "200% 100%",
                 animation: "shimmer 1.5s infinite",
                 borderRadius: "4px",
                 "@keyframes shimmer": {
                   "0%": { backgroundPosition: "-200% 0" },
                   "100%": { backgroundPosition: "200% 0" },
                 },
               }}
             />
           ))}
         </Box>
           ):(
         categoriesData.map((category, index) => (
              <Button
                onClick={() => {
                  setActiveIndex(index);
                  // setSelectedCategoryId(category.category_id);
                  menuItems(category.category_id);
                }}
                key={category.category_id}
                sx={{
                  cursor: "pointer",
                  textTransform: "capitalize",
                  fontWeight: "medium",
                  color: activeIndex === index ? "#0041A8" : "#7A7A7A",
                  fontSize: { xs: "16px", sm: "18px" },
                  fontFamily: "Nunito",
                  lineHeight: "14px",
                  flexShrink: 0,
                }}
              >
                {category.name.charAt(0).toUpperCase() + category.name.slice(1)}
              </Button>
            ))
          )}
          </Box>
        
          <Box
            sx={{
              padding: "22px 18px 18px 15px",
              display: "flex",
              gap: "20px",
              width: { xs: "100%", sm: "50%" },
              mx: "auto",
            }}
          >
 {menuItemsData === null ? (
<></>
 ):(
  menuItemsData?.length > 0 && (
              <TextField
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                type="search"
                variant="outlined"
                placeholder="Search"
                size="small"
                sx={{
                  flex: 1,
                  borderRadius: "20px",
                  "& fieldset": {
                    borderWidth: "2px",
                    borderColor: "#ccc",
                    borderRadius: "50px",
                  },
                }}
                InputProps={{
                  sx: {
                    paddingRight: "6px",
                  },
                  endAdornment: (
                    <InputAdornment position="end" sx={{}}>
                      <IconButton
                        size="small"
                        sx={{
                          backgroundColor: "#ccc",
                          color: "white",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        <SearchIcon
                          sx={{ fontSize: "20px", color: "#212529" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ))}


            {!menuitemsloading && categoriesData?.length > 0 && menuItemsData?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "rgb(59, 73, 223)",
                    color: "white",
                    borderRadius: "50px",
                    pr: "14px",
                    pl: "10px",
                    letterSpacing: "1px",
                  }}
                  onClick={openAddItemModal}
                >
                  <IconButton
                    sx={{ color: "white", padding: "0px", mr: "3px" }}
                  >
                    <AddIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                  Menu item
                </Button>
              </Box>
            )}
          </Box>
          <Box sx={{ p: "18px" }}>
            <Grid container spacing={2} flexDirection="row">
              {menuitemsloading 
                ? (
                  Array.from(new Array(6)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                       <Box
                                        key={index}
                                        sx={{
                                          border: "1px solid #EDEDED",
                                          borderRadius: "10px",
                                          px: "12px",
                                          py: "10px",
                                          display: "flex",
                                          gap: "10px",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                          <Box
                                            sx={{
                                              width: "120px",
                                              height: "16px",
                                              borderRadius: "4px",
                                              background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                                              backgroundSize: "200% 100%",
                                              animation: "shimmer 1.5s infinite",
                                            }}
                                          />
                                          <Box
                                            sx={{
                                              width: "80px",
                                              height: "14px",
                                              borderRadius: "4px",
                                              background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                                              backgroundSize: "200% 100%",
                                              animation: "shimmer 1.5s infinite",
                                              mt: "10px",
                                            }}
                                          />
                                          <Box
                                            sx={{
                                              width: "150px",
                                              height: "14px",
                                              borderRadius: "4px",
                                              background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                                              backgroundSize: "200% 100%",
                                              animation: "shimmer 1.5s infinite",
                                              mt: "8px",
                                            }}
                                          />
                                        </Box>
                                        <Box
                                          sx={{
                                            width: "100px",
                                            height: "93px",
                                            borderRadius: "6px",
                                            background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                                            backgroundSize: "200% 100%",
                                            animation: "shimmer 1.5s infinite",
                                          }}
                                        />
                                      </Box>
                    </Grid>
                  ))
                )
                : menuItemsData.length >  0 && displayedMenuItems.length > 0
                ? displayedMenuItems.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {/* Item Box */}
                        <Box
                          sx={{
                            border: "2px solid #dfdede",
                            borderRadius: "10px",
                            px: "12px",
                            py: "10px",
                            display: "flex",
                            gap: "14px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                           }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              // maxHeight: "116px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Nunito",
                                fontSize: "16px",
                                fontWeight: "600",
                                lineHeight: "16px",
                                color: "#000000",
                              }}
                            >
                              {item.menu_name.charAt(0).toUpperCase() +
                                item.menu_name.slice(1)}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontFamily: "Nunito",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "14px",
                                color: "#7A7A7A",
                                pt: "8px",
                              }}
                            >
                              {expandedItems[index] ? (
                                <>
                                  {item.menu_description
                                    .charAt(0)
                                    .toUpperCase() +
                                    item.menu_description.slice(1)}{" "}
                                  <Typography
                                    component="span"
                                    onClick={() => handleToggle(index)}
                                    sx={{
                                      color: "blue",
                                      cursor: "pointer",
                                      fontSize: "12px",
                                      p: "0",
                                    }}
                                  >
                                    ...less
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  {item.menu_description
                                    .charAt(0)
                                    .toUpperCase() +
                                    item.menu_description
                                      .slice(1)
                                      .split(" ")
                                      .slice(0, 10)
                                      .join(" ")}
                                  {item.menu_description.trim().split(/\s+/).length >
                                    10 && (
                                    <Typography
                                      component="span"
                                      onClick={() => handleToggle(index)}
                                      sx={{
                                        color: "blue",
                                        cursor: "pointer",
                                        fontSize: "12px",
                                        p: "0",
                                      }}
                                    >
                                      ...more
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Typography>
                            {/* <Box
                          sx={{
                            pt: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Nunito",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "14px",
                              color: "#7A7A7A",
                              pt: "5px",
                            }}
                          >
                            {" "}
                            ingredient1
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "Nunito",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "8px",
                              color: "#7A7A7A",
                              pt: "8px",
                            }}
                          >
                            {" "}
                            ingredient2
                          </Typography>
                        </Box> */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                pt: "20px",
                                gap: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Nunito",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "14px",
                                  color: "#7A7A7A",
                                }}
                              >
                                ${item.price}
                              </Typography>
                              {item.created_by !== null && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1px",
                                  }}
                                >
                                  <IconButton
                                    sx={{ padding: "0px 5px 0px 0px " }}
                                    onClick={() => handleEditOpen(item)}
                                  >
                                    <EditIcon sx={{ fontSize: "16px" }} />
                                  </IconButton>
                                  <IconButton
                                    color="error"
                                    sx={{ padding: "0px 5px 0px 5px " }}
                                    onClick={() => handleDeleteModal(item)}
                                  >
                                    <DeleteIcon sx={{ fontSize: "16px" }} />
                                  </IconButton>
                                </Box>
                              )}
                            </Box>
                          </Box>

                          <Box
                            component="img"
                            src={item.image || menuitem}
                            width="100px"
                            height="93px"
                            sx={{
                              objectFit: "cover",
                              borderRadius: "4px",
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  )):
                  searchTerm.length > 0 && displayedMenuItems.length === 0 ? (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Nunito",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "16px",
                            color: "#7A7A7A",
                            textAlign: "center",
                          }}
                        >
                          No items available 
                        </Typography>
                      </Grid>
                  )
                : categoriesData.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2, // Adds spacing between text and button
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Nunito",
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "16px",
                          color: "#7A7A7A",
                          textAlign: "center",
                        }}
                      >
                        There are no Menu Items yet. Click 'ADD Menu Item' to
                        create one and get started!
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          p: { xs: "6px", sm: "8px" },
                          fontSize: { xs: "12px", sm: "14px" },
                        }}
                        onClick={openAddItemModal}
                      >
                        <AddIcon /> Add Menu Item
                      </Button>
                    </Grid>
                  )}
            </Grid>
          </Box>
        </>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "900px",
            bgcolor: "white",
            borderRadius: 2,
            textAlign: { xs: "center", sm: "start" },
            p: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontWeight: 600,
              color: "#1a237e",
            }}
          >
            Add New Menu Item
          </Typography>

          <Box sx={{ display: { xs: "", sm: "flex" }, gap: 3, mb: 3 }}>
            {/* Image Upload Section */}
            <Box
              sx={{
                width: 200,
                height: 200,
                border: "2px dashed #e0e0e0",
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                transition: "all 0.3s ease",
                position: "relative",
                "&:hover": {
                  borderColor: "#1a237e",
                  background: "#f5f5f5",
                },
                mb: { xs: "15px", sm: "px" },
                mx: { xs: "auto" },
              }}
              component="label"
            >
              {newItem.isPreviewMode ? (
                <>
                  <img
                    src={newItem.imagePreview || "default-image.png"}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: 8,
                      cursor: "pointer",
                    }}
                    onClick={handleImageClick}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleRemoveImage();
                    }}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  {!loading ? (
                    <>
                      <AddIcon sx={{ fontSize: 40, color: "#9e9e9e", mb: 1 }} />
                      <Typography variant="body2" color="textSecondary">
                        Upload Image
                      </Typography>
                    </>
                  ) : (
                    <CircularProgress color="primary" />
                  )}
                  <input
                    type="file"
                    hidden
                    onChange={handleFileUpload}
                    accept="image/*"
                  />
                </>
              )}
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                label="Item Name"
                name="menu_name"
                fullWidth
                value={newItem.menu_name}
                onChange={handleInputChange}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": { borderRadius: 2 },
                }}
              />

              <TextField
                label="Description"
                name="menu_description"
                fullWidth
                multiline
                value={newItem.menu_description}
                onChange={handleInputChange}
                rows={2}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": { borderRadius: 2 },
                }}
              />

              <TextField
                label="Price"
                name="price"
                type="number"
                fullWidth
                value={newItem.price}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
                }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": { borderRadius: 2 },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-end" },
              gap: 2,
              mt: 3,
            }}
          >
            <Button
              variant="outlined"
              // onClick={() => setShowForm(false)}
              sx={{
                borderRadius: 2,
                textTransform: "none",
                px: 3,
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: 2,
                textTransform: "none",
                px: 3,
                background: "#1a237e",
                "&:hover": {
                  background: "#0d47a1",
                },
              }}
              onClick={() => addMenuItem()}
              disabled={!newItem.menu_name.trim() || !newItem.price.trim()} 
            >
             {addMenuLoader ? <><CircularProgress color="white" size={24}/> </> : "Add Item"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={editItem} onClose={handleEditClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "900px",
            bgcolor: "white",
            borderRadius: 2,
            textAlign: { xs: "center", sm: "start" },
            p: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontWeight: 600,
              color: "#1a237e",
            }}
          >
            Edit Menu Item
          </Typography>

          <Box sx={{ display: { xs: "", sm: "flex" }, gap: 3, mb: 3 }}>
            <Box
              sx={{
                width: 200,
                height: 200,
                border: "2px dashed #e0e0e0",
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                transition: "all 0.3s ease",
                position: "relative",
                "&:hover": {
                  borderColor: "#1a237e",
                  background: "#f5f5f5",
                },
                mb: { xs: "15px", sm: "px" },
                mx: { xs: "auto" },
              }}
              component="label"
            >
              {selectedItem?.isPreviewMode ? (
                <>
                  <img
                    src={selectedItem.imagePreview}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: 8,
                    }}
                    onClick={handleEditImageClick}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditRemoveImage();
                    }}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  {!editLoading ? (
                    <>
                      <AddIcon sx={{ fontSize: 40, color: "#9e9e9e", mb: 1 }} />
                      <Typography variant="body2" color="textSecondary">
                        Upload Image
                      </Typography>
                    </>
                  ) : (
                    <CircularProgress color="primary" />
                  )}
                  <input
                    type="file"
                    hidden
                    onChange={handleEditFileUpload}
                    accept="image/*"
                  />
                </>
              )}
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                label="Item Name"
                name="menu_name"
                fullWidth
                value={selectedItem?.menu_name || ""}
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    menu_name: e.target.value,
                  })
                }
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": { borderRadius: 2 },
                }}
              />

              <TextField
                label="Description"
                name="menu_description"
                fullWidth
                multiline
                rows={2}
                value={selectedItem?.menu_description || ""}
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    menu_description: e.target.value,
                  })
                }
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": { borderRadius: 2 },
                }}
              />

              <TextField
                label="Price"
                name="price"
                type="number"
                fullWidth
                value={selectedItem?.price || ""}
                onChange={(e) =>
                  setSelectedItem({ ...selectedItem, price: e.target.value })
                }
                InputProps={{
                  startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
                }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": { borderRadius: 2 },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-end" },
              gap: { xs: 1, sm: 2 },
              mt: 3,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: 2,
                textTransform: "none",
                px: 3,
              }}
              onClick={handleEditClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: 2,
                textTransform: "none",
                px: 3,
                background: "#1a237e",
                "&:hover": {
                  background: "#0d47a1",
                },
              }}
              onClick={handleEditSubmit}
            >
              Update Item
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={deleteItem}
        onClose={handleDeleteClose}
        sx={{ borderColor: "white" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {xs:"90%",sm:"60%",md:"26%"},
            bgcolor: "white",
            boxShadow: 24,
            p: 5,
            textAlign: "center",
            borderRadius: "8px",
            borderColor: "white",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 4,
              fontFamily: "Nunito",
              fontSize: "18px",
              lineHeight: "20px",
              fontWeight: "600",
            }}
          >
            Are you sure you want to delete this item?
          </Typography>

          {/* Buttons */}
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (selectedDeleteItem) {
                handleDelete(selectedDeleteItem.id);
                handleDeleteClose();
              }
            }}
            sx={{ mr: 2 }}
          >
            Yes
          </Button>
          <Button variant="outlined" onClick={handleDeleteClose}>
            No
          </Button>
        </Box>
      </Modal>
      <Modal
        open={previewModalOpen}
        onClose={handlePreviewClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <Box
          sx={{
            position: "relative",
            maxWidth: "90vw",
            maxHeight: "90vh",
            outline: "none",
          }}
        >
          <img
            src={previewImage}
            alt="Full Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "90vh",
              objectFit: "contain",
            }}
          />
          <IconButton
            onClick={handlePreviewClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </Box>
  );
}

export default MenuContent;
