import React from "react";
import { Box, Container, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PrivacyPolicy = ({ open, handleModalClose }) => {
  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box
        sx={{
          py: "60px",
          position: "absolute",
          top: "49%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "100vh",
          bgcolor: "#fff",
          p: 3,
          // transform: open ? "translateX(0)" : "translateX(-100%)",
          transition: "transform 0.4s ease-in-out",
          zIndex: 5,
          overflowY: "scroll",
        }}
      >
        <Container>
          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: "400",
              color: "  #12183a",
              fontFamily: "Montserrat",
              py: "18px",
            }}
          >
            Privacy Policy
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "1px",
            }}
          >
            Last Updated: March 29, 2025
          </Typography>

          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "10px",
            }}
          >
            PointGenie values your privacy. This Privacy Policy outlines how we
            collect, use, and protect your data when you use our NFC tags and
            services.
          </Typography>
   
          <Typography
            sx={{
              fontSize: "1.75rem",
              fontWeight: "400",
              color: "  #12183a",
              fontFamily: "Montserrat",
              // pb: "18px",
              pt: "24px",
            }}
          >
            {" "}
            Information We Collect
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
            }}
          >
            When a user taps an NFC tag, we may collect:

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
              pl:{xs:"0px",sm:"24px"}
            }}
          >
            <Typography component={"span"} sx={{ fontSize: "20px" }}>
              
              •{" "}
            </Typography>
            Device type and browser (non-identifiable)

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
              pl:{xs:"0px",sm:"24px"}
            }}
          >
            <Typography component={"span"} sx={{ fontSize: "20px" }}>
              
              •{" "}
            </Typography>
            Tap timestamp and general location (if permissions allow)


          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
              pl:{xs:"0px",sm:"24px"}
            }}
          >
            <Typography component={"span"} sx={{ fontSize: "20px" }}>
              
              •{" "}
            </Typography>
            Referral URLs or accessed links

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
            }}
          >
           For business owners:
           </Typography>
           <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
              pl:{xs:"0px",sm:"24px"}
            }}
          >
            <Typography component={"span"} sx={{ fontSize: "20px" }}>
              
              •{" "}
            </Typography>
            Business name, email, address

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
              pl:{xs:"0px",sm:"24px"}
            }}
          >
            <Typography component={"span"} sx={{ fontSize: "20px" }}>
              
              •{" "}
            </Typography>
            Links to social media, review pages, or menus

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
              pl:{xs:"0px",sm:"24px"}
            }}
          >
            <Typography component={"span"} sx={{ fontSize: "20px" }}>
              
              •{" "}
            </Typography>
            NFC tag activity data


          </Typography>
        
        
      
     
          <Typography
            sx={{
              fontSize: "1.75rem",
              fontWeight: "400",
              color: "  #12183a",
              fontFamily: "Montserrat",
              // pb: "18px",
              pt: "24px",
            }}
          >
            {" "}
            How We Use the Data

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "14px",
            }}
          >
            To provide analytics and insights to business owners

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "14px",
            }}
          >
            To improve the functionality of PointGenie services

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "14px",
            }}
          >
            For customer support and troubleshooting

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "14px",
            }}
          >
           To send important service updates

          </Typography>
         
         
          <Typography
            sx={{
              fontSize: "1.75rem",
              fontWeight: "400",
              color: "  #12183a",
              fontFamily: "Montserrat",
              pt: "34px",
            }}
          >
            {" "}
            Data Sharing

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              // pt: "8px",
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                color: "  #415076",
                fontFamily: "Montserrat",
                lineHeight: 1.68,
                pt: "14px",
              }}
            >
             We do not sell or rent your data. We may share data with:

            </Typography>
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
            }}
          >
            <Typography component={"span"} sx={{ fontSize: "20px" }}>
             
              • {" "}
            </Typography>
           
            Trusted third-party partners (e.g., Easyship, Stripe, Brevo) to fulfill services

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
            }}
          >
            <Typography component={"span"} sx={{ fontSize: "20px" }}>
             
              • {" "}
            </Typography>
            Authorities, if required by law or for legal protection

          </Typography>
       
          <Typography
            sx={{
              fontSize: "1.75rem",
              fontWeight: "400",
              color: "  #12183a",
              fontFamily: "Montserrat",
              pb: "10px",
              pt: "34px",
            }}
          >
            {" "}
            Cookies & Tracking

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              // pt: "14px",
            }}
          >
            Our landing pages may use cookies to enhance the user experience and gather usage data. You may opt out through your browser settings.

          </Typography>
          <Typography
            sx={{
              fontSize: "1.75rem",
              fontWeight: "400",
              color: "  #12183a",
              fontFamily: "Montserrat",
              pb: "10px",
              pt: "34px",
            }}
          >
            {" "}
            Data Security

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              // pt: "14px",
            }}
          >
            We use industry-standard encryption and secure servers to protect your data. However, no digital transmission is 100% secure.


          </Typography>
          <Typography
            sx={{
              fontSize: "1.75rem",
              fontWeight: "400",
              color: "  #12183a",
              fontFamily: "Montserrat",
              pb: "10px",
              pt: "34px",
            }}
          >
            {" "}
            User Rights
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              // pt: "14px",
            }}
          >
            You have the right to:



          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
            }}
          >
            <Typography component={"span"} sx={{ fontSize: "20px", }}>
            
              • {" "}
            </Typography>
              Request access to or deletion of your data


          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "12px",
            }}
          >
            <Typography component={"span"} sx={{ fontSize: "20px", }}>
            
              • {" "}
            </Typography>
            Opt out of promotional emails (if any)


          </Typography>
          <Typography
            sx={{
              fontSize: "1.75rem",
              fontWeight: "400",
              color: "  #12183a",
              fontFamily: "Montserrat",
              pb: "10px",
              pt: "34px",
            }}
          >
            {" "}
            Children’s Privacy
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              // pt: "14px",
            }}
          >
           Our services are not intended for children under 13. We do not knowingly collect data from minors.




          </Typography>

          <Typography
            sx={{
              fontSize: "1.75rem",
              fontWeight: "400",
              color: "  #12183a",
              fontFamily: "Montserrat",
              pb: "10px",
              pt: "34px",
            }}
          >
            {" "}
            Changes to This Policy

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              // pt: "14px",
            }}
          >
          We may update this Privacy Policy periodically. You will be notified of any major changes.

          </Typography>
          <Typography
            sx={{
              fontSize: "1.75rem",
              fontWeight: "400",
              color: "  #12183a",
              fontFamily: "Montserrat",
              pb: "10px",
              pt: "34px",
            }}
          >
            {" "}
            Contact


          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              // pt: "14px",
            }}
          >
         For any questions about this policy, contact us at:


          </Typography>
          <Typography
              component={"span"}
              sx={{
                color: "#2b56f5",
                fontSize: "15px",
                fontFamily: "Montserrat",
                lineHeight: 1.68,
                fontWeight: "400",
              }}
            >
              support@PointGenie.com.
            </Typography>
        
        </Container>
        <IconButton
          onClick={handleModalClose}
          sx={{
            position: "absolute",
            top: { xs: 10, sm: 20 },
            right: { xs: 4, sm: 40 },
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default PrivacyPolicy;
