import { auth } from "../../firebase/FirebaseConfig"
import { signInWithEmailAndPassword } from "firebase/auth"
import { getAuth } from "firebase/auth";

const FirebaseAuth = () => {

    const signInWithFireBase = async ({ email, password }) => {
        const userInfo = await signInWithEmailAndPassword(
            auth,
            email,
            password
        )

        const { user } = userInfo
        if (user) {
            generateIdToken(user)
        }
    }

    const generateIdToken = async user => {
        const token = await user.getIdToken(true)

        if (token) {
            storeUserInfoIntoLocalStorage(token, user)
        }
    }

    const storeUserInfoIntoLocalStorage = (token, user) => {
        localStorage.setItem('firebaseUser', JSON.stringify(user));
        localStorage.setItem('userEmail', user.email);
        localStorage.setItem('idToken', token);
    }

    return [signInWithFireBase]
}

export default FirebaseAuth