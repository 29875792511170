import React from "react";
import {
  Box,
  Button,
  Container,
  styled,
  Typography,
  Avatar,
  Grid,
  backdropClasses,
  colors,
  Image,
  Radio,
  Card,
} from "@mui/material";
import itemCart from "../images/ItemCart.png";
import itemLink from "../images/ItemLink.png";
import itemSearch from "../images/ItemSearch.png";
import greylogo from "../images/greylogo.png";

function Header() {
  const Header = styled(Box)({
    // width: "98%",
    borderWidth: "1px",
    borderBottom: "1px solid rgb(184, 183, 183)",
    // padding: "16px 0px 16px 40px",
  });
  const HeaderText = styled(Typography)({
    fontWeight: "400",
    // fontSize: "14px",
    // lineHeight: "33px",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#000000",
    fontFamily: "Montserrat",
  });

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Header
        sx={{
          width: { xs: "100%", sm: "98%" },
          padding: { xs: "16px 4px 16px 4px", sm: "16px 0px 16px 40px" },
        }}
      >
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            sm={3}
            sx={{ textAlign: { xs: "center", sm: "start" } }}
          >
            <Box component="img" src={greylogo} />
          </Grid>
          <Grid item xs={12} md={6.8} sm={6} lg={5} xl={5}>
            <Box
              display="flex"
              justifyContent="space-between"
              gap={{ xs: "12px", sm: "0px" }}
              py={{ xs: "16px", sm: "0px" }}
            >
              <HeaderText
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: { xs: "20px", sm: "33px" },
                }}
              >
                Shop Now
              </HeaderText>
              <HeaderText
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: { xs: "20px", sm: "33px" },
                }}
              >
                Clearance Sale
              </HeaderText>
              <HeaderText
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: { xs: "20px", sm: "33px" },
                }}
              >
                Bundle and Save
              </HeaderText>
              <HeaderText
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: { xs: "20px", sm: "33px" },
                }}
              >
                Reviews
              </HeaderText>
              <HeaderText
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: { xs: "20px", sm: "33px" },
                }}
              >
                About Us
              </HeaderText>
            </Box>
          </Grid>

          <Grid item sm={1} md={0.2} lg={2} xl={2}></Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
            <Box
              display={"flex"}
              gap={{ xs: 5, sm: 2 }}
              justifyContent={"center"}
            >
              <Box
                component="img"
                src={itemLink}
                sx={{ width: "22px", height: "22px", color: "#000000" }}
              />
              <Box
                component="img"
                src={itemCart}
                sx={{ width: "22px", height: "22px", color: "#000000" }}
              />
              <Box
                component="img"
                src={itemSearch}
                sx={{ width: "22px", height: "22px", color: "#000000" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Header>
    </Box>
  );
}

export default Header;
