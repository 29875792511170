import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Button,
  Modal,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "../axios";

const OrdersContent = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPlates, setSelectedPlates] = useState([]);
  const [platesLoading, setPlatesLoading] = useState(false);
  const [platesError, setPlatesError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axiosInstance.post("user.php?method=get_orders");
        setOrders(response.data.orders);
      } catch (err) {
        setError("Failed to fetch orders. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Fetch plates when opening modal
  const handleOpenModal = async (orderId) => {
    setOpenModal(true);
    setPlatesLoading(true);
    setPlatesError(null);

    try {
      const response = await axiosInstance.post(
        "user.php?method=get_order_plates",
        {
          order_id: orderId,
        }
      );
      setSelectedPlates(response.data.plates || []);
    } catch (error) {
      setPlatesError("Failed to load plates.");
    } finally {
      setPlatesLoading(false);
    }
  };

  // Close modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPlates([]);
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 3 } }}>
      <Typography
        variant="h4"
        sx={{
          fontSize: { xs: "24px", sm: "28px" },
          pb: "8px",
          fontWeight: "600px",
        }}
        gutterBottom
      >
        Your Orders
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : orders?.length === 0 ? (
        <Alert severity="info">No orders found.</Alert>
      ) : (
        <TableContainer component={Paper} elevation={2}>
          <Table sx={{ minWidth: 900 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell sx={{ fontWeight: "bold" }}>Order ID</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Product Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Transaction ID
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{ "&:hover": { backgroundColor: "#d8dadb" } }}
                >
                  <TableCell>{order.id}</TableCell>
                  <TableCell>
                    {new Date(order.created_at)
                      .toLocaleString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })
                      .replace(",", "")}
                  </TableCell>
                  <TableCell>{order.name}</TableCell>
                  <TableCell>{order.transaction_id}</TableCell>
                  <TableCell>${order.amount}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleOpenModal(order.id)}
                    >
                      View Plates
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Modal for Viewing Plates */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%", // Adjust to a percentage instead of a fixed width
            maxWidth: 500, // Ensures it does not exceed 400px
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            overflowX: "hidden", // Prevents horizontal scroll
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Your Plates</Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          {platesLoading ? (
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          ) : platesError ? (
            <Alert severity="error">{platesError}</Alert>
          ) : selectedPlates?.length > 0 ? (
            <TableContainer component={Paper} elevation={2}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell sx={{ fontWeight: "bold" }}>Business</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Plate Code
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedPlates.map((plate, index) => (
                    <TableRow key={index}>
                      <TableCell>{plate.name}</TableCell>
                      <TableCell sx={{ color: "green", fontWeight: "bold" }}>
                        {plate.plate_code}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: plate.status === "1" ? "green" : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {plate.status === "1" ? "Active" : "InActive"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No plates available.</Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default OrdersContent;
