import React, { useEffect, useState, useContext } from "react";
import { Box, Typography } from "@mui/material";
import axiosInstance from "../axios";
import { BusinessContext } from "../BusinessContext";
import { CircularProgress } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const headerStyle = {
  fontFamily: "Nunito",
  fontSize: "16px",
  fontWeight: "700",
  color: "#000000",
  backgroundColor: "#f5f5f5",
  textAlign: "left",
};

const cellStyle = {
  fontFamily: "Nunito",
  fontSize: "14px",
  fontWeight: "500",
  color: "green",
  px: "14px",
  py: "8px",
};
const cellStyles = {
  fontFamily: "Nunito",
  fontSize: "14px",
  fontWeight: "500",
  color: "black",
  px: "14px",
  py: "8px",
};

const cellStyless = {
  fontFamily: "Nunito",
  fontSize: "14px",
  fontWeight: "500",
  color: "blue",
  px: "14px",
  py: "8px",
};

function PlatesContent() {
  const [platesData, setPlatesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedBusinessId } = useContext(BusinessContext);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchPlates = async () => {
      if (!selectedBusinessId) {
        console.error("Business IDmissing!");
        setLoading(false); // Stop loading
        setPlatesData([]); // Set empty array
        return;
      }
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          "places.php?method=business_plates",
          {
            business_id: selectedBusinessId,
          }
        );
        if (response.data?.plates) {
          setPlatesData(response.data?.plates);
        } else {
          console.error("No plates found in response");
          setPlatesData([]);
        }
      } catch (error) {
        console.error("Error fetching plates:", error);
        setPlatesData([]);
      }
      setLoading(false);
    };
    fetchPlates();
  }, [selectedBusinessId]);

  const handleCopy = (code) => {
    const currentUrl = window.location.origin; // Get current URL domain
    const url = `${currentUrl}/r/code=${code}`;
    // Copy to clipboard
    navigator.clipboard.writeText(url);
    setCopied(true);

    // Open in a new tab
    window.open(url, "_blank");

    // Reset tooltip text after 1.5 seconds
    setTimeout(() => setCopied(false), 1500);
  };

  return (
    <Box paddingLeft={"30px"}>
      {loading ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress
            sx={{
              fontSize: "16px",
              mt: "80px",
            }}
            size={28}
          />
        </Box>
      ) : (
        <>
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "16px",
              color: "#000000",
              px: "14px",
              pb: "25px",
              pt: "14px",
            }}
          >
            Plates Data :
          </Typography>

          <TableContainer
            component={Paper}
            sx={{ maxWidth: { xs: "90%", sm: "60%" }, mt: 2 }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerStyle}>Code</TableCell>
                  <TableCell sx={headerStyle}>Status</TableCell>
                  <TableCell sx={headerStyle}>Scans</TableCell>
                  <TableCell sx={headerStyle}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {platesData.length > 0 ? (
                  platesData.map((plate, index) => (
                    <TableRow key={index}>
                      <TableCell sx={cellStyles}>{plate.plate_code}</TableCell>
                      <TableCell sx={cellStyle}>Active</TableCell>
                      <TableCell sx={cellStyle}>{plate.scans}</TableCell>
                      <TableCell
                        sx={{ ...cellStyless, cursor: "pointer" }}
                        onClick={() => handleCopy(plate.plate_code)}
                      >
                        <Tooltip title={copied ? "Copied!" : "Copy"} arrow>
                          <span>
                            {`${window.location.origin}/r/code=${plate.plate_code}`}
                            <ContentCopyIcon
                              sx={{ color: "black", marginLeft: 1 }}
                            />
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      sx={{ textAlign: "center", py: 2, fontWeight: 600 }}
                    >
                      No Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
}

export default PlatesContent;
