import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Switch,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import GradeIcon from "@mui/icons-material/Grade";
import StarIcon from "@mui/icons-material/Star";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import RoomIcon from "@mui/icons-material/Room";
import axiosInstance from "./axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const itemsData = [
  {
    id: 1,
    name: "Facebook",
    icon: <FacebookIcon fontSize="small" />,
    bgColor: "#1877F2",
  },
  {
    id: 2,
    name: "Google",
    icon: <GradeIcon fontSize="small" />,
    bgColor: "#4285F4",
  },
  {
    id: 3,
    name: "Instagram",
    icon: <InstagramIcon fontSize="small" />,
    bgColor: "#C13584",
  },
  {
    id: 4,
    name: "TikTok",
    icon: <PlayCircleFilledIcon fontSize="small" />,
    bgColor: "#000000",
  },
  {
    id: 5,
    name: "TripAdvisor",
    icon: <StarIcon fontSize="small" />,
    bgColor: "#34E0A1",
  },
  {
    id: 6,
    name: "WhatsApp",
    icon: <WhatsAppIcon fontSize="small" />,
    bgColor: "#25D366",
  },
  {
    id: 7,
    name: "Yelp",
    icon: <LanguageIcon fontSize="small" />,
    bgColor: "#D32323",
  },
  {
    id: 8,
    name: "Custom Provider",
    icon: <InsertLinkIcon fontSize="small" />,
    bgColor: "#117be5",
  },
  {
    id: 9,
    name: "Custom Provider",
    icon: <InsertLinkIcon fontSize="small" />,
    bgColor: "#117be5",
  },
];
const validatePhoneNumber = (phoneNumber) => {
  const countryCodes = ["91", "1"]; // Add more country codes if needed
  let digitsAfterCode = phoneNumber;

  // Detect and remove country code if present
  for (const code of countryCodes) {
      if (phoneNumber.startsWith(code)) {
          digitsAfterCode = phoneNumber.slice(code.length);
          break; // Stop checking once a country code is removed
      }
  }

  console.log("Entered Number:", phoneNumber);
  console.log("Detected Country Code:", phoneNumber.slice(0, phoneNumber.length - digitsAfterCode.length));
  console.log("Digits After Code:", digitsAfterCode);
  console.log("Final Length Check:", digitsAfterCode.length);

  const expectedLength = 10; // Typically, mobile numbers are 10 digits long
  console.log("Expected Length:", expectedLength);

  const isValid = digitsAfterCode.length === expectedLength;
  console.log("Validation Result:", isValid);

  return isValid;
};

function SocialMediaReview() {
  const navigate = useNavigate();
  const [activeItems, setActiveItems] = useState({});
  const [urls, setUrls] = useState({});
  const [loading, setLoading] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const location = useLocation();
  const businessId = location.state?.businessId;
  console.log("busines", businessId);
  const [customProviderNames, setCustomProviderNames] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        if (!businessId) {
          throw new Error("Authentication required");
        }

        const response = await axiosInstance.post(
          "places.php?method=business_providers",
          {
            business_id: businessId,
          }
        );

        if (response.data.status === "success" && response.data.providers) {
          console.log("responseprovider", response.data);

          // Create a new object for active providers
          const newActiveItems = {};
          const newUrls = {};
          const newCustomProviderNames = {};

          // Normalize API keys to lowercase
          const normalizedProviders = Object.entries(
            response.data.providers
          ).reduce((acc, [key, value]) => {
            acc[key.toLowerCase()] = value;
            return acc;
          }, {});

          // ✅ Enable only the providers present in API response
          itemsData.forEach((item) => {
            const providerKey = item.name.toLowerCase();
            if (normalizedProviders[providerKey]) {
              newActiveItems[item.id] = true;
              newUrls[item.id] = normalizedProviders[providerKey].proivder_link;
            }
          });

          // ✅ Handle multiple custom providers dynamically
          const customProviders = Object.entries(
            response.data.providers
          ).filter(([key]) => key.toLowerCase().includes("customprovider"));

          customProviders.forEach(([key, value], index) => {
            const uniqueId = 8 + index; // Assign dynamic IDs

            newActiveItems[uniqueId] = true;
            newUrls[uniqueId] = value.proivder_link;
            newCustomProviderNames[uniqueId] =
              value.proivder_label || `Custom Provider ${index + 1}`;
          });

          setActiveItems(newActiveItems);
          setUrls(newUrls);
          setCustomProviderNames(newCustomProviderNames);
        } else {
          throw new Error(response.data.message || "Failed to fetch providers");
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchProviders();
  }, [businessId]);
  const isValidUrl = (url) => {
    const urlRegex =
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\S*)?$/;
    return urlRegex.test(url);
  };

  // const isValidWhatsApp = (number, country) => {
  //   const trimmedNumber = number.trim();

  //   if (!country) return false; // Ensure country data is available

  //   const countryCode = country.dialCode; // Get country dial code
  //   const minLength = country.format?.replace(/\D/g, "").length || 10; // Get expected length
  //   const maxLength = minLength + 3; // Allow small variations

  //   const numberWithoutCode = trimmedNumber
  //     .replace(`+${countryCode}`, "")
  //     .replace(/\D/g, "");

  //   return (
  //     numberWithoutCode.length >= minLength &&
  //     numberWithoutCode.length <= maxLength
  //   );
  // };

  const handleToggle = (id) => {
    setActiveItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleUrlChange = (id, value) => {
    if (typeof value !== "string") {
      console.error("Invalid URL value:", value);
      return;
    }
    setUrls((prev) => ({ ...prev, [id]: value }));
  };

  const handleCustomProviderNameChange = (id, value) => {
    setCustomProviderNames((prev) => ({ ...prev, [id]: value }));
  };

  const handleSave = async (item) => {
    try {
      let providerName = item.name;
      let providerKey = item.name.toLowerCase().replace(/\s+/g, "");
      if (item.name.includes("Custom Provider")) {
        providerName = customProviderNames[item.id]?.trim() || "Custom Provider";
        providerKey = `customprovider_${item.id}`; // Ensure unique key

        if (!providerName) {
          setSnackbar({
            open: true,
            message: "Please enter provider name",
            severity: "error",
          });
          return;
        }
      }
      // Validate input field
      const inputValue = urls[item.id]?.trim();
      if (!inputValue) {
        setSnackbar({
          open: true,
          message: `Please enter ${
            item.name === "WhatsApp"
              ? "WhatsApp number"
              : item.name === "Google"
              ? "Google place"
              : `${item.name} URL`
          }`,
          severity: "error",
        });
        return;
      }

      if (
        item.name === "WhatsApp" &&
        !validatePhoneNumber(inputValue, selectedCountry)
      ) {
        setSnackbar({
          open: true,
          message: "Enter a valid WhatsApp number for selected country",
          severity: "error",
        });
        return;
      }
      if (item.name !== "WhatsApp" && !isValidUrl(inputValue)) {
        setSnackbar({
          open: true,
          message: "Enter a valid URL",
          severity: "error",
        });
        return;
      }

      let providerLabel = customProviderNames[item.id] || providerName; // Ensure non-null label

      setLoading((prev) => ({ ...prev, [item.id]: true }));

      setLoading((prev) => ({ ...prev, [item.id]: true }));
      const response = await axiosInstance.post(
        "places.php?method=add_review_provider",
        {
          provider: providerKey,
          business_id: "3965",
          provider_link: inputValue,
          provider_label: providerLabel,
        }
      );

      if (response.data.status === "success") {
        setSnackbar({
          open: true,
          message: `${
            item.name === "WhatsApp"
              ? "WhatsApp number"
              : item.name + " URL"
          } saved successfully!`,
          severity: "success",
        });
        // fetchProviders()
        setActiveItems((prev) => ({ ...prev, [item.id]: true }));
        setUrls((prev) => ({ ...prev, [item.id]: inputValue }));
        setCustomProviderNames((prev) => ({
          ...prev,
          [item.id]: providerLabel, // Ensure updated name is stored
        }));
      } else {
        throw new Error(response.data.message || "Failed to save URL");
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          error.response?.data?.message || error.message || "Error saving URL",
        severity: "error",
      });
    } finally {
      setLoading((prev) => ({ ...prev, [item.id]: false }));
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box
      p={2}
      maxWidth={400}
      mx="auto"
      sx={{ backgroundColor: "rgb(239 243 255)" }}
    >
      <Paper sx={{ p: 2, mb: 2, borderRadius: 3 }}>
        <Typography
          sx={{ fontSize: "18px", fontFamily: "Nunito", lineHeight: "14px" }}
        >
          Description
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontFamily: "Nunito",
            lineHeight: "24px",
            fontWeight: "700",
          }}
          mt={1}
        >
          Explore Our Socials and Share Your Feedback! Your Opinions Matter to
          Us!
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontFamily: "Nunito",
            lineHeight: "20px",
            fontWeight: "500",
          }}
          mt={1}
          color="gray"
        >
          Add a description to your All-In-One Plate to provide more information
          about your business. This will be visible on your page.
        </Typography>
      </Paper>
      {/* Drag & Drop Info Box */}
      <Paper sx={{ p: 2, mb: 2, borderRadius: 3, backgroundColor: "#E8F1FF" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontFamily: "Nunito",
            lineHeight: "20px",
            fontWeight: "500",
          }}
          textAlign="center"
        >
          Drag and drop the icon to change the order of appearance on your
          All-In-One Page.
        </Typography>
      </Paper>

      {/* List Items */}
      {itemsData.map((item) => (
        <Paper
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 1.5,
            mb: 2,
            borderRadius: 3,
            //   backgroundColor:"#f5f5f5",
            boxShadow: "none",
          }}
        >
          {/* Header Row */}
          <Box display="flex" alignItems="center">
            {/* <IconButton>
            <DragIndicatorIcon />
          </IconButton> */}
            <Box
              sx={{
                width: 35,
                height: 35,
                borderRadius: "50%",
                backgroundColor: item.bgColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                mr: 2,
              }}
            >
              {item.icon}
            </Box>
            <Typography
              flexGrow={1}
              sx={{
                fontSize: "16px",
                fontFamily: "Nunito",
                lineHeight: "20px",
                fontWeight: "700",
              }}
            >
              {item.name}
            </Typography>
            <Switch
              checked={!!activeItems[item.id]}
              onChange={() => handleToggle(item.id)}
              sx={{
                transform: "scale(1.4)",
                "& .MuiSwitch-thumb": {
                  width: 20,
                  height: 20,
                  marginTop: "5px",
                },
                "& .MuiSwitch-track": {
                  height: 24,
                  width: 42,
                  borderRadius: 20,
                  opacity: 1,
                  backgroundColor: "#E4E4E7",
                },
                "&.Mui-checked .MuiSwitch-thumb": {
                  backgroundColor: "#6D28D9",
                  transform: "translateX(18px)",
                },
                "&.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#6D28D9",
                  opacity: 1,
                },
              }}
            />
          </Box>

          {/* Expandable Section */}
          {activeItems[item.id] && (
            <Box mt={2} p={2} bgcolor="#F4F4F5" borderRadius={2}>
              {/* Handle Custom Provider Name Input */}
              {item.name.includes("Custom Provider") && (
                <>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                    mb={1}
                  >
                    Provider Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Enter provider name"
                    fullWidth
                    value={customProviderNames[item.id] || ""}
                    onChange={(e) =>
                      handleCustomProviderNameChange(item.id, e.target.value)
                    }
                    sx={{ mb: 1, bgcolor: "white" }}
                  />
                </>
              )}

              {/* Determine Provider Name Dynamically */}
              <Typography sx={{ fontSize: "14px", fontWeight: "500" }} mb={1}>
                  {item.name.includes("WhatsApp") ? "WhatsApp Number" : "Provider URL"}
              </Typography>

              {item.name === "WhatsApp" ? (
                <PhoneInput
                  country={"us"} // Default country (change as needed)
                  value={urls[item.id] || ""}
                  onChange={(phone, country) => {
                    setSelectedCountry(country); // Store selected country
                    handleUrlChange(item.id, phone);
                  }}
                  inputStyle={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  disableCountryGuess // Prevents automatic country detection
                  enableAreaCodes // Allows area codes within a country
                  disableDropdown={false}
                />
              ) : (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder={`Enter ${
                    item.name.includes("custom provider")
                      ? customProviderNames[item.id] || "Custom Provider"
                      : item.name
                  } link`}
                  fullWidth
                  value={urls[item.id] || ""}
                  onChange={(e) => handleUrlChange(item.id, e.target.value)}
                  sx={{ mb: 1, bgcolor: "white" }}
                  type="url"
                />
              )}

              <Box sx={{ width: "30%", mx: "auto" }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => handleSave(item)}
                  disabled={loading[item.id]}
                  sx={{
                    backgroundColor: "#4285F4",
                    color: "white",
                    py: "5px",
                  }}
                >
                  {loading[item.id] ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      ))}
      <Box sx={{ textAlign: "center", pt: "25px", pb: "20px" }}>
        <Button
          sx={{ backgroundColor: "#4285F4", color: "white", px: "30px" }}
          onClick={() => navigate("/dashboard")}
        >
          Continue
        </Button>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default SocialMediaReview;
