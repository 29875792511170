import React, { createContext, useState, useEffect } from "react";
import axiosInstance from './axios'
export const BusinessContext = createContext();

export const BusinessProvider = ({ children }) => {
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusinessId, setSelectedBusinessId] = useState(localStorage.getItem("businessId") || "");
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);

  const fetchBusinesses = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        'user.php?method=get_user_buinesses',
      );

      if (response.data?.status === "success") {
        const businessList = response.data.orders || [];
        setBusinesses(businessList);
        if (businessList.length > 0) {
          const storedBusinessId = localStorage.getItem("businessId");
          const defaultBusiness = businessList.find(b => b.id === storedBusinessId) || businessList[0];

          setSelectedBusinessId(defaultBusiness.id);
          localStorage.setItem("businessId", defaultBusiness.id);
        }
      }
    } catch (error) {
      console.error("Failed to fetch businesses:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchcategories = async () => {
    try {
      const response = await axiosInstance.post(
        'payments.php?method=menu_categories',
        {
          business_id: selectedBusinessId,
        },
      );
      setCategoriesData(response.data.categories || []);
    } catch (error) {
      console.log("Error fetching categories", error);
    }
  };


  useEffect(() => {
    fetchBusinesses();
    fetchcategories()
  }, []);

  const updateBusinessId = (newBusinessId) => {
    setSelectedBusinessId(newBusinessId);
    localStorage.setItem("businessId", newBusinessId);
  };

  return (
    <BusinessContext.Provider value={{ businesses, selectedBusinessId, updateBusinessId, isLoading, fetchBusinesses, fetchcategories }}>
      {children}
    </BusinessContext.Provider>
  );
};
