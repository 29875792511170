import React from "react";
import { Box, TextField, Typography, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import reviewstar from "../images/reviewstar.png";
import location from "../images/location.png";
import { Checkbox} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

const CustomCheckbox = styled(Checkbox)({
  "&.MuiCheckbox-root": {
    borderRadius: "4px",
    border: "1px solid #BDBDBD",
    width: 20,
    height: 20,
    padding: 0,
  },
  "&.Mui-checked": {
    color: "#757575",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 16,
  },
});

function RatingandReview() {
  return (
    <Box sx={{ py: "34px" }}>
      <Box
        sx={{
          px: "10px",
          display: "flex",
          gap: "14px",
          alignItems: "center",
          pb: "25px",
        }}
      >
        <ArrowBackIcon sx={{ fontSize: 22 }} />
        <Typography
          sx={{
            fontSize: "26px",
            fontWeight: "700",
            fontFamily: "Roboto",
            lineHeight: "30px",
          }}
        >
          Rating & Review
        </Typography>
      </Box>
      <Box sx={{ px: "90px", textAlign: "center" }}>
        <Typography
          sx={{
            fontSize: "17px",
            fontFamily: "Roboto",
            fontWeight: "700",
            lineHeight: "19.92px",
          }}
        >
          What do you think about this place?
        </Typography>
        <Box sx={{ py: "22px", display: "flex", gap: "8px" }}>
          <Box
            component="img"
            src={reviewstar}
            width={"32px"}
            height={"28.05px"}
          />
          <Box
            component="img"
            src={reviewstar}
            width={"32px"}
            height={"28.05px"}
          />
          <Box
            component="img"
            src={reviewstar}
            width={"32px"}
            height={"28.05px"}
          />
          <Box
            component="img"
            src={reviewstar}
            width={"32px"}
            height={"28.05px"}
          />
          <Box
            component="img"
            src={reviewstar}
            width={"32px"}
            height={"28.05px"}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mx: "26px",
          height: "197px",
          border: "1px solid #F7F9FC",
          backgroundColor: "#F7F9FC",
        }}
      >
        <Typography
          sx={{
            p: "10px",
            fontSize: "15px",
            fontWeight: "400",
            fontFamily: "Roboto",
            lineHeight: "17.58px",
          }}
        >
          Kichi's foods and drinks are delicious!!! I will order again in the
          future.
        </Typography>
      </Box>
      <Box sx={{ px: "28px", pt: "32px" }}>
        <TextField
          placeholder="First Name"
          variant="outlined"
          sx={{
            width: "100%",
            mb: "8px",
            backgroundColor: "#f9f9f9",
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "grey", borderRadius: "8px" },
              "&:hover fieldset": { borderColor: "#002B6B" },
              "&.Mui-focused fieldset": { borderColor: "grey" },
            },
            "& .MuiInputBase-input": {
              padding: "10px 18px",
              height: "auto",
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "12px",
              fontStyle: "Roboto",
              color: "#A9A9A9",
              fontWeight: "400",
            },
          }}
        />
        <TextField
          placeholder="Email"
          variant="outlined"
          sx={{
            width: "100%",
            mb: "8px",
            backgroundColor: "#f9f9f9",
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "grey", borderRadius: "8px" },
              "&:hover fieldset": { borderColor: "#002B6B" },
              "&.Mui-focused fieldset": { borderColor: "grey" },
            },
            "& .MuiInputBase-input": {
              padding: "10px 18px",
              height: "auto",
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "12px",
              fontStyle: "Roboto",
              color: "#A9A9A9",
              fontWeight: "400",
            },
          }}
        />
        <TextField
          placeholder="Phone(optional)"
          variant="outlined"
          sx={{
            width: "100%",
            mb: "4px",
            backgroundColor: "#f9f9f9",
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "grey", borderRadius: "8px" },
              "&:hover fieldset": { borderColor: "#002B6B" },
              "&.Mui-focused fieldset": { borderColor: "grey" },
            },
            "& .MuiInputBase-input": {
              padding: "10px 18px",
              height: "auto",
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "12px",
              fontStyle: "Roboto",
              color: "#A9A9A9",
              fontWeight: "400",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "4px",
            Width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "300",
              fontFamily: "Roboto",
              lineHeight: "20px",
              color: "#0d6efd",
            }}
          >
            Share my name on review
          </Typography>
          <Box>
            <CustomCheckbox
              icon={<CheckIcon sx={{ opacity: 0 }} />}
              checkedIcon={<CheckIcon />}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ px: "20px", textAlign: "center", pt: "14px", pb: "28px" }}>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "400",
            fontFamily: "Roboto",
            lineHeight: "24px",
            color: "#ACB1C0",
          }}
        >
          Your feedback will help us
        </Typography>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "400",
            fontFamily: "Roboto",
            color: "#ACB1C0",
          }}
        >
          improve restaurants experience
        </Typography>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "400",
            fontFamily: "Roboto",
            lineHeight: "24px",
            color: "#ACB1C0",
          }}
        >
          better.
        </Typography>
      </Box>
      <Box
        sx={{ px: "20px", display: "flex", gap: "12px", alignItems: "center" }}
      >
        {/* <Box component="img" src={}/> */}
        <Typography
          sx={{
            backgroundColor: "#C5CEE0",
            width: "98px",
            height: "75px",
            borderRadius: "5px",
          }}
        ></Typography>
        <Box>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "900",
              fontFamily: "Roboto",
              lineHeight: "17.58px",
            }}
          >
            Kichi Coffee & Drink
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              pt: "6px",
              alignItems: "center",
            }}
          >
            <Box component="img" src={location} height={"100%"} />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "Roboto",
                lineHeight: "14.06px",
                color: "#ACB1C0",
              }}
            >
              76A Eighth Avenue, New York.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ px: "20px", pt: "50px", pb: "20px" }}>
        <Button
          sx={{
            backgroundColor: "#0041A8",
            borderRadius: "8px",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "700",
            lineHeight: "16px",
            color: "#FFFFFF",
            width: "100%",
            py: "12px",
            textTransform: "capitalize",
          }}
        >
          Rate this place
        </Button>
      </Box>
    </Box>
  );
}

export default RatingandReview;
