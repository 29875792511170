import axios from 'axios';
import config from './environment';
import { getAuth } from "firebase/auth";

const auth = getAuth();

export const getFreshToken = async () => {
	if (auth.currentUser) {
		return await auth.currentUser.getIdToken(true); // Force refresh
	}
	return null;
};


 const UpdateToken = async (deviceToken) => {
	try {
	  const response = await axiosInstance.post(
		"user.php?method=update_device_token",
		{ device_token: deviceToken } // ✅ Send device_token in request body
	  );
	  console.log("Token Updated:", response.data);
	} catch (error) {
	  console.error(
		"Update Token Failed:",
		error.response?.data || error.message
	  );
	}
  };
const axiosInstance = axios.create({
	baseURL: `${config.baseUrl}${config.apiServer}`
});

const useJWT = async (config) => {
	let firebaseToken = localStorage.getItem('idToken');

	if (!firebaseToken) {
		firebaseToken = await getFreshToken();
		if (firebaseToken) {
			localStorage.setItem("idToken", firebaseToken);
		}
	}

	config.headers['Authorization'] = 'Bearer ' + firebaseToken
	return config;
}

axiosInstance.interceptors.request.use(useJWT);

axiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		if (error.response?.status === 401 &&  !originalRequest._retry) {
			originalRequest._retry = true;
			const newToken = await getFreshToken(); // Refresh token on 401
			if (newToken) {
				localStorage.setItem("idToken", newToken); // ✅ Store new token
				await UpdateToken(newToken); // ✅ Send updated token to server
				axiosInstance.defaults.headers.common["Authorization"]=`Bearer ${newToken}`
				return axiosInstance(originalRequest);
			}
		}
		return Promise.reject(error);
	}
);

export default axiosInstance;