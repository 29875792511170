import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Drawer,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
  AppBar,
  Toolbar,
} from '@mui/material';
import { Menu as MenuBurgerIcon } from '@mui/icons-material';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';
import Sidebar from './dashboard/Sidebar';
import { BusinessContext } from "./BusinessContext";

const UserDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname.split('/').pop();
  
  const [selectedItem, setSelectedItem] = useState(() => {
    switch(currentPath) {
      case 'dashboard': return 'Dashboard';
      case 'plates': return 'Plates';
      case 'orders': return 'Orders';
      case 'menu': return 'Menu';
      case 'sociallinks': return 'SocialLinks';
      case 'profile': return 'Profile';
      default: return 'Dashboard';
    }
  });

  // Effect to handle initial navigation and URL sync
  useEffect(() => {
    if (location.pathname === '/dashboard') {
      navigate('/dashboard/dashboard');
      setSelectedItem('Dashboard');
    } else {
      const path = location.pathname.split('/').pop();
      switch(path) {
        case 'dashboard':
          setSelectedItem('Dashboard');
          break;
        case 'plates':
          setSelectedItem('Plates');
          break;
        case 'orders':
          setSelectedItem('Orders');
          break;
        case 'menu':
          setSelectedItem('Menu');
          break;
        case 'sociallinks':
          setSelectedItem('SocialLinks');
          break;
        case 'profile':
            setSelectedItem('Profile');
            break;
        default:
          break;
      }
    }
  }, [location.pathname, navigate]);

  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isLoading } = useContext(BusinessContext);

  const handleMenuItemClick = (itemText) => {
    setSelectedItem(itemText);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {isMobile && (
        <AppBar position="fixed" sx={{ backgroundColor: '#1a237e' }}>
          <Toolbar>
            <IconButton color="inherit" edge="start" onClick={() => setMobileOpen(!mobileOpen)}>
              <MenuBurgerIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1, ml: 2 }}>
              {selectedItem}
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={() => setMobileOpen(false)}
        sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            border: 'none',
          },
        }}
      >
        <Sidebar selectedItem={selectedItem} onMenuItemClick={handleMenuItemClick} />
      </Drawer>
      <Box
        component="main"
        sx={{
          overflowX: { xs: "hidden", sm: "auto" },
          flexGrow: 1,
          p: { xs: 0, sm: 3 },
          marginTop: isMobile ? '64px' : 0,
        }}
      >
        {isLoading ? <Typography></Typography> : <Outlet />}
      </Box>
    </Box>
  );
};

export default UserDashboard;
