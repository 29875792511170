import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../components/axios";

function ConditionalRoute() {
  const [extractedCode, setExtractedCode] = useState(null);
  const [isValidCode, setValidCode] = useState(null);
  const navigate = useNavigate();
  const firebaseUser = localStorage.getItem("firebaseUser");

  // Extract code from URL and store in state
  useEffect(() => {
    const path = window.location.pathname;
    if (path.startsWith("/r/code=")) {
      const code = path.split("code=")[1];
      setExtractedCode(code);
    } else {
      console.error("Invalid URL format:", path);
      navigate("/networkerror");
    }
  }, [navigate]);

  // Check plate code when extractedCode changes
  useEffect(() => {
    if (!extractedCode) return;

    const checkPlateCode = async () => {
      try {
        const apiResponse = await axiosInstance.post(
          "payments.php?method=plate_active",
          { plate_code: extractedCode }
        );
        console.log("Plate Code Response:", apiResponse.data);

        if (apiResponse?.data?.status === "success") {
          setValidCode(apiResponse.data.message);
        } else {
          setValidCode("networkerror");
        }
      } catch (err) {
        setValidCode("networkerror");
      }
    };

    const PlatesScan = async () => {
      try {
        const apiResponse = await axiosInstance.post(
          "payments.php?method=plate_scan",
          { plate_code: extractedCode }
        );
      } catch (err) {
        console.error("Error in Plate Scan:", err.message);
      }
    };

    checkPlateCode();
    PlatesScan();
  }, [extractedCode]);

  // Redirect based on isValidCode
  useEffect(() => {
    if (!isValidCode || !extractedCode) return;

    if (isValidCode === "available" && firebaseUser) {
      navigate(`/place/${extractedCode}`);
    } else if (isValidCode === "available") {
      navigate(`/place/${extractedCode}`);
    } else if (isValidCode === "not_available") {
      navigate(`/signup/${extractedCode}`);
    } else {
      navigate("/networkerror");
    }
  }, [isValidCode, firebaseUser, extractedCode, navigate]);

  return null;
}

export default ConditionalRoute;
