import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Configure from "../components/Configure";
import NewPlate from "../components/NewPlate";
import Login from "./Login";
import LoginOtp from "./LoginOtp";
import Product from './Product';
import Checkout from './Checkout';
import LayoutDesign from "./LayoutDesign";
import Thankyou from './Thankyou';
import Menu from "./Menu";
import Ingredients from "./Ingredients";
import LandingPage from "./LandingPage";
import RatingandReview from "./RatingandReview";
import UserDashboard from './UserDashboard';
import SignUp from "./SignUp";
import SocialMediaReview from "./SocialMediaReview";
import Plates from "./Plates";
import PlatesContent from "./dashboard/PlatesContent";
import OrdersContent from "./dashboard/OrdersContent";
import MenuContent from "./dashboard/MenuContent";
import ReviewProvider from "./ReviewProvider";
import DashboardContent from "./dashboard/DashboardContent";
import NetworkError from "./NetworkError";
import ConditionalRoute from '../globals/ConditionalGuard';
import { isAuthenticated } from "../globals/helper";
import Profile from "./dashboard/Profile";
import ResetPassword from "./ResetPassword";


// Protected Route component
const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }
  return children;
};


const AuthRoute = ({ children }) => {
  if (isAuthenticated()) {
    return <Navigate to="/dashboard" />;
  }
  return children;
};

const AppRouter = () => {

  return (
    <Routes>
      <Route path="/" element={<LayoutDesign />} />
      <Route path="/configure" element={<Configure />} />
      <Route path="/newplate" element={<NewPlate />} />
      <Route path="/login" element={<AuthRoute><Login /></AuthRoute>} />
      <Route path="/loginotp" element={< LoginOtp />} />
      <Route path="/place/:code" element={<LandingPage />} />
      <Route path="/r/*" element={<ConditionalRoute/>}/>
      <Route path="/signup/:code" element={<AuthRoute><SignUp /></AuthRoute>} />
      <Route path="/signup" element={<AuthRoute><SignUp /></AuthRoute>} />
      <Route path="/product" element={< Product />} />
      <Route path="/checkout" element={< Checkout />} />
      <Route path="/thankyou" element={<Thankyou />} />
      <Route path="/menu/:code" element={<Menu />} />
      <Route path="/ingredients" element={<Ingredients />} />

      <Route path="/ratings" element={<RatingandReview />} />
      <Route path="/networkerror" element={<NetworkError />} />

      {/* Dashboard Routes */}
      <Route path="/dashboard" element={<UserDashboard />}>
        <Route index element={<Navigate to="/dashboard/dashboard" replace />} />
        <Route path="dashboard" element={<ProtectedRoute><DashboardContent /></ProtectedRoute>} />
        <Route path="/dashboard/plates" element={<ProtectedRoute><PlatesContent /></ProtectedRoute>} />
        <Route path="/dashboard/orders" element={<ProtectedRoute><OrdersContent /></ProtectedRoute>} />
        <Route path="/dashboard/menu" element={<ProtectedRoute><MenuContent /></ProtectedRoute>} />
        <Route path="/dashboard/sociallinks" element={<ProtectedRoute><ReviewProvider /></ProtectedRoute>} />
        <Route path="/dashboard/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      </Route>

      <Route path="/socialmediareview/:code" element={<ProtectedRoute><SocialMediaReview /></ProtectedRoute>} />
      <Route path="/plates/:code" element={<ProtectedRoute><Plates /></ProtectedRoute>} />
      <Route path="/resetpassword" element={<ResetPassword></ResetPassword>}/>
      <Route path="*" element={<Navigate to="/networkerror" replace />} />
    </Routes>
  );
};

export default AppRouter;
