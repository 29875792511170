import React from "react";
import { Box, Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { BusinessContext } from "./BusinessContext";
import { useContext } from "react";

const ThankYou = () => {
  const navigate = useNavigate();
  const { fetchBusinesses } = useContext(BusinessContext);

  const handleNavigation = () => {
    const token = localStorage.getItem("idToken");
    if (token) {
      fetchBusinesses();
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
      bgcolor="#f9f9f9"
    >
      <CheckCircleIcon sx={{ fontSize: 80, color: "green", mb: 2 }} />
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Thank You for Your Purchase!
      </Typography>
      <Typography variant="body1" color="textSecondary" mb={3}>
        Your order has been successfully placed. We appreciate your business!
      </Typography>
      <Button variant="contained" color="primary" onClick={handleNavigation}>
        Back to Home
      </Button>
    </Box>
  );
};

export default ThankYou;
