import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { auth } from "../firebase/FirebaseConfig";
import { GoogleAuthProvider } from "firebase/auth";
import { signInWithCredential } from "../firebase/FirebaseConfig";
import { BusinessContext } from "./BusinessContext";
import bluelogo from "../images/bluelogo.png";
import { GoogleLogin } from "@react-oauth/google";
import { sendPasswordResetEmail } from "firebase/auth";
import { useMediaQuery, useTheme } from "@mui/material";

import useFirebaseAuth from "../globals/hooks/useFirebaseAuth";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const { fetchBusinesses } = useContext(BusinessContext);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [openForgotModal, setOpenForgotModal] = useState(false);
  const [forgotMessage, setForgotMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [signInWithFireBase] = useFirebaseAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Handle Input Change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      // Firebase Login
      await signInWithFireBase(formData);
      // Redirect to Dashboard
      fetchBusinesses();
      navigate("/dashboard");
    } catch (err) {
      if (err.code === "auth/wrong-password") {
        setError("Incorrect password. Please try again.");
      } else if (err.code === "auth/user-not-found") {
        setError("No user found with this email. Please register.");
      } else {
        setError("Login failed. Please try again.");
      }
    }
  };

  const handleGoogleLogin = async (response) => {
    if (response.credential) {
      setGoogleLoading(true);
      const token = response.credential;

      try {
        // Create a Google credential with the token
        const credential = GoogleAuthProvider.credential(token);

        // Sign in to Firebase with the credential
        const userCredential = await signInWithCredential(auth, credential);
        const user = userCredential.user;
        const idToken = await user.getIdToken(); // Get ID token for API requests

        // Store user details in localStorage
        localStorage.setItem("firebaseUser", JSON.stringify(user));
        localStorage.setItem("googleToken", token);
        localStorage.setItem("idToken", idToken);
        localStorage.setItem("userEmail", user.email);
        localStorage.setItem("firstName", user.displayName?.split(" ")[0]);
        localStorage.setItem("lastName", user.displayName?.split(" ")[1]);
        // Navigate to Register page after successful logi
        fetchBusinesses();
        //  fetchData();
        setGoogleLoading(false);
        navigate("/dashboard");
      } catch (error) {
        console.error("Firebase Authentication Error:", error.message);
      }
    } else {
      console.error("No token received from Google.");
    }
  };

  const errorMessage = (error) => {
    console.log("Google Login Error:", error);
  };

  const handleForgotPassword = async () => {
    if (!forgotEmail) {
      setForgotMessage("Please enter your email.");
      return;
    }
  
    setLoading(true);
    try {
      const actionCodeSettings = {
        url: `https://app.pointgenie.com/resetpassword?email=${forgotEmail}`, // Custom reset URL
        handleCodeInApp: true, // Ensures Firebase handles it inside your app
      };
  
      await sendPasswordResetEmail(auth, forgotEmail, actionCodeSettings);
      setForgotMessage("Reset link sent successfully. Check your email.");
      setTimeout(() => {
        setOpenForgotModal(false);
        setForgotEmail("");
        setForgotMessage("");
      }, 1000);
    } catch (error) {
      setForgotMessage("Error sending reset email. Try again.");
      console.error(error);
    }
    setLoading(false);
  };
  return (
    <Box
      sx={{
        pt: { xs: "40px", sm: "55px" },
      }}
    >
      <Container maxWidth="xs">
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "20px",
          }}
        >
          <Box sx={{ textAlign: "center", pb: { xs: "", sm: "28px" } }}>
            <Box
              component="img"
              src={bluelogo}
              sx={{
                cursor: "pointer",
                width: { xs: "55%", sm: "192.99px" },
                height: { xs: "", sm: "43px" },
              }}
            />
          </Box>

          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontWeight: "600",
              pb: "20px",
              marginBottom: 3,
              fontSize: "21px",
              lineHeight: "140%",
              fontFamily: "Roboto",
            }}
          >
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              type="email"
              // label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder=" Email "
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    border: "1px soild #BDBDBD",
                  },
                  "&:hover fieldset": {
                    borderColor: "#BDBDBD",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#BDBDBD",
                  },
                  "& input::placeholder": {
                    color: "#949494",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "500",
                    opacity: 1,
                  },
                },
                mb: "8px",
              }}
            />
            <TextField
              fullWidth
              type="password"
              // label="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              placeholder="Password "
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    border: "1px soild #BDBDBD",
                  },
                  "&:hover fieldset": {
                    borderColor: "#BDBDBD",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#BDBDBD",
                  },
                  "& input::placeholder": {
                    color: "#949494",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "500",
                    opacity: 1,
                  },
                },
              }}
            />
            {error && (
              <Typography
                color="error"
                sx={{ textAlign: "center", marginTop: 2 }}
              >
                {error}
              </Typography>
            )}

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                marginTop: 4,
                backgroundColor: "#0041A8",
                color: "white",
                borderRadius: "58px",
                py: "18px",
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Roboto",
                lineHeight: "16px",
                textTransform: "capitalize",
              }}
            >
              Login
            </Button>
          </form>
          {/* Forgot Password Modal */}
          <Button
            fullWidth
            sx={{ mt: 2, backgroundColor: "transparent", color: "#0041A8" }}
            onClick={() => setOpenForgotModal(true)}
          >
            Forgot Password?
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              my: 3,
            }}
          >
            <Divider sx={{ flex: 0.1, borderColor: "black" }} />
            <Typography
              sx={{
                mx: 0.4,
                color: "#222B45",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "Roboto",
              }}
            >
              Or
            </Typography>
            <Divider sx={{ flex: 0.1, borderColor: "black" }} />
          </Box>

          <Box
            sx={{
              width: "100%",
              // backgroundColor: "#4285F4",
              // border:"solid 1px #d7d7d7",
              borderRadius: "11px",
              textAlign: "center",
            }}
          >
            <Button
              sx={{
                textTransform: "capitalize",
                color: "#fff",
                fontSize: "15px",
                fontFamily: "Roboto",
                fontWeight: "700",
              }}
              disabled={googleLoading}
              onClick={() =>
                document.getElementById("googleSignInButton").click()
              }
            >
              {googleLoading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <GoogleLogin
                    onSuccess={handleGoogleLogin}
                    onError={errorMessage}
                    width="100%"
                    id="googleSignInButton"
                    style={{ display: "none" }}
                  />
                </>
              )}
            </Button>
          </Box>
        </Box>
      </Container>
      <Dialog
        open={openForgotModal}
        onClose={() => setOpenForgotModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent
          sx={{
            minWidth: isMobile ? "300px" : "400px", // Adjust width for mobile
            padding: "20px",
          }}
        >
          <TextField
            fullWidth
            type="email"
            label="Enter your email"
            value={forgotEmail}
            onChange={(e) => setForgotEmail(e.target.value)}
            sx={{ mt: 2 }}
          />
          {forgotMessage && (
            <Typography sx={{ mt: 1, color: "green" }}>
              {forgotMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenForgotModal(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleForgotPassword}
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "Send Reset Link"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Login;
