import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Radio,
  TextField,
  CircularProgress,
} from "@mui/material";
import productimg from "../images/productimg.png";
// import Star3 from "../images/Star3.png";
import Star1 from "../images/Star1.png";
import uploadimage from "../images/uploadimage.png";
import days from "../images/days.png";
import truck from "../images/truck.png";
import easyreturn from "../images/easyreturn.png";
import Header from "../components/Header";
import Footer from "./Footer";
import { useJsApiLoader } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";
import { getGeocode } from "use-places-autocomplete";
import axiosInstance from "./axios";

const libraries = ["places"];
function Product() {
  const [selectedValue, setSelectedValue] = useState({
    productId: "",
    plates: "",
    price: "",
  });
  const [addressError, setAddressError] = useState("");

  const navigate = useNavigate();
  const handleOptionChange = (productId, plates, price) => {
    setSelectedValue({
      productId,
      plates,
      price,
    });
  };
  const [products, setProducts] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const autoCompleteRef = useRef(null);
  const [placeId, setPlaceId] = useState(null);

  const onLoad = (autocomplete) => {
    autoCompleteRef.current = autocomplete;
  };

 const onPlaceChanged = async () => {
     if (autoCompleteRef.current !== null) {
       const place = autoCompleteRef.current.getPlace();
       console.log("Full Place Details:", place);
   
       if (place) {
         // ✅ Get the correct place_id from place object
         if (place.place_id) {
           setPlaceId(place.place_id);
           console.log("Correct Place ID:", place.place_id);
         } else {
           console.warn("Place ID not found in place object.");
         }
   
         // ✅ Set address if available
         if (place.formatted_address) {
           setSelectedAddress(place.formatted_address);
           setAddressError(""); // Clear any previous error
         }
   
         try {
           // Fetch geocode details if needed
           const results = await getGeocode({ address: place.formatted_address });
           console.log("Geocode results:", results);
         } catch (error) {
           console.error("Error fetching geocode:", error);
         }
       }
     }
   };
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axiosInstance.post(
          "payments.php?method=products"
        );
        console.log("response", response.data.places);
        setProducts(response.data.places);

        if (
          response.data &&
          response.data.status === "success" &&
          response.data.places
        ) {
          setProducts(response.data.places);
          // Set the first product as default selection if no product is currently selected
          if (!selectedValue.productId && response.data.places.length > 0) {
            const firstProduct = response.data.places[0];
            setSelectedValue({
              productId: firstProduct.id,
              plates: firstProduct.name,
              price: firstProduct.price,
            });
          }
        }
      } catch (err) {
        console.error("error", err);
      }
    };
    fetchProducts();
  }, []);

  console.log("placeId", placeId);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBK2LtRcuFRSz4ZYZwaz8XAaj3RMTOKfh8",
    libraries,
  });

  const handleCheckout = () => {
    if (!selectedAddress) {
      setAddressError(
        "Please enter your location before purchasing the product"
      );
      return;
    }
    navigate("/checkout", {
      state: {
        plates: selectedValue.plates,
        price: selectedValue.price,
        producId: selectedValue.productId,
        placeid: placeId,
      },
    });
  };

  if (loadError)
    return <Typography color="error">Error loading maps</Typography>;
  if (!isLoaded) return <CircularProgress />;
  return (
    <Box>
      <Header />
      <Grid
        container
        pt={{ xs: "20px", sm: "70px" }}
        px={{ xs: "10px", sm: "0px" }}
      >
        <Grid items xs={12} sm={6} md={6} xl={5.7} textAlign={"end"}>
          <Box
            component="img"
            src={productimg}
            width={{ xs: "100%", md: "90%", xl: "auto" }}
          />
        </Grid>

        <Grid items xs={12} sm={6} md={6} xl={6} sx={{}}>
          <Grid container>
            <Grid items sm={1} xl={2}></Grid>
            <Grid items xs={12} sm={8} md={10} xl={8}>
              <Box
                display="flex"
                gap={{ xs: 0.2, sm: 0.4 }}
                // mx={{ xs: 0, sm: 2 }}
                mt={{ xs: 3, sm: 1.5 }}
                alignItems={"center"}
                justifyContent={{ xs: "center", sm: "start" }}
                paddingBottom={{ xs: "", sm: "3px" }}
              >
                {[...Array(5)].map((_, index) => (
                  <Box
                    component="img"
                    src={Star1}
                    sx={{
                      width: { xs: "10px", sm: "16px" },
                      height: { xs: "10px", sm: "16px" },
                      color: "#FBBC04",
                      // alignItems:"center",
                      // justifyContent:"center",
                      // display:"flex",
                    }}
                  />
                ))}
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    paddingLeft: "5px",
                    color: "#000000",
                  }}
                >
                  5.0{" "}
                  <Box component="span" mx={1}>
                    |
                  </Box>{" "}
                  <Box component="span" ms={1}>
                    +1403 Reviews
                  </Box>
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: { xs: "26px", sm: "40px" },
                  lineHeight: "46.34px",
                  fontWeight: "700",
                  textAlign: { xs: "center", sm: "start" },
                }}
              >
                Google Reviews - Plate
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: "500",
                  pb: { xs: "16px" },
                  pt: { xs: "8px", sm: "16px" },
                  textAlign: { xs: "center", sm: "start" },
                  letterSpacing: "1px",
                  borderBottom: "1px solid rgb(189, 189, 189)",
                }}
              >
                Get more 5 star google reviews or get your money back!
              </Typography>
              <Box
                paddingTop={{ xs: "18px", sm: "25px" }}
                paddingBottom={{ xs: "16px", sm: "0px" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "16px", sm: "20px" },
                    lineHeight: "34px",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    paddingLeft: { xs: "16px", sm: "0px" },
                  }}
                >
                  Collect Reviews in 3 Seconds!
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "16px", sm: "20px" },
                    lineHeight: "34px",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    py: "5px",
                    paddingLeft: { xs: "16px", sm: "0px" },
                  }}
                >
                  Pay Once - No Subscriptions, No Fees
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "16px", sm: "20px" },
                    lineHeight: "34px",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    paddingLeft: { xs: "16px", sm: "0px" },
                  }}
                >
                  Rank at the Top of Google Searches
                </Typography>
              </Box>
              <Box sx={{ paddingTop: { xs: "", sm: "20px" } }}>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    lineHeight: { xs: "22px", sm: "34px" },
                    color: "#000000",
                    paddingLeft: { xs: "16px", sm: "0px" },
                    paddingBottom: { xs: "6px", sm: "0px" },
                  }}
                >
                  All Phones Compatible! Your customers don't need an app
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    lineHeight: { xs: "25px", sm: "34px" },
                    color: "#000000",
                    paddingLeft: { xs: "16px", sm: "0px" },
                    paddingBottom: { xs: "6px", sm: "0px" },
                  }}
                >
                  We create the QR code for you
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    lineHeight: { xs: "25px", sm: "34px" },
                    color: "#000000",
                    paddingLeft: { xs: "16px", sm: "0px" },
                    paddingBottom: { xs: "8px", sm: "0px" },
                  }}
                >
                  Unlimited use | No wifi or power supply needed
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    lineHeight: { xs: "22px", sm: "34px" },
                    color: "#000000",
                    paddingLeft: { xs: "16px", sm: "0px" },
                    paddingBottom: { xs: "6px", sm: "0px" },
                  }}
                >
                  Plexiglass | Size : 4.7"x4,7" (12x12cm), Thick : 0.1" (3mm) |
                  Supplied with adhesive located on the back
                </Typography>
              </Box>
            </Grid>
            <Grid items sm={1} xl={2}></Grid>
          </Grid>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            pb={1}
            pt={0}
          >
            <Typography
              component="span"
              sx={{
                borderTop: "1px solid ",
                width: { xs: "100px", sm: "31%", xl: "270px" },
              }}
            ></Typography>
            <Typography
              sx={{
                fontSize: { xs: "14px", sm: "16px" },
                lineHeight: "34px",
                fontWeight: "500",
                letterSpacing: "1px",
                px: 1,
                fontFamily: "Montserrat",
              }}
            >
              BUNDLE & Save
            </Typography>
            <Typography
              component="span"
              sx={{
                borderTop: "1px solid ",
                width: { xs: "100px", sm: "31%", xl: "270px" },
              }}
            ></Typography>
          </Box>
          <Box sx={{ width: "78%", mx: "auto" }}>
            <Autocomplete 
            onLoad={onLoad} 
            onPlaceChanged={onPlaceChanged}
            options={{
              types: [
                "restaurant",
                "food",
                "bar",
                "night_club",
                "lodging"
              ],
            }}
            >
              <TextField
                fullWidth
                variant="outlined"
                label="Search for an address"
                error={!!addressError}
                helperText={addressError}
                sx={{
                  marginBottom: "10px",
                  borderRadius: "10px",
                  fontWeight: 500,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "10px",
                      borderColor: addressError ? "red" : "black",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "10px",
                    color: "black",
                  },
                  "& .MuiInputLabel-root": {
                    color: addressError ? "red" : "black",
                    fontWeight: "bold",
                    top: "-6px",
                    fontSize: "14px",
                    fontFamily: "Goldplay",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: addressError ? "red" : "black",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "red",
                    marginLeft: "0",
                    fontFamily: "Montserrat",
                  },
                }}
              />
            </Autocomplete>
          </Box>
          <Box
            sx={{
              backgroundColor: "#2B56F5",
              width: { xs: "100%", sm: "93%", xl: "80%" },
              py: { xs: "8px", sm: "22px", xl: "28px" },
              px: { xs: "", sm: "10px" },
              borderRadius: { xs: "12px", sm: "16px" },
              mx: { xs: "", sm: "auto" },
              mb: { xs: "15px", sm: "0px", xl: "10px" },
            }}
          >
            <Box
              sx={{
                border: "1px solid #2B56F5",
                display: "flex",
                alignItems: "center",
                gap: { xs: "24px", sm: "40px", xl: "68px" },
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Radio
                  checked
                  sx={{
                    color: "#2B56F5",
                    "&.Mui-checked": {
                      color: "#2B56F5",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: { xs: "35px", sm: "40px", xl: "50px" },
                      borderRadius: "10px",
                      backgroundColor: "#ffffff",
                      border: "2px solid #2B56F5",
                      // px:{xs:"", sm:"2px"}
                    },
                  }}
                />
                <Typography
                  component="span"
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "14x", sm: "18px", xl: "24px" },
                    lineHeight: "34px",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    color: "white",
                  }}
                >
                  {selectedValue.plates}
                </Typography>
              </Box>
              <Button onClick={handleCheckout}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "14x", sm: "20px", xl: "24px" },
                    lineHeight: "34px",
                    fontWeight: "800",
                    letterSpacing: "1px",
                    color: "white",
                  }}
                >
                  Buy Now
                </Typography>
              </Button>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: { xs: "14x", sm: "24px" },
                  lineHeight: "34px",
                  fontWeight: "600",
                  letterSpacing: "1px",
                  color: "white",
                }}
              >
                ${selectedValue.price}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container px={{ xs: "10px", sm: "0px" }}>
        <Grid item sm={0.8}></Grid>
        <Grid
          item
          xs={12}
          sm={5}
          mt={{ xs: "px", sm: "12px" }}
          mb={{ xs: "15px", sm: "0px" }}
          display={"flex"}
          gap={2}
        >
          <Box
            component="img"
            src={uploadimage}
            width={{ xs: "50px", sm: "80px" }}
            height={{ xs: "50px", sm: "80px" }}
          />
          <Box
            component="img"
            src={uploadimage}
            width={{ xs: "50px", sm: "80px" }}
            height={{ xs: "50px", sm: "80px" }}
          />
          <Box
            component="img"
            src={uploadimage}
            width={{ xs: "50px", sm: "80px" }}
            height={{ xs: "50px", sm: "80px" }}
          />
          <Box
            component="img"
            src={uploadimage}
            width={{ xs: "50px", sm: "80px" }}
            height={{ xs: "50px", sm: "80px" }}
          />
        </Grid>
        <Grid
          items
          xs={12}
          sm={6}
          display={"flex"}
          pt={{ sm: "10px", xl: "0px" }}
          flexDirection={"row"}
          gap={{ xs: 4, sm: 4, xl: "55px" }}
          justifyContent={"center"}
        >
          <Box textAlign={"center"}>
            <Box
              component="img"
              src={days}
              width={{ xs: "40px", sm: "auto" }}
              py={{ xs: "10px", sm: "0px" }}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: { xs: "12px", sm: "16px" },
                lineHeight: { xs: "16px", sm: "34px" },
                fontWeight: "900",
              }}
            >
              30-Day Risk-Free trial
            </Typography>
          </Box>
          <Box textAlign={"center"}>
            <Box
              component="img"
              src={truck}
              width={{ xs: "40px", sm: "auto" }}
              py={{ xs: "10px", sm: "0px" }}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: { xs: "12px", sm: "16px" },
                lineHeight: { xs: "16px", sm: "34px" },
                fontWeight: "900",
              }}
            >
              Free Shipping
            </Typography>
          </Box>
          <Box textAlign={"center"}>
            <Box
              component="img"
              src={easyreturn}
              width={{ xs: "40px", sm: "auto" }}
              py={{ xs: "10px", sm: "0px" }}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: { xs: "12px", sm: "16px" },
                lineHeight: { xs: "16px", sm: "34px" },
                fontWeight: "900",
              }}
            >
              Easy & Free Return
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2.5}
        pt={{ xs: "10px", sm: "20px" }}
        pb={{ xs: "", sm: "20px" }}
        px={{ xs: "10px", sm: "0px" }}
      >
        <Grid item xl={0.7}></Grid>
        {products.map((product, index) => (
          <Grid
            item
            xs={12}
            md={3.9}
            xl={3.4}
            key={index}
            position={index === 1 ? "relative" : "static"}
          >
            <Box
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.2)",
                borderRadius: "15px",
                borderWidth: "1",
                my: { xs: "10px", sm: "20px" },
                pb: "10px",
                pt: { xs: "20px", sm: "28px" },
              }}
            >
              <Box
                display={"flex"}
                gap={{ xs: "22px", sm: "14px", xl: "16px" }}
                alignItems={"start"}
                justifyContent={"center"}
              >
                <Radio
                  checked={selectedValue.productId === product.id}
                  onChange={() =>
                    handleOptionChange(product.id, product.name, product.price)
                  }
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#2B56F5",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: { xs: "35px", sm: "40px", xl: "50px" },
                      borderRadius: "10px",
                      backgroundColor: "#ffffff",
                      border: "2px solid rgba(0, 0, 0, 0.2)",
                      padding: "2px",
                    },
                    "&.Mui-checked::after": {
                      content: '""',
                      width: { xs: "14px", sm: "16px", xl: "22px" },
                      height: { xs: "14px", sm: "16px", xl: "22px" },
                      backgroundColor: "#2B56F5",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    },
                  }}
                />

                <Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: { xs: "16px", sm: "20px", xl: "24px" },
                      fontWeight: "500",
                      lineHeight: "34px",
                      letterSpacing: "1px",
                    }}
                  >
                    {product.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: { xs: "12px", sm: "12px", xl: "14px" },
                      fontWeight: "500",
                      lineHeight: { xs: "22px", sm: "18px", xl: "34px" },
                      letterSpacing: "1px",
                    }}
                  >
                    + FreeBook & Gift
                  </Typography>
                </Typography>
                <Typography textAlign={"center"}>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: { xs: "18px", sm: "24px" },
                      fontWeight: "500",
                      lineHeight: "30px",
                      letterSpacing: "1px",
                    }}
                  >
                    ${product.price}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: { xs: "12px", sm: "14px" },
                      fontWeight: "500",
                      lineHeight: { xs: "16px", sm: "24px" },
                      letterSpacing: "1px",
                      color: "rgb(134, 133, 133)",
                    }}
                  >
                    {/* {product.originalPrice} */}
                    <del>${product.retail_price}</del>
                  </Typography>
                  <Button
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: { xs: "14px", sm: "14px", xl: "15px" },
                      fontWeight: "500",
                      lineHeight: "34px",
                      letterSpacing: "1px",
                      backgroundColor: "#2B56F5",
                      color: "#FFFFFF",
                      py: "0",
                      // px: "20px",
                      height: "28px",
                      width: { xs: "110px", sm: "max-content", xl: "141px" },
                      textTransform: "capitalize",
                      borderRadius: "5px",
                    }}
                  >
                    {Math.round(
                      ((product.retail_price - product.price) /
                        product.retail_price) *
                        100
                    )}
                    %
                  </Button>
                </Typography>
              </Box>
            </Box>
            {index === 1 && (
              <Box
                position={"absolute"}
                top={{ xs: "6px", sm: "25px" }}
                right={{ xs: "31%", sm: "32%" }}
              >
                <Button
                  sx={{
                    backgroundColor: "#34A853",
                    color: "#FFFFFF",
                    fontSize: "15px",
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    width: { xs: "141px", sm: "141px" },
                    height: { xs: "30px", sm: "28px" },
                    borderRadius: "5px",
                    textTransform: "capitalize",
                  }}
                >
                  Most Savings
                </Button>
              </Box>
            )}
          </Grid>
        ))}
        <Grid item sm={1} xl={1}></Grid>
      </Grid>
      <Footer />
    </Box>
  );
}

export default Product;
