// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .background-c{
    background-color: #122272;
    height: 100vh;
}
.register-width{
    max-width: 450px;
}
.heading i{
font-size: 30px;
color: white;
}
.heading p{
font-size: 28px;
color: white;
}
.cardheading p{
    font-size:22px;
    line-height: 1.2;
}
.cardheading span{
    font-size: 14px;
    color: grey;
}
.registerbtn span{
    font-size: 16px;
}
.registerbtn p{
    font-size: 14px;
}
.registerbtn button , .registerbtn button:hover{
    background-color: #122272;
    font-size: 20px;
}
.register-card{
    border: black;
    background-color: white;
    border-radius: 35px;
}
.input-border input{
    border-radius: 10px;
    font-weight: 500;
}
@media screen and (min-width:320px) and (max-width:560px) {
    .heading p{
        font-size: 26px;
    }
    .registerbtn button{
        font-size: 18px;
    }
    .registerbtn p{
        font-size: 12px;
    }
    .registerbtn span{
        font-size: 14px;
    }
    .register-card{
        height: 100vh;
    }
} */`, "",{"version":3,"sources":["webpack://./src/components/Register.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA0DG","sourcesContent":["/* .background-c{\n    background-color: #122272;\n    height: 100vh;\n}\n.register-width{\n    max-width: 450px;\n}\n.heading i{\nfont-size: 30px;\ncolor: white;\n}\n.heading p{\nfont-size: 28px;\ncolor: white;\n}\n.cardheading p{\n    font-size:22px;\n    line-height: 1.2;\n}\n.cardheading span{\n    font-size: 14px;\n    color: grey;\n}\n.registerbtn span{\n    font-size: 16px;\n}\n.registerbtn p{\n    font-size: 14px;\n}\n.registerbtn button , .registerbtn button:hover{\n    background-color: #122272;\n    font-size: 20px;\n}\n.register-card{\n    border: black;\n    background-color: white;\n    border-radius: 35px;\n}\n.input-border input{\n    border-radius: 10px;\n    font-weight: 500;\n}\n@media screen and (min-width:320px) and (max-width:560px) {\n    .heading p{\n        font-size: 26px;\n    }\n    .registerbtn button{\n        font-size: 18px;\n    }\n    .registerbtn p{\n        font-size: 12px;\n    }\n    .registerbtn span{\n        font-size: 14px;\n    }\n    .register-card{\n        height: 100vh;\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
