import React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  Container,
} from "@mui/material";
import {useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import rightarrow from "../images/rightarrow.png";
import greylogo from "../images/greylogo.png";
import logo from "../images/logo.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MediumIcon from "@mui/icons-material/Article";
const Footer = () => {
  const navigate = useNavigate();
  const DomainUrl = process.env.REACT_APP_DOMAIN_URL
  const PointGenieUrl =  process.env.REACT_APP_POINTGENIE_URL;

  return (
    <Box sx={{ backgroundColor: "rgb(33, 75, 226)", py: "55px" }}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <Box sx={{ textAlign: { xs: "center", sm: "start" } }}>
              <Box
                component="img"
                src={logo}
                sx={{ height: "35px", width: "135px" }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  color: "white",
                  pt: { xs: "6px", sm: "6px" },
                  pb: { xs: "10px", sm: "10px" },
                }}
              >
                Be part of our community
              </Typography>
              <Box>
                <IconButton
                  component="a"
                  href="https://twitter.com/pointgenie1"
                  target="_blank"
                  sx={{
                    color: "#fff",
                    pl: { xs: "0px" },
                    pr: "2px",
                    width: "1em",
                  }}
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://www.facebook.com/PointGenie"
                  target="_blank"
                  sx={{ color: "#fff", pr: "2px" }}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://www.instagram.com/pointgenieapp?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  target="_blank"
                  sx={{ color: "#fff", pr: "2px" }}
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://medium.com/@PointGenie"
                  target="_blank"
                  sx={{ color: "#fff", pr: "2px" }}
                >
                  <MediumIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={{ textAlign: { xs: "center", sm: "start" } }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  color: "white",
                  pt: { xs: "32px", sm: "6px" },
                  pb: { xs: "8px", sm: "8px" },
                }}
              >
                Company
              </Typography>
     
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  lineHeight: 1.5,
                  color: "white",
                  cursor: "pointer",
                  pt: "5px",
                  pb: "5px",
                 }}
                onClick={() => window.open(`${PointGenieUrl}blog.php`, "_blank")}
                >
                Blog
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  lineHeight: 1.5,
                  color: "white",
                  cursor: "pointer",
                  pt: "5px",
                  pb: "5px",
                }}
                onClick={() => window.open(`${PointGenieUrl}about.php`, "_blank")}
              >
                About Us
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  lineHeight: 1.5,
                  color: "white",
                  cursor: "pointer",
                  pt: "5px",
                  pb: "5px",
                }}
                onClick={() => window.open(`${PointGenieUrl}index.php#contact`, "_blank")}
              >
                Contact Us
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  lineHeight: 1.5,
                  color: "white",
                  cursor: "pointer",
                  pt: "5px",
                  pb: "5px",
                }}
                onClick={() => window.open(`${PointGenieUrl}affiliate.php`, "_blank")}
              >
                Affilate With Us
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{ textAlign: { xs: "center", sm: "start" } }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontFamily: "Montserrat",
                fontWeight: "600",
                color: "white",
                pt: { xs: "32px", sm: "6px" },
                pb: { xs: "", sm: "8px" },
              }}
            >
              Legal
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "500",
                lineHeight: 1.5,
                color: "white",
                cursor: "pointer",
                pt: "5px",
                pb: "5px",
              }}
              onClick={() => window.open(`${PointGenieUrl}press_release.php`, "_blank")}
            >
              Press Release
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "500",
                lineHeight: 1.5,
                color: "white",
                cursor: "pointer",
                pt: "5px",
                pb: "5px",
              }}
              onClick={() => window.open(`${PointGenieUrl}terms_policy.php`, "_blank")}
            >
              Terms and Condition
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "500",
                lineHeight: 1.5,
                color: "white",
                cursor: "pointer",
                pt: "5px",
                pb: "5px",
              }}
              onClick={() => window.open(`${PointGenieUrl}privacy_policy.php`, "_blank")}
            >
              Privacy Policy
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{ textAlign: { xs: "center", sm: "start" } }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontFamily: "Montserrat",
                fontWeight: "600",
                color: "white",
                pt: { xs: "32px", sm: "6px" },
                pb: { xs: "", sm: "8px" },
              }}
            >
              Quick Links
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "500",
                lineHeight: 1.5,
                color: "white",
                cursor: "pointer",
                pt: { xs: "", sm: "5px" },
                pb: "5px",
              }}
              onClick={() => window.open(`${DomainUrl}login`, "_blank")}
            >
              Login
            </Typography>
            {/* <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "500",
                lineHeight: 1.5,
                color: "white",
                cursor: "pointer",
                pt: "5px",
                pb: "5px",
              }}
            >
              Sign Up
            </Typography> */}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mx: "auto" }}>
            <Typography
              sx={{ borderBottom: "1px solid white", pt: "16px", mb: "10px" }}
            ></Typography>
            <Box textAlign={"center"}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  lineHeight: 1.5,
                  color: "white",
                  pt: "4px",
                  pb: "4px",
                }}
              >
                Copyright© 2025 Pointgenie LLC. All rights reserved.
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  lineHeight: 1.5,
                  color: "white",
                  pt: { xs: "8px", sm: "8px" },
                  pb: { xs: "", sm: "4px" },
                }}
              >
                Handcrafted in Florida, USA
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
