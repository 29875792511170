import React from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
} from "@mui/material";
import days from "../images/days.png";
import truck from "../images/truck.png";
import easyreturn from "../images/easyreturn.png";
import livesupport from "../images/livesupport.png";
import paymentimage from "../images/securepayment.png";
import review1 from "../images/review1.jpeg";
import review2 from "../images/review2.jpeg";
import review3 from "../images/review3.jpeg";
import StarIcon from "@mui/icons-material/Star";

function Reviews() {
  return (
    <>
      <Box sx={{ paddingBottom: { xs: "38px" } }}>
        <Box sx={{ textAlign: "center", pb: { xs: "25px", sm: "40px" } }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "20px", sm: "40px" },
              fontWeight: "Bold",
              letterSpacing: "0.77px",
            }}
          >
            {" "}
            Why More Business Owners are
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "20px", sm: "40px" },
              fontWeight: "Bold",
              letterSpacing: "0.77px",
            }}
          >
            Choosing us Every Day
          </Typography>
        </Box>
        <Container>
          <Grid container position={"relative"} sx={{}}>
            <Grid item xs={12} sm={4} pb={{ xs: "14px", sm: "18px" }}>
              <Box
                sx={{
                  // border: "2px solid black",
                  borderRadius: "30px",
                  backgroundColor: "#F9FAFB",
                  mx: { sm: "12px" },
                  boxShadow: 3,
                }}
              >
                <Box
                  component="img"
                  src={review3}
                  sx={{
                    // border: "2px solid ",
                    borderRadius: "30px 30px 0px 0px ",
                    width: { xs: "100%", sm: "100%" },
                    height: { xs: "80%", sm: "80%" },
                  }}
                />
                <Box sx={{ p: "10px" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", sm: "16px" },
                      fontWeight: "700",
                      fontFamily: "Inter",
                      lineHeight: { xs: "20px", sm: "26px" },
                    }}
                  >
                    David Diaz
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", sm: "14px" },
                      fontWeight: "500",
                      fontFamily: "Inter",
                      lineHeight: { xs: "20px", sm: "20px" },
                    }}
                  >
                   CorpoBello Spa
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", sm: "14px" },
                      fontWeight: "500",
                      fontFamily: "Inter",
                      lineHeight: { xs: "20px", sm: "20px" },
                    }}
                  >
                   Puerto Rico US
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", sm: "12px" },
                      fontWeight: "regular",
                      fontFamily: "Inter",
                      color: "#9CA3AF",
                      lineHeight: { xs: "18px", sm: "24px" },
                      pt: { xs: "15px", sm: "4px" },
                      pb: { xs: "6px", sm: "4px" },
                    }}
                  >
                    {" "}
                    20/03/2025
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ pb: "5px" }}>
                    {[...Array(5)].map((_, index) => (
                      <StarIcon
                        key={index}
                        sx={{ color: "#ffc107", fontSize: "20px" }}
                      />
                    ))}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", sm: "14px" },
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      color: "#141414",
                      lineHeight: { xs: "24px", sm: "20px" },
                      pt: { xs: "15px", sm: "5px" },
                      pb: { xs: "6px", sm: "15px" },
                    }}
                  >
                    PointGenie has been a game-changer for my spa. Customers
                    love checking in and leaving reviews, and we’ve seen a
                    noticeable increase in new clients thanks to the positive
                    feedback. It’s easy to use, and I love how simple it is to
                    manage everything from one place!
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} pb={{ xs: "14px", sm: "18px" }}>
              <Box
                sx={{
                  // border: "2px solid black",
                  borderRadius: "30px",
                  backgroundColor: "#F9FAFB",
                  mx: { sm: "12px" },
                  boxShadow: 3,
                }}
              >
                <Box
                  component="img"
                  src={review2}
                  sx={{
                    // border: "2px solid ",
                    borderRadius: "30px 30px 0px 0px ",
                    width: { xs: "100%", sm: "100%" },
                    height: { xs: "80%", sm: "80%" },
                  }}
                />
                <Box sx={{ p: "10px" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", sm: "16px" },
                      fontWeight: "700",
                      fontFamily: "Inter",
                      lineHeight: { xs: "20px", sm: "26px" },
                    }}
                  >
                    Ariel Perez
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", sm: "14px" },
                      fontWeight: "500",
                      fontFamily: "Inter",
                      lineHeight: { xs: "20px", sm: "20px" },
                    }}
                  >
                   MostWanted Cuts
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", sm: "14px" },
                      fontWeight: "500",
                      fontFamily: "Inter",
                      lineHeight: { xs: "20px", sm: "20px" },
                    }}
                  >
                  Newark, NJ US
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", sm: "12px" },
                      fontWeight: "regular",
                      fontFamily: "Inter",
                      color: "#9CA3AF",
                      lineHeight: { xs: "18px", sm: "24px" },
                      pt: { xs: "15px", sm: "4px" },
                      pb: { xs: "6px", sm: "4px" },
                    }}
                  >
                    {" "}
                    20/03/2025
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ pb: "5px" }}>
                    {[...Array(5)].map((_, index) => (
                      <StarIcon
                        key={index}
                        sx={{ color: "#ffc107", fontSize: "20px" }}
                      />
                    ))}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", sm: "14px" },
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      color: "#141414",
                      lineHeight: { xs: "24px", sm: "20px" },
                      pt: { xs: "15px", sm: "5px" },
                      pb: { xs: "6px", sm: "15px" },
                    }}
                  >
                    Honestly, I wasn’t sure if PointGenie would make a big
                    difference, but wow — it’s been amazing. Our clients check
                    in, leave reviews, and even follow us on social media right
                    from the tag. It’s helped us build a solid online presence
                    without much extra effort.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} pb={{ xs: "14px", sm: "18px" }}>
              <Box
                sx={{
                  // border: "2px solid black",
                  borderRadius: "30px",
                  backgroundColor: "#F9FAFB",
                  mx: { sm: "12px" },
                  boxShadow: 3,
                }}
              >
                <Box
                  component="img"
                  src={review1}
                  sx={{
                    // border: "2px solid ",
                    borderRadius: "30px 30px 0px 0px ",
                    width: { xs: "100%", sm: "100%" },
                    height: { xs: "80%", sm: "80%" },
                  }}
                />
                <Box sx={{ p: "10px" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", sm: "16px" },
                      fontWeight: "700",
                      fontFamily: "Inter",
                      lineHeight: { xs: "20px", sm: "26px" },
                    }}
                  >
                    Lucy Lopez
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", sm: "14px" },
                      fontWeight: "500",
                      fontFamily: "Inter",
                      lineHeight: { xs: "20px", sm: "20px" },
                    }}
                  >
                  GetBeautyPro Studio
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", sm: "14px" },
                      fontWeight: "500",
                      fontFamily: "Inter",
                      lineHeight: { xs: "20px", sm: "20px" },
                    }}
                  >
                 Newark, NJ US
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", sm: "12px" },
                      fontWeight: "regular",
                      fontFamily: "Inter",
                      color: "#9CA3AF",
                      lineHeight: { xs: "18px", sm: "24px" },
                      pt: { xs: "15px", sm: "4px" },
                      pb: { xs: "6px", sm: "4px" },
                    }}
                  >
                    {" "}
                    20/03/2025
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ pb: "5px" }}>
                    {[...Array(5)].map((_, index) => (
                      <StarIcon
                        key={index}
                        sx={{ color: "#ffc107", fontSize: "20px" }}
                      />
                    ))}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", sm: "14px" },
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      color: "#141414",
                      lineHeight: { xs: "24px", sm: "20px" },
                      pt: { xs: "15px", sm: "5px" },
                      pb: { xs: "6px", sm: "15px" },
                    }}
                  >
                    PointGenie makes it so easy for my clients to leave reviews
                    right after their appointment. Since adding the tag, my
                    Google reviews have doubled, and new clients say they found
                    me because of all the great feedback. It’s been a huge win
                    for my business!
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          display: "grid",
          gap: "40px",
          justifyItems: "center",
          pt: { sm: "10px" },
          pb: "20px",
          maxWidth: { xs: "90%", sm: "70%" },
          margin: "0 auto",
          gridTemplateColumns: {
            xs: "1fr 1fr",
            sm: "repeat(5, 1fr)",
          },
        }}
      >
        <Box textAlign="center">
          <Box
            component="img"
            src={days}
            width={"auto"}
            py={{ xs: "10px", sm: "0px" }}
          />
          <Typography
            sx={{
              fontSize: { xs: "16px", sm: "16px", xl: "20px" },
              pt: { xl: "5px" },
              fontWeight: "800",
              fontFamily: "Montserrat",
            }}
          >
            30-Day Risk-Free Trial
          </Typography>
        </Box>
        <Box textAlign="center">
          <Box
            component="img"
            src={truck}
            width={"auto"}
            py={{ xs: "10px", sm: "0px" }}
          />
          <Typography
            sx={{
              fontSize: { xs: "16px", sm: "16px", xl: "20px" },
              pt: { xl: "5px" },
              fontWeight: "800",
              fontFamily: "Montserrat",
            }}
          >
            Free Shipping
          </Typography>
        </Box>
        <Box textAlign="center">
          <Box
            component="img"
            src={easyreturn}
            width={"auto"}
            py={{ xs: "10px", sm: "0px" }}
          />
          <Typography
            sx={{
              fontSize: { xs: "16px", sm: "16px", xl: "20px" },
              pt: { xl: "5px" },
              fontWeight: "800",
              fontFamily: "Montserrat",
            }}
          >
            Easy & Free Return
          </Typography>
        </Box>
        <Box textAlign="center">
          <Box
            component="img"
            src={livesupport}
            width={"auto"}
            height={{ xl: "auto" }}
            py={{ xs: "10px", sm: "0px" }}
          />
          <Typography
            sx={{
              fontSize: { xs: "16px", sm: "16px", xl: "20px" },
              pt: { xl: "5px" },
              fontWeight: "800",
              fontFamily: "Montserrat",
            }}
          >
            24/7/365 Live Support
          </Typography>
        </Box>
        <Box
          textAlign="center"
          sx={{ gridColumn: { xs: "1 / span 2", sm: "auto" } }}
        >
          <Box
            component="img"
            src={paymentimage}
            width={"auto"}
            height={{ xl: "auto" }}
            py={{ xs: "10px", sm: "0px" }}
          />
          <Typography
            sx={{
              fontSize: { xs: "16px", sm: "16px", xl: "20px" },
              pt: { xl: "5px" },
              fontWeight: "800",
              fontFamily: "Montserrat",
            }}
          >
            100% Secure Payment
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default Reviews;
