import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  Grid,
  CircularProgress,
  Select,
  FormControl,
} from "@mui/material";
import { Star } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "./axios";
import googlelogo from "../images/googlelogo.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from "@mui/icons-material/Language";

const Plates = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [businessData, setBusinessData] = useState([]);
  const [platesData, setPlatesData] = useState([]);
  const [businessId, setBusinessId] = useState(null);
  const { code } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const response = await axiosInstance.post(
          "payments.php?method=fetch_business",
          { plate_code: code }
        );
        setBusinessData(response.data.place_details);
        setPlatesData(response?.data?.place_details?.plates);
        setBusinessId(response.data.id);
      } catch (err) {
        console.error("Failed to load data.");
      }
      setLoading(false); // Stop loading
    };

    fetchData();
  }, [code]);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#0047AB",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: { xs: "0px 1px", sm: 2 },
      }}
    >
      {/* HEADER */}
      <Box
        className="heading"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          pt: 5,
          pb: 3,
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontSize: "28px", fontFamily: "Goldplay-bold", color: "white" }}
        >
          PointGenie
        </Typography>
      </Box>

      {/* MAIN WHITE CONTAINER */}
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: 3,
          padding: 3,
          maxWidth: 900,
          width: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          mt: 3,
          boxShadow: 3,
        }}
      >
        {loading ? (
          <CircularProgress color="primary" /> // Show loader while data is loading
        ) : (
          <>
            {/* CENTERED CODE */}
            <Typography
              variant="h6"
              color="limegreen"
              fontWeight="bold"
              sx={{ textAlign: "center" }}
            >
              {code}
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              textAlign="center"
            >
              Please help us validate your business below
            </Typography>

            {/* BUSINESS & PLATES CONTAINER */}
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                width: "100%",
                gap: 3,
              }}
            >
              {/* LEFT - BUSINESS DETAILS */}
              <Box sx={{ flex: 1 }}>
                <Card sx={{ borderRadius: 2 }}>
                  <CardContent>
                    <Box mt={2}>
                      <Box>
                        <Grid container>
                          <Grid xs={9}>
                            <Typography variant="subtitle1" fontWeight="bold">
                              {businessData.name}
                            </Typography>
                          </Grid>

                          <Grid xs={3}>
                            <Box sx={{ display: "flex" }}>
                              <Star
                                sx={{ color: "gold", fontSize: 18, ml: 1 }}
                              />
                              <Typography variant="body2" fontWeight="bold">
                                {businessData.rating}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ fontSize: "12px" }}
                            >
                              ({businessData.review_count} reviews)
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          display="flex"
                          alignItems="center"
                          gap={0.5}
                          mt={"6px"}
                          // mb="10px"
                        >
                          <LocationOnIcon fontSize="small" />
                          {businessData.address}
                        </Typography>

                        <Typography
                          variant="body2"
                          color="#0041A8"
                          display="flex"
                          alignItems="center"
                          gap={0.5}
                          mb="10px"
                          mt={{xs:"2px",sm:"5px"}}
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(`tel:${businessData.telephone}`)
                          }
                        >
                          <PhoneIcon fontSize="small" />
                          {businessData.telephone}
                        </Typography>
                        {businessData?.website && (
                        <Typography
                          variant="body2"
                          color="#0041A8"
                          alignItems="center"
                          gap={0.5}
                          display="flex"
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(businessData.website, "_blank")
                          }
                        >
                          <LanguageIcon
                            fontSize="small"
                            sx={{ color: "#0041A8" }}
                          />
                          Website
                        </Typography>
                          )}
                      </Box>
                    </Box>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      display="block"
                      textAlign="right"
                    >
                      Powered by{" "}
                      <img src={googlelogo} alt="Google" width={40} />
                    </Typography>
                  </CardContent>
                </Card>
                <Box mt={2} sx={{ width: "100%" }}>
                  <FormControl fullWidth variant="outlined">
                    {/* <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                      Select Category
                    </Typography> */}
                    <Select
                      native
                      sx={{
                        borderRadius: 2,
                        backgroundColor: "#f8f8f8",
                        fontWeight: "bold",
                      }}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Choose a category
                      </option>
                      <option value="bars">Bars</option>
                      <option value="lounge">Lounge</option>
                      <option value="beauty salon's">Beauty salon's</option>
                      <option value="barbershop">Barbershop</option>
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              {/* RIGHT - PLATES TABLE */}
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6" fontWeight="bold" mb={1}>
                  Your Plates
                </Typography>

                <TableContainer component={Paper} elevation={0} sx={{ mt: 1 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Code</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Status
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Scans</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {platesData.map((plate, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{ color: "green", fontWeight: "bold" }}
                          >
                            {plate.plate_code}
                          </TableCell>
                          <TableCell sx={{ color: "green" }}>
                            {plate.status || "Active"}
                          </TableCell>
                          <TableCell>{plate.scans || 0}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </>
        )}
      </Box>

      {/* VALIDATE BUTTON */}
      {!loading && (
        <Button
          variant="contained"
          color="primary"
          sx={{
            my: 3,
            borderRadius: 5,
            px: 4,
            
          }}
          onClick={() =>
            navigate(`/socialmediareview/${code}`, { state: { businessId } })
          }
        >
          Validate
        </Button>
      )}
    </Box>
  );
};

export default Plates;
