import React, { useEffect, useState, useContext } from "react";
import {
  TextField,
  Button,
  Box,
  Modal,
  Typography,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { ChevronLeft } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import axiosInstance from "./axios";
import { BusinessContext } from "./BusinessContext";

const CategorySearch = ({ onDone }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [categories, setCategories] = useState([]);
  const { selectedBusinessId } = useContext(BusinessContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

 
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.post(
          "payments.php?method=menu_categories",
          {
            business_id: selectedBusinessId,
          }
        );
        if (response.data?.categories) {
          setCategories(response.data?.categories);
          setSelectedCategories(response.data?.categories); // Set existing categories to selected
        } else {
          setCategories([]);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        setCategories([]);
      }
    };

    useEffect(()=>
    {
      fetchCategories()
    },[selectedBusinessId])
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() === "") {
      setSuggestions([]);
      return;
    }

    // Call API to search categories
    const fetchSearchResults = async () => {
      try {
        const response = await axiosInstance.post(
          "places.php?method=menu_categories",
          {
            keyword: value,
          }
        );

        if (response.data?.categories) {
          const filtered = response.data.categories.filter(
            (cat) =>
              !selectedCategories.some((selected) => selected.id === cat.id)
          );
          setSuggestions(filtered);
        } else {
          setSuggestions([]);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        setSuggestions([]);
      }
    };

    fetchSearchResults();
  };

  const handleSelectCategory = async (category) => {
    // Prevent duplicate categories from being added
    if (selectedCategories.some((selected) => selected.id === category.id)) {
      setSearchTerm("");
      setSuggestions([]);
      return;
    }
  
    try {
      const response = await axiosInstance.post(
        "places.php?method=add_business_category",
        {
          business_id: selectedBusinessId,
          business_menu_category_id: category.id,
        }
      );
  
      if (response.data?.status === "success") {
        setSearchTerm("");
        setSuggestions([]); // Clear search suggestions
        fetchCategories();
      } else {
        console.error("Failed to select category:", response.data?.message);
      }
    } catch (error) {
      console.error("Error selecting category:", error);
    }
  };  

  const handleAddCategory = async () => {
    if (!searchTerm.trim()) return;

    try {
      const response = await axiosInstance.post(
        "places.php?method=add_menu_category",
        {
          name: searchTerm.trim(),
          business_id: selectedBusinessId,
        }
      );

      if (response.data?.status === "success") {
        const newCategory = {
          id: response.data.category_id || Date.now(),
          name: searchTerm.trim(),
        };
        setSelectedCategories((prev) => [...prev, newCategory]);
        setSearchTerm("");
        setSuggestions([]);
      } else {
        console.error("Failed to add category:", response.data?.message);
      }
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const handleRemoveCategory = (category) => {
    setCategoryToDelete(category);
    setOpenModal(true);
  };

  const confirmDelete = async () => {
    if (!categoryToDelete?.category_id) {
        console.error("Category ID is missing:", categoryToDelete);
        return;
    }

    try {
        console.log("Deleting category:", selectedBusinessId, categoryToDelete.category_id);
        const response = await axiosInstance.post(
            "places.php?method=delete_business_category",
            {
                business_id: selectedBusinessId,
                business_menu_category_id: categoryToDelete.category_id, // Use category_id instead of id
            }
        );

        if (response.data?.status === "success") {
            setSelectedCategories(
                selectedCategories.filter((cat) => cat.category_id !== categoryToDelete.category_id)
            );
            setCategoryToDelete(null);
            setOpenModal(false);
            fetchCategories();
        } else {
            console.error("Failed to delete category:", response.data?.message);
        }
    } catch (error) {
        console.error("Error deleting category:", error);
    }
};

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 3,
          px: { xs: "10px", sm: "5px" },
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          Select Categories
        </Typography>
        <IconButton onClick={() => onDone()} sx={{ color: "primary.main" }}>
          <ChevronLeft sx={{ color: "primary.main" }} />
          <Typography sx={{ fontWeight: "bold", color: "primary.main" }}>
            Back
          </Typography>
        </IconButton>
      </Box>
      <Box sx={{ height: "100vh", p: 2 }}>
        <Box
          sx={{
            mt: { xs: 0, sm: 5 },
            p: { xs: 0, sm: 3 },
          }}
        >
          {/* Header with Back Button */}
          {/* Search Bar */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <TextField
              sx={{ width: { xs: "80%", sm: "50%" } }}
              label="Search Category"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddCategory}
              sx={{
                ml: 1,
                height: "54px",
                minWidth: "56px",
              }}
            >
              <AddIcon sx={{ fontSize: "24px" }} />
            </Button>
          </Box>

          {/* Suggestions */}
          {suggestions.length > 0 && (
            <Paper sx={{ mt: 1, maxHeight: 200, overflow: "auto",  width: { xs: "80%", sm: "50%" }, }}>
              <List>
                {suggestions.map((cat) => (
                  <ListItem
                    key={cat.id}
                    button
                    onClick={() => handleSelectCategory(cat)}
                  >
                    <ListItemText primary={cat.name} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}

          {/* Selected Categories */}
          <Box sx={{ mt: 2, width: "100%" }}>
            <Grid container spacing={2}>
              {selectedCategories.map((cat) => (
                <Grid item xs={12} sm={4} key={cat.id}>
                  <Paper
                    elevation={1}
                    sx={{
                      p: 1,
                      bgcolor: "background.paper",
                      "&:hover": {
                        bgcolor: "action.hover",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                      {cat.name.charAt(0).toUpperCase() + cat.name.slice(1)}
                      </Typography>
                      <IconButton
                        size="small"
                        aria-label="delete"
                        onClick={() => handleRemoveCategory(cat)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Centered Done Button */}
          {/* <Box sx={{ display: "flex", justifyContent: { xs: "start", sm: "center" }, mt: 2 }}>
            <Typography>
              menu
              <ChevronLeft />
              categories
            </Typography>
          </Box> */}

          {/* Delete Confirmation Modal */}
          <Modal open={openModal} onClose={() => setOpenModal(false)}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "white",
                p: 3,
                borderRadius: 2,
              }}
            >
              <Typography>
                Are you sure you want to delete {categoryToDelete?.name}?
              </Typography>
              <Box mt={2} display="flex" gap={2}>
                <Button variant="contained" color="error" onClick={confirmDelete}>
                  Delete
                </Button>
                <Button variant="outlined" onClick={() => setOpenModal(false)}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

export default CategorySearch;
