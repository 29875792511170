import React from "react";
import {
  Modal,
  Box,
  Typography,
  Container,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function TermsModal({ open, handleClose }) {
  return (
    <Modal open={open} onClose={handleClose} sx={{}}>
      <Box
        sx={{
          py: "60px",
          position: "absolute",
          top: "49%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "100vh",
          bgcolor: "#fff",
          p: 3, // transform: open ? "translateX(0)" : "translateX(-100%)",
          // transition: "transform 0.4s ease-in-out",
          zIndex: 5,
          overflowY: "scroll",
        }}
      >
        <Container>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "600",
              color: "#0a1992",
              fontFamily: "Montserrat",
              pb: "15px",
              fontSize: { xs: "28px", sm: "38px" },
            }}
          >
            Terms & Conditions
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#415076",
              fontFamily: "Montserrat",
            }}
          >
            Last Updated: March 29, 2025
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "16px" },
              fontWeight: { xs: "500", sm: "400" },
              color: "  #415076",
              pt: "20px",
              fontFamily: "Montserrat",
            }}
          >
            Welcome to PointGenie! These Terms and Conditions (“Terms”) govern
            your use of our NFC tags and associated services (collectively, the
            “Service”). By purchasing or using a PointGenie NFC tag, you agree
            to these Terms.
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "  #415076",
              fontFamily: "Montserrat",
              pt: "18px",
            }}
          >
            {" "}
            1. NFC Tag Usage
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "1px",
            }}
          >
            PointGenie NFC tags are designed to help businesses connect
            customers with a landing page that can include review links, menus,
            social media, specials, and tracking tools.
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "10px",
            }}
          >
            You are solely responsible for setting up and managing your NFC tag through the PointGenie dashboard.

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "10px",
            }}
          >
           Tags must not be tampered with, copied, or used in any illegal or harmful manner.

          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "  #415076",
              fontFamily: "Montserrat",
              pt: "18px",
            }}
          >
            {" "}
            2.  Licensing
 </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "1px",
            }}
          >
           When you purchase a PointGenie NFC tag, you receive a limited, non-exclusive license to use our technology and platform as described.
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "1px",
            }}
          >
           The software and services remain the property of PointGenie and are licensed, not sold.

          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "  #415076",
              fontFamily: "Montserrat",
              pt: "18px",
            }}
          >
            {" "}
            3.  Content Responsibility 
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "1px",
            }}
          >
           You are responsible for the content you upload or link to via your tag (e.g., menus, social links, review URLs).
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              // pt: "14px",
            }}
          >
           You agree not to host or direct users to offensive, defamatory, or illegal content.
          </Typography>
         
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "  #415076",
              fontFamily: "Montserrat",
              pt: "18px",
            }}
          >
            {" "}
            4. Data & Analytics
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "1px",
            }}
          >
            You may receive access to insights such as tap counts, location, and general behavior analytics.
          </Typography>
          <Typography
             sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "1px",
            }}
          >
           Use of this data must comply with all applicable privacy laws.
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "  #415076",
              fontFamily: "Montserrat",
              pt: "18px",
            }}
          >
            {" "}
            5. Limitation of Liability

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "1px",
            }}
          >
           PointGenie is not liable for any indirect, incidental, or consequential damages resulting from the use or inability to use the NFC tags or platform.

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              // pt: "12px",
            }}
          >
            PointGenie does not guarantee any increase in traffic, sales, or reviews.

          </Typography>
         
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "  #415076",
              fontFamily: "Montserrat",
              pt: "18px",
            }}
          >
            {" "}
            6. Returns and Replacements
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "1px",
            }}
          >
           NFC tags are non-refundable after activation. If your tag is defective upon arrival, we’ll replace it within 14 days of delivery.

          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "  #415076",
              fontFamily: "Montserrat",
              pt: "18px",
            }}
          >
            {" "}
            7. Modifications
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "1px",
            }}
          >
            We may update these Terms at any time. Continued use of the Service after changes means you accept the new terms.

          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              color: "  #415076",
              fontFamily: "Montserrat",
              lineHeight: 1.68,
              pt: "1px",
            }}
          >
           These Terms are governed by the laws of the State of Florida, USA, without regard to conflict of law principles.
         </Typography>
         
        </Container>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: { xs: 10, sm: 20 },
            right: { xs: 4, sm: 40 },
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>
  );
}

export default TermsModal;
