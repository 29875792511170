// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/fonts/Goldplay-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/fonts/Goldplay-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/fonts/GoldplayAlt-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
 font-family: Goldplay;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: Goldplay;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: Goldplay-bold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: Goldplay-bolder;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

.font-regular{
font-family: Goldplay;
}
.font-bold{
  font-family: Goldplay-bold;
}
.font-bolder{
  font-family: Goldplay-bolder;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;CACV,qBAAqB;EACpB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,qBAAqB;EACrB,4CAAgD;AAClD;;AAEA;EACE,0BAA0B;EAC1B,4CAAiD;AACnD;;AAEA;EACE,4BAA4B;EAC5B,4CAAmD;AACrD;;AAEA;AACA,qBAAqB;AACrB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,4BAA4B;AAC9B","sourcesContent":["body {\n  margin: 0;\n font-family: Goldplay;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n@font-face {\n  font-family: Goldplay;\n  src: url('../public/fonts/Goldplay-Regular.ttf');\n}\n\n@font-face {\n  font-family: Goldplay-bold;\n  src: url('../public/fonts/Goldplay-SemiBold.ttf');\n}\n\n@font-face {\n  font-family: Goldplay-bolder;\n  src: url('../public/fonts/GoldplayAlt-Regular.ttf');\n}\n\n.font-regular{\nfont-family: Goldplay;\n}\n.font-bold{\n  font-family: Goldplay-bold;\n}\n.font-bolder{\n  font-family: Goldplay-bolder;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
