import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/FirebaseConfig";


export const isAuthenticated = () => {
    const idToken = localStorage.getItem("idToken");
    return !!idToken;
  };

  
  export const setupAuthListener = (callback) => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        user.getIdToken().then(idToken => {
          localStorage.setItem("idToken", idToken);
          localStorage.setItem("firebaseUser", JSON.stringify(user));
          if (callback) callback(true);
        });
      } else {
        // User is signed out
        localStorage.removeItem("idToken");
        localStorage.removeItem("firebaseUser");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("username");
        localStorage.removeItem("PhoneNumber");
        localStorage.removeItem("plateCode");
        localStorage.removeItem("firstName");
        localStorage.removeItem("businessId");
        localStorage.removeItem("googleToken");
        localStorage.removeItem("lastName");
        localStorage.removeItem("BusinessName");
        if (callback) callback(false);
      }
    });
  };