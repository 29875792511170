import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
  Stack,
  Tabs,
  Tab,
  MenuItem,
  Select,
  Modal,
  TextField,
  CircularProgress,
} from "@mui/material";
import totalreviews from "../../images/totalreviews.png";
import totalratings from "../../images/totalratings.png";
import totalscans from "../../images/totalscans.png";
import activeoffers from "../../images/activeoffers.png";
import dashboarddelete from "../../images/dashboarddelete.png";
import dashboardedit from "../../images/dashboardedit.png";
import sliderlefticon from "../../images/sliderlefticon.png";
import sliderrighticon from "../../images/sliderrighticon.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "../axios";
import { BusinessContext } from "../BusinessContext";
import { Rating } from "@mui/material";
import { Avatar } from "@mui/material";
import {styled } from "@mui/system";
import Slider from "react-slick";
import imageCompression from "browser-image-compression";
import AWS from "aws-sdk";

const DashboardContent = () => {
  const [activeButton, setActiveButton] = useState("");
  const [value, setValue] = useState(0);
  const [timeRange, setTimeRange] = useState("6 months");
  const [addOffer, setAddOffer] = useState(false);
  const [editOffer, setEditOffer] = useState(false);
  const [specialToEdit, setSpecialToEdit] = useState(null);
  const { selectedBusinessId } = useContext(BusinessContext);
  const [businessData, setBusinessData] = useState([]);
  const [businessDataspecials, setBusinessDataspecials] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [cardsPerSlide, setCardsPerSlide] =
    useState();
    // window.innerWidth < 1200 ? 1 : window.innerWidth < 1440 ? 2 : 3
  const sliderRef = useRef(null);
  const [specialFormdata, setSpecialFormData] = useState({
    menu_name: "",
    menu_description: "",
    price: "",
    expiration_date: "",
    status: "1",
    menu_image: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [specialToDelete, setSpecialToDelete] = useState(null);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    endpoint: process.env.REACT_APP_AWS_BASE_URL,
    s3ForcePathStyle: true,
    signatureVersion: "v4",
  });
  const s3 = new AWS.S3();

  const handleOpenAddOffer = () => {
    setAddOffer(true);
  };

  const handleCloseAddOffer = () => {
    setAddOffer(false);
  };

  const handleOpenEditOffer = (special) => {
    setSpecialToEdit({
      ...special,
      menu_image: special.image,
      // status: special.status === 1 ? "1" : "0"
      status: special.status === "1" ? "Active" : "Inactive",
    });
    setEditOffer(true);
  };

  const handleCloseEditOffer = () => {
    setEditOffer(false);
    setSpecialToEdit(null);
  };

  const Customslider = styled(Slider)({
    slider: {
      "& .slick-slide": {
        // display: 'inline-block',
        marginRight: "16px",
      },
    },
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setCardsPerSlide(1);
      } else if (window.innerWidth < 1440) {
        setCardsPerSlide(2);
      } else {
        setCardsPerSlide(3);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchBusinessesspecials = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.post(
          "places.php?method=get_business_specials",
          {
            business_id: selectedBusinessId,
          }
        );
        if (response.data.place_details);
        setBusinessDataspecials(response.data);
        console.log("businessData", response.data);
      } catch (err) {
        console.log("error", err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBusinessesspecials();
  }, [selectedBusinessId]);


  useEffect(() => {
    const fetchBusinesses = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.post(
          "places.php?method=get_place_details",
          {
            id: selectedBusinessId,
          }
        );
        if (response.data.place_details);
        setBusinessData(response.data.place_details);
        console.log("businessData", response.data.place_details);
      } catch (err) {
        console.log("error", err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBusinesses();
  }, [selectedBusinessId]);

  const data = [
    { name: "Jan", Scans: 5000, Reviews: 3000 },
    { name: "Feb", Scans: 6000, Reviews: 4000 },
    { name: "Mar", Scans: 9000, Reviews: 6000 },
    { name: "Apr", Scans: 12728, Reviews: 9000 },
    { name: "May", Scans: 17000, Reviews: 13000 },
    { name: "Jun", Scans: 20000, Reviews: 18000 },
  ];

  const settings = {
    infinite: false, // Disable infinite scroll
    slidesToShow: 3, // Always show 3 slides
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2.7,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.4,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 1130,
        settings: {
          slidesToShow: 1.6,
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    if (sliderRef.current) {
      const currentSlide = sliderRef.current.innerSlider.state.currentSlide;
      if (currentSlide > 0) {
        sliderRef.current.slickPrev();
      }
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      const currentSlide = sliderRef.current.innerSlider.state.currentSlide;
      const totalSlides = businessData?.specials?.length || 0;
      const slidesToShow =
        window.innerWidth < 1200 ? 1 : window.innerWidth < 1440 ? 2 : 3;
  if (currentSlide < totalSlides - 1) {
    sliderRef.current.slickNext();
  }
    }
  };

  

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            background: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            boxShadow: 2,
          }}
        >
          <Typography fontSize={12} color="#444">
            🔵 Scans: <strong>{payload[0].value}</strong>
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const totalScans = businessData?.plates?.reduce(
    (sum, plate) => sum + plate.scans,
    0
  );
  const visibleReviews = showAll
    ? businessData.reviews
    : businessData?.reviews?.slice(0, 3);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const validateForm = (isEdit = false) => {
    const errors = {};
    if (!specialFormdata.menu_name) errors.menu_name = "Menu name is required";
    if (!specialFormdata.menu_description) errors.menu_description = "Description is required";
    if (!specialFormdata.price) errors.price = "Price is required";
    if (!specialFormdata.coupon_code) errors.coupon_code = "Coupon code is required";
    if (!specialFormdata.expiration_date) errors.expiration_date = "Expiration date is required";
    if (!isEdit && !uploadedImageUrl) errors.menu_image = "Image is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      // Compress image
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);

      // Upload to S3
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: `${Date.now()}-${file.name}`,
        Body: compressedFile,
        ContentType: file.type,
        ACL: "public-read",
      };

      const uploadResult = await s3.upload(params).promise();
      setUploadedImageUrl(uploadResult.Location);
      setSpecialFormData((prev) => ({
        ...prev,
        menu_image: uploadResult.Location,
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleImageDelete = () => {
    setUploadedImageUrl(null);
    setSpecialFormData(prev => ({
      ...prev,
      menu_image: null
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSpecialFormData((prev) => ({ ...prev, [name]: value }));
    if (formErrors[name]) {
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };
  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const payload = {
        ...specialFormdata,
        business_id: selectedBusinessId,
        expiration_date: specialFormdata.expiration_date,
        status: specialFormdata.status === "Active" ? "1" : "0",
      };

      const response = await axiosInstance.post(
        "places.php?method=add_special",
        payload
      );

      if (response.data.status === "success") {
        // First close the modal
        handleCloseAddOffer();
        
        // Reset form data
        setSpecialFormData({
          menu_name: "",
          menu_description: "",
          price: "",
          expiration_date: "",
          status: "1",
          menu_image: null,
        });
        setUploadedImageUrl(null);
        
        // Refresh the business data to get updated specials
        const updatedResponse = await axiosInstance.post(
          "places.php?method=get_business_specials",
          {
            business_id: selectedBusinessId,
          }
        );
        
        if (updatedResponse.data) {
          setBusinessDataspecials(updatedResponse.data);
        }
      }
    } catch (error) {
      console.error("Error adding offer:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteSpecial = async (specialId) => {
    try {
      const response = await axiosInstance.post(
        "places.php?method=delete_menu_item",
        {
          id: specialId,
        }
      );

      if (response.data.status === "success") {
        // First close the modal
        setDeleteDialogOpen(false);
        setSpecialToDelete(null);
        
        // Refresh the business data to get updated specials
        const updatedResponse = await axiosInstance.post(
          "places.php?method=get_place_details",
          {
            id: selectedBusinessId,
          }
        );
        
        if (updatedResponse.data) {
          setBusinessDataspecials(updatedResponse.data);
        }
      }
    } catch (error) {
      console.error("Error deleting special:", error);
    }
  };

  const handleEditSubmit = async () => {
    // For edit, we only validate if the fields have been changed
    const hasChanges = Object.keys(specialToEdit).some(key => 
      specialToEdit[key] !== businessDataspecials.specials.find(s => s.id === specialToEdit.id)[key]
    );

    if (!hasChanges) {
      handleCloseEditOffer();
      return;
    }

    // Only validate required fields that have been changed
    const errors = {};
    if (!specialToEdit.menu_name) errors.menu_name = "Menu name is required";
    if (!specialToEdit.menu_description) errors.menu_description = "Description is required";
    if (!specialToEdit.price) errors.price = "Price is required";
    if (!specialToEdit.coupon_code) errors.coupon_code = "Coupon code is required";
    if (!specialToEdit.expiration_date) errors.expiration_date = "Expiration date is required";
    
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setIsSubmitting(true);
    try {
      // Create payload without duplicate image fields
      const { image, ...payload } = {
        ...specialToEdit,
        business_id: selectedBusinessId,
        expiration_date: specialToEdit.expiration_date,
        // status: specialToEdit.status // Already in the correct format ("1" or "0")
        status: specialToEdit.status === "Active" ? "1" : "0",
      };

      const response = await axiosInstance.post(
        "places.php?method=edit_special",
        payload
      );

      if (response.data.status === "success") {
        handleCloseEditOffer();
        setSpecialToEdit(null);
        
        // Refresh the business data to get updated specials
        const updatedResponse = await axiosInstance.post(
          "places.php?method=get_business_specials",
          {
            business_id: selectedBusinessId,
          }
        );
        
        if (updatedResponse.data) {
          setBusinessDataspecials(updatedResponse.data);
        }
      }
    } catch (error) {
      console.error("Error updating offer:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isloading) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height
          }}
        >
          <CircularProgress />
        </Box>
      </>
    );
  }

  if (!selectedBusinessId) {
    return (
      <>
        <Typography
          sx={{
            textAlign: "center",
            pt: "50px",
            fontFamily: "Nunito",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          No businesses available at the moment.
        </Typography>
      </>
    );
  }

  return (
    <Box sx={{ p: "10px  0px 10px 10px", backgroundColor: "#e5e5e5" }}>
      <Grid container gap={{ xs: "12px", sm: "12px", md: "6px", lg: "12px" }}>
        <Grid item xs={12} sm={12} md={7} lg={6.8}>
          {/* business name */}

          <Box
            sx={{
              borderRadius: "8px",
              p: "15px",
              display: "flex",
              gap: "16px",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            {/* <Box
              component="img"
              src={dashboardname}
              sx={{ objectFit: "contain" }}
            /> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",

                // justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  lineHeight: "100%",
                  color: "#00000",
                }}
              >
                {businessData.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#898989",
                  wordBreak: "break-all",
                }}
              >
                {businessData.address}
              </Typography>
            </Box>
          </Box>

          {/* likesand reviews count */}

          <Grid
            container
            pt={"10px"}
            gap={{ xs: "9px", lg: "10px", xl: "9px" }}
          >
            <Grid
              item
              xs={12}
              sm={3.6}
              md={3.6}
              lg={3.7}
              xl={3.9}
              sx={{
                borderRadius: "8px",
                p: "10px",
                backgroundColor: "white",
                display: "flex",
                gap: { xs: "16px", sm: "12px" },
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={totalreviews}
                width={"31px"}
                height={"31px"}
              />
              <Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Roboto",
                    fontWeight: "400px",
                    lineHeight: "100%",
                    color: "#000000",
                  }}
                >
                  Total Reviews
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    lineHeight: "100%",
                    color: "#000000",
                    pt: "2px",
                  }}
                >
                  {businessData?.review_count}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3.9}
              sx={{
                borderRadius: "8px",
                p: "10px",
                backgroundColor: "white",
                display: "flex",
                gap: "12px",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={totalratings}
                width={"31px"}
                height={"31px"}
              />
              <Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Roboto",
                    fontWeight: "400px",
                    lineHeight: "100%",
                    color: "#000000",
                  }}
                >
                  Ratings
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    lineHeight: "100%",
                    color: "#000000",
                    pt: "2px",
                  }}
                >
                  {businessData.rating}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3.9}
              sx={{
                borderRadius: "8px",
                p: "10px",
                backgroundColor: "white",
                display: "flex",
                gap: "12px",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={totalscans}
                width={"31px"}
                height={"31px"}
              />
              <Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Roboto",
                    fontWeight: "400px",
                    lineHeight: "100%",
                    color: "#000000",
                  }}
                >
                  Total Scans
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    lineHeight: "100%",
                    color: "#000000",
                    pt: "2px",
                  }}
                >
                  {totalScans}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* active orders expired offers */}

          <Box
            sx={{
              borderRadius: "8px",
              px: "18px",
              pb: "15px",
              pt: "10px",
              backgroundColor: "white",
              mt: "10px",
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", sm: "flex" },
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #BFBFBF",
                // pb: "8px",
                heminHeight: "32px",
              }}
            >
              <Box sx={{ order: { xs: "2", sm: "1" } }}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  TabIndicatorProps={{ style: { display: "none" } }}
                  sx={
                    {
                      // minHeight: "32px", // Reduce Tabs height
                    }
                  }
                >
                  <Tab
                    label={
                      <Box display="flex" alignItems="center">
                        <Box component="img" src={activeoffers} pr={"3px"} />
                        Active Offers
                      </Box>
                    }
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textTransform: "capitalize",
                      color: value === 0 ? "#0041A8" : "#0D0F1C",
                      borderBottom: value === 0 ? "3px solid #0041A8" : "none",
                      p: 0,
                      minWidth: "auto",
                      marginRight: "25px",
                    }}
                  />

                  {/* <Tab
                    label="Expired Offers"
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textTransform: "capitalize",
                      color: value === 1 ? "#0041A8" : "#0D0F1C",
                      borderBottom: value === 1 ? "3px solid #0041A8" : "none",
                      p: 0,
                      minWidth: "auto",
                    }}
                  /> */}
                </Tabs>
              </Box>
              <Box sx={{ order: { xs: "1", sm: "2" } }}>
                <Button
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    lineHeight: "21px",
                    color: "#0041A8",
                    textTransform: "capitalize",
                    border: "1px solid #0041A8",
                    borderRadius: "27px",
                    px: "12px",
                    py: "4px",
                  }}
                  onClick={handleOpenAddOffer}
                >
                  + create new offer
                </Button>
              </Box>
            </Box>
            <Box
              width="100%"
              textAlign="center"
              pt={"10px"}
              pb={"8px"}
              sx={{
                "& .slick-slide": {
                  maxwidth: "225px !important",
                },
              }}
            >
              {businessDataspecials?.specials?.length >0 ? (  <Customslider ref={sliderRef} {...settings}>
                {businessDataspecials?.specials?.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      maxWidth: "224px",
                      height: "250px",
                      borderRadius: "8px",
                      border: "1px solid #DFDFDF",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  >
                    <img
                      src={item.image}
                      alt="Coupon"
                      style={{
                        width: "222px",
                        height: "150px",
                        objectFit:'cover'
                      }}
                    />
                    <Box sx={{ p: "10px" }}>
                      {/* <Typography
                        variant="body2"
                        sx={{
                          fontSize: "13px",
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          lineHeight: "100%",
                          color: "#000000",
                          textAlign: "start",
                          pt: "6px",
                        }}
                      >
                        Total Reviews
                      </Typography> */}
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "13px",
                          fontFamily: "Roboto",
                          fontWeight: "700",
                          lineHeight: "100%",
                          color: "#000000",
                          textAlign: "start",
                          pt: "10px",
                        }}
                      >
                        {item.menu_name}
                      </Typography>
                      <Box >
                        <Stack
                          direction="row"
                          mt={2}
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#0343AA",
                              fontSize: "13px",
                              fontFamily: "Roboto",
                              fontWeight: "600",
                              lineHeight: "100%",
                              textTransform: "capitalize",
                              borderRadius: "25px",
                              px: "10px",
                            }}
                          >
                            {item.coupon_code}
                          </Button>
                          <Box sx={{ display: "flex", gap: "6px" }}>
                            <Box
                              sx={{
                                border: "1px solid #DEDEDE",
                                width: "30px",
                                height: "30px",
                                borderRadius: "25px",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={() => handleOpenEditOffer(item)}
                            >
                              <Box component="img" src={dashboardedit} />
                            </Box>
                            <Box
                              sx={{
                                border: "1px solid #DEDEDE",
                                width: "30px",
                                height: "30px",
                                borderRadius: "25px",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                setSpecialToDelete(item.id);
                                setDeleteDialogOpen(true);
                              }}
                            >
                              <Box
                                component="img"
                                src={dashboarddelete}
                                sx={{}}
                              />
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Customslider>):(<>
              <Typography>
                No Offers available
              </Typography>
              </>
            )}

              {/* Navigation Buttons Below */}
            { businessData?.specials?.length >0 && (
                <Stack
                direction="row"
                justifyContent="end"
                alignItems="center"
                mt={2}
                gap={2}
              >
                <IconButton
                  onClick={handlePrevClick}
                  sx={{
                    border: "1px solid #DEE3F2",
                    borderRadius: "25px",
                    width: "40px",
                    height: "40px",
                    backgroundColor:
                      activeButton === "left" ? "#DEE3F2" : "transparent",
                  }}
                >
                  <img src={sliderlefticon} alt="icon" />
                </IconButton>

                <IconButton
                  onClick={handleNextClick}
                  sx={{
                    border: "1px solid #DEE3F2",
                    borderRadius: "25px",
                    width: "40px",
                    height: "40px",
                    backgroundColor:
                      activeButton === "right" ? "#DEE3F2" : "transparent",
                  }}
                >
                  <img src={sliderrighticon} alt="icon" />
                </IconButton>
              </Stack>
           ) }
            </Box>
          </Box>

          {/* graph overview*/}

          <Box
            sx={{
              border: "1px solid #E0E0E0",
              borderRadius: "8px",
              p: 2,
              background: "#fff",
              mt: "10px",
            }}
          >
            {/* Header */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2.5}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  lineHeight: "100%",
                  color: "#000000",
                }}
              >
                Overview
              </Typography>
              <Select
                value={timeRange}
                onChange={(e) => setTimeRange(e.target.value)}
                variant="standard"
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "Roboto",
                  lineHeight: "100%",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  "&:before, &:after": {
                    display: "none", // Removes default underline
                  },
                  svg: { fontSize: 18 },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px",
                    marginLeft: "4px",
                    verticalAlign: "middle",
                  },
                }}
              >
                <MenuItem value="6 months">Last 6 months</MenuItem>
                <MenuItem value="12 months">Last 12 months</MenuItem>
              </Select>
            </Box>

            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={data} barSize={24}>
                <CartesianGrid stroke="#E5E5E5" vertical={false} />{" "}
                {/* X-axis grid lines */}
                <XAxis
                  dataKey="name"
                  tickLine={false}
                  axisLine={{ stroke: "#E5E5E5" }}
                  tick={{
                    fontSize: "12px",
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    fill: "#8F9BB3",
                    lineHeight: "16px",
                  }}
                />
                <YAxis
                  tickFormatter={(value) => `${value / 1000}k`}
                  tickLine={false}
                  axisLine={false}
                  domain={[0, "dataMax"]}
                  allowDecimals={false}
                  width={30}
                  tick={{
                    fontSize: "12px",
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    fill: "#8F9BB3",
                    lineHeight: "16px",
                  }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  verticalAlign="bottom"
                  // iconSize={10}
                  iconType="circle"
                  wrapperStyle={{
                    fontSize: "11px",
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    color: "#000000",
                    lineHeight: "100%",
                  }}
                />
                <Bar dataKey="Scans" fill="#54AEF8" radius={[4, 4, 0, 0]} />
                <Bar dataKey="Reviews" fill="#8A73CE" radius={[4, 4, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>

        {/* second grid */}

        <Grid item xs={12} sm={12} md={4.8} lg={5}>
          <Box
            sx={{
              borderRadius: "8px",
              p: " 15px",

              backgroundColor: "white",
            }}
          >
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  lineHeight: "100%",
                  color: "#000000",
                }}
              >
                Latest Reviews
              </Typography>
              {visibleReviews?.map((review) => (
                <Box
                  sx={{
                    border: "1px solid #DCDCDC",
                    p: "6px 10px 15px 15px",
                    mt: "14px",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "16px",
                      color: "#8F9BB3",
                      textAlign: "end",
                    }}
                  >
                    {formatDate(review.created_at)}
                  </Typography>
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {review.name
                        .split(" ") // Split name into words
                        .map((word) => word.charAt(0)) // Get first letter of each word
                        .join("")}{" "}
                      {/* Join initials */}
                    </Avatar>

                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "11px",
                          color: "##000000",
                          pb: "3px",
                        }}
                      >
                        {review.name}
                      </Typography>
                      <Rating
                        value={review.rating}
                        readOnly
                        precision={1}
                        sx={{ fontSize: "18px" }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#222B45",
                      pt: "10px",
                    }}
                  >
                    {review.comment}
                  </Typography>
                </Box>
              ))}
            </>
            {businessData?.reviews?.length > 3 && (
              <Box sx={{ textAlign: "center", pt: "20px", pb: "10px" }}>
                <Button
                  onClick={() => setShowAll(!showAll)}
                  sx={{
                    textTransform: "capitalize",
                    color: "#0343AA",
                    border: "1px solid #D8D8D8",
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "Roboto",
                    lineHeight: "100%",
                    py: "10px",
                    px: "18px",
                    borderRadius: "8px",
                  }}
                >
                  {showAll ? "Show Less" : "View All Reviews"}
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Modal open={addOffer} onClose={handleCloseAddOffer}>
        <Box
          sx={{
            width: "400px",
            backgroundColor: "white",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            // padding: "16px",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              backgroundColor: "#0041A8",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                fontFamily: "Roboto",
                fontWeight: "600",
                lineHeight: "100%",
                pl: "12px",
              }}
            >
              Add Offer
            </Typography>
            <IconButton onClick={handleCloseAddOffer} sx={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ padding: "16px 25px 28px 25px" }}>
            {/* Image Upload */}
            <Box
              sx={{
                border: "2px dashed #D3D3D3",
                borderRadius: "8px",
                padding: "16px",
                textAlign: "center",
                margin: "16px 0",
                height: "96px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <input
                accept="image/*"
                id="image-upload"
                type="file"
                onChange={handleImageUpload}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
              {!uploadedImageUrl ? (
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    lineHeight: "21px",
                    color: "#0041A8",
                    textTransform: "capitalize",
                    border: "1px solid #0041A8",
                    borderRadius: "25px",
                    textAlign: "center",
                    pointerEvents: "none",
                  }}
                >
                  + Upload Image
                </Button>
              ) : (
                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                  <img
                    src={uploadedImageUrl}
                    alt="Preview"
                    style={{ 
                      width: '100%', 
                      height: '100%', 
                      objectFit: 'contain',
                      borderRadius: '4px'
                    }}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageDelete();
                    }}
                    sx={{
                      position: 'absolute',
                      top: -8,
                      right: -8,
                      backgroundColor: 'black',
                      '&:hover': {
                        backgroundColor: 'gray',
                      },
                      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                      width: '24px',
                      height: '24px',
                      padding: '4px'
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '16px', color: '#666' }} />
                  </IconButton>
                </Box>
              )}
              {formErrors.menu_image && (
                <Typography color="error" sx={{ mt: 1 }}>
                  {formErrors.menu_image}
                </Typography>
              )}
            </Box>

            {/* Form Fields */}
            <Box sx={{ pt: "9px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  lineHeight: "100%",
                  color: "#000000",
                }}
              >
                Offer Title
              </Typography>
              <TextField
                fullWidth
                name="menu_name"
                value={specialFormdata.menu_name}
                onChange={handleInputChange}
                error={!!formErrors.menu_name}
                helperText={formErrors.menu_name}
                placeholder="Enter title"
                sx={{
                  borderRadius: "6px",
                  borderColor: "#C5C5C5",
                  my: "10px",
                }}
                InputProps={{
                  sx: {
                    padding: "3px 11px", // Adjust top-bottom and left-right padding
                    borderRadius: "12px",
                  },
                }}
                inputProps={{
                  style: {
                    padding: "5px 7px", // Decrease padding inside the input
                  },
                }}
              />
            </Box>
            <Box sx={{ pt: "9px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  lineHeight: "100%",
                  color: "#000000",
                }}
              >
                Description
              </Typography>
              <TextField
                fullWidth
                name="menu_description"
                value={specialFormdata.menu_description}
                onChange={handleInputChange}
                error={!!formErrors.menu_description}
                helperText={formErrors.menu_desciption}
                multiline
                rows={3}
                placeholder="Enter Description"
                sx={{
                  borderRadius: "6px",
                  borderColor: "#C5C5C5",
                  my: "10px",
                }}
                InputProps={{
                  sx: {
                    padding: "3px 11px", // Adjust top-bottom and left-right padding
                    borderRadius: "12px",
                  },
                }}
                inputProps={{
                  style: {
                    padding: "5px 7px", // Decrease padding inside the input
                  },
                }}
              />
            </Box>
            <Box sx={{ pt: "9px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  lineHeight: "100%",
                  color: "#000000",
                }}
              >
                Price
              </Typography>
              <TextField
                fullWidth
                name="price"
                type="number"
                value={specialFormdata.price}
                onChange={handleInputChange}
                error={!!formErrors.price}
                helperText={formErrors.price}
                placeholder="Enter price"
                sx={{
                  borderRadius: "6px",
                  borderColor: "#C5C5C5",
                  my: "10px",
                }}
                InputProps={{
                  sx: {
                    padding: "3px 11px", // Adjust top-bottom and left-right padding
                    borderRadius: "12px",
                  },
                }}
                inputProps={{
                  style: {
                    padding: "5px 7px", // Decrease padding inside the input
                  },
                }}
              />
            </Box>

            <Box sx={{ pt: "9px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  lineHeight: "100%",
                  color: "#000000",
                }}
              >
                Coupon Code
              </Typography>
              <TextField
                fullWidth
                name="coupon_code"
                value={specialFormdata.coupon_code}
                onChange={handleInputChange}
                error={!!formErrors.coupon_code}
                helperText={formErrors.coupon_code}
                placeholder="Enter Code"
                sx={{
                  borderRadius: "6px",
                  borderColor: "#C5C5C5",
                  my: "10px",
                }}
                InputProps={{
                  sx: {
                    padding: "3px 11px", // Adjust top-bottom and left-right padding
                    borderRadius: "12px",
                  },
                }}
                inputProps={{
                  style: {
                    padding: "5px 7px", // Decrease padding inside the input
                  },
                }}
              />
            </Box>
            {/* Expiration Date & Status */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                pt: "10px",
              }}
            >
              <Box sx={{ width: "50%" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    lineHeight: "100%",
                    color: "#000000",
                  }}
                >
                  Expiration Date
                </Typography>
                <Box sx={{ flex: 1, position: "relative" }}>
                  <TextField
                    fullWidth
                    type="date"
                    name="expiration_date"
                    value={specialFormdata.expiration_date}
                    // onChange={(e) => {
                    //   setSpecialFormData((prev) => ({
                    //     ...prev,
                    //     expiration_date: e.target.value,
                    //   }));
                    //   if (formErrors.expiration_date) {
                    //     setFormErrors((prev) => ({
                    //       ...prev,
                    //       expiration_date: "",
                    //     }));
                    //   }
                    // }}
                    onChange={(e) => {
                      const selectedDate = new Date(e.target.value);
                      const today = new Date();
                      today.setHours(0, 0, 0, 0); // Normalize today's date for comparison
                    
                      if (selectedDate < today) {
                        setFormErrors((prev) => ({
                          ...prev,
                          expiration_date: "Expiration date cannot be in the past",
                        }));
                      } else {
                        setSpecialFormData((prev) => ({
                          ...prev,
                          expiration_date: e.target.value,
                        }));
                    
                        if (formErrors.expiration_date) {
                          setFormErrors((prev) => ({
                            ...prev,
                            expiration_date: "",
                          }));
                        }
                      }
                    }}  
                    error={!!formErrors.expiration_date}
                    helperText={formErrors.expiration_date}
                    sx={{
                      borderRadius: "6px",
                      borderColor: "#C5C5C5",
                      my: "10px",
                    }}
                    InputProps={{
                      sx: {
                        padding: "3px 11px",
                        borderRadius: "12px",
                      },
                    }}
                    inputProps={{
                      min: new Date().toISOString().split("T")[0],
                      style: {
                        padding: "5px 6px",
                      },
                    }}
                  />
                  {/* <CalendarMonthIcon
                    sx={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  /> */}
                </Box>
              </Box>
              <Box sx={{ width: "50%" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    lineHeight: "100%",
                    color: "#000000",
                  }}
                >
                  Status
                </Typography>
                <Select
                  name="status"
                  value={specialFormdata.status}
                  onChange={handleInputChange}
                  fullWidth
                  displayEmpty
                  sx={{
                    borderRadius: "12px",
                    // border: "1px solid #C5C5C5",
                    my: "10px",
                    padding: "3px 11px", // Adjust padding to match TextField
                    "& .MuiSelect-select": {
                      padding: "5px 7px", // Inner padding like TextField
                      borderRadius: "12px",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "12px", // Dropdown border radius
                      },
                    },
                  }}
                >
                  <MenuItem
                    value="Active"
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      lineHeight: "100%",
                      fontWeight: "400",
                    }}
                  >
                    Active
                  </MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </Box>
            </Box>

            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: "14px",
                gap: "6px",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleCloseAddOffer}
                disabled={isSubmitting}
                sx={{
                  borderRadius: "27px",
                  borderColor: "#0343AA",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  textTransform: "capitalize",
                  width: "28%",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={isSubmitting}
                sx={{
                  backgroundColor: "#0041A8",
                  borderRadius: "27px",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  textTransform: "capitalize",
                  width: "28%",
                }}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Save"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
      >
        <Box
          sx={{
            width: "400px",
            backgroundColor: "white",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="delete-dialog-title"
            sx={{
              fontSize: "18px",
              fontFamily: "Roboto",
              fontWeight: "600",
              lineHeight: "100%",
              mb: 2,
            }}
          >
            Delete Offer
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Are you sure you want to delete this offer?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              onClick={() => setDeleteDialogOpen(false)}
              sx={{
                borderRadius: "27px",
                borderColor: "#0343AA",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "14px",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteSpecial(specialToDelete)}
              variant="contained"
              sx={{
                backgroundColor: "#0041A8",
                borderRadius: "27px",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "21px",
                textTransform: "capitalize",
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Edit Offer Modal */}
      <Modal open={editOffer} onClose={handleCloseEditOffer}>
        <Box
          sx={{
            width: "400px",
            backgroundColor: "white",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
          }}
        >
          {/* Header */}
          <Box
            sx={{
              backgroundColor: "#0041A8",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                fontFamily: "Roboto",
                fontWeight: "600",
                lineHeight: "100%",
                pl: "12px",
              }}
            >
              Edit Offer
            </Typography>
            <IconButton onClick={handleCloseEditOffer} sx={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ padding: "16px 25px 28px 25px" }}>
            {/* Image Upload */}
            <Box
              sx={{
                border: "2px dashed #D3D3D3",
                borderRadius: "8px",
                padding: "16px",
                textAlign: "center",
                margin: "16px 0",
                height: "96px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <input
                accept="image/*"
                id="image-upload"
                type="file"
                onChange={async (event) => {
                  const file = event.target.files[0];
                  if (!file) return;

                  try {
                    // Compress image
                    const options = {
                      maxSizeMB: 1,
                      maxWidthOrHeight: 800,
                      useWebWorker: true,
                    };
                    const compressedFile = await imageCompression(file, options);

                    // Upload to S3
                    const params = {
                      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                      Key: `${Date.now()}-${file.name}`,
                      Body: compressedFile,
                      ContentType: file.type,
                      ACL: "public-read",
                    };

                    const uploadResult = await s3.upload(params).promise();
                    setSpecialToEdit({
                      ...specialToEdit,
                      menu_image: uploadResult.Location
                    });
                  } catch (error) {
                    console.error("Error uploading image:", error);
                  }
                }}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
              {!specialToEdit?.menu_image ? (
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    lineHeight: "21px",
                    color: "#0041A8",
                    textTransform: "capitalize",
                    border: "1px solid #0041A8",
                    borderRadius: "25px",
                    textAlign: "center",
                    pointerEvents: "none",
                  }}
                >
                  + Upload Image
                </Button>
              ) : (
                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                  <img
                    src={specialToEdit.menu_image}
                    alt="Preview"
                    style={{ 
                      width: '100%', 
                      height: '100%', 
                      objectFit: 'contain',
                      borderRadius: '4px'
                    }}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSpecialToEdit({ ...specialToEdit, menu_image: null });
                    }}
                    sx={{
                      position: 'absolute',
                      top: -8,
                      right: -8,
                      backgroundColor: 'black',
                      '&:hover': {
                        backgroundColor: 'gray',
                      },
                      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                      width: '24px',
                      height: '24px',
                      padding: '4px'
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '16px', color: '#666' }} />
                  </IconButton>
                </Box>
              )}
              {formErrors.menu_image && (
                <Typography color="error" sx={{ mt: 1 }}>
                  {formErrors.menu_image}
                </Typography>
              )}
            </Box>

            {/* Form Fields */}
            <Box sx={{ pt: "9px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  lineHeight: "100%",
                  color: "#000000",
                }}
              >
                Offer Title
              </Typography>
              <TextField
                fullWidth
                name="menu_name"
                value={specialToEdit?.menu_name || ""}
                onChange={(e) =>
                  setSpecialToEdit({ ...specialToEdit, menu_name: e.target.value })
                }
                error={!!formErrors.menu_name}
                helperText={formErrors.menu_name}
                placeholder="Enter title"
                sx={{
                  borderRadius: "6px",
                  borderColor: "#C5C5C5",
                  my: "10px",
                }}
                InputProps={{
                  sx: {
                    padding: "3px 11px",
                    borderRadius: "12px",
                  },
                }}
                inputProps={{
                  style: {
                    padding: "5px 7px",
                  },
                }}
              />
            </Box>

            <Box sx={{ pt: "9px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  lineHeight: "100%",
                  color: "#000000",
                }}
              >
                Description
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                name="menu_description"
                value={specialToEdit?.menu_description || ""}
                onChange={(e) =>
                  setSpecialToEdit({
                    ...specialToEdit,
                    menu_description: e.target.value,
                  })
                }
                error={!!formErrors.menu_description}
                helperText={formErrors.menu_description}
                placeholder="Enter description"
                sx={{
                  borderRadius: "6px",
                  borderColor: "#C5C5C5",
                  my: "10px",
                }}
                InputProps={{
                  sx: {
                    padding: "3px 11px",
                    borderRadius: "12px",
                  },
                }}
                inputProps={{
                  style: {
                    padding: "5px 7px",
                  },
                }}
              />
            </Box>

            <Box sx={{ pt: "9px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  lineHeight: "100%",
                  color: "#000000",
                }}
              >
                Price
              </Typography>
              <TextField
                fullWidth
                name="price"
                type="number"
                value={specialToEdit?.price || ""}
                onChange={(e) =>
                  setSpecialToEdit({ ...specialToEdit, price: e.target.value })
                }
                error={!!formErrors.price}
                helperText={formErrors.price}
                placeholder="Enter price"
                sx={{
                  borderRadius: "6px",
                  borderColor: "#C5C5C5",
                  my: "10px",
                }}
                InputProps={{
                  sx: {
                    padding: "3px 11px",
                    borderRadius: "12px",
                  },
                }}
                inputProps={{
                  style: {
                    padding: "5px 7px",
                  },
                }}
              />
            </Box>

            <Box sx={{ pt: "9px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  lineHeight: "100%",
                  color: "#000000",
                }}
              >
                Coupon Code
              </Typography>
              <TextField
                fullWidth
                name="coupon_code"
                value={specialToEdit?.coupon_code || ""}
                onChange={(e) =>
                  setSpecialToEdit({ ...specialToEdit, coupon_code: e.target.value })
                }
                error={!!formErrors.coupon_code}
                helperText={formErrors.coupon_code}
                placeholder="Enter Code"
                sx={{
                  borderRadius: "6px",
                  borderColor: "#C5C5C5",
                  my: "10px",
                }}
                InputProps={{
                  sx: {
                    padding: "3px 11px",
                    borderRadius: "12px",
                  },
                }}
                inputProps={{
                  style: {
                    padding: "5px 7px",
                  },
                }}
              />
            </Box>

            {/* Expiration Date & Status */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                pt: "10px",
              }}
            >
              <Box sx={{ width: "50%" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    lineHeight: "100%",
                    color: "#000000",
                  }}
                >
                  Expiration Date
                </Typography>
                <Box sx={{ flex: 1, position: "relative" }}>
                  <TextField
                    fullWidth
                    type="date"
                    name="expiration_date"
                    value={specialToEdit?.expiration_date || ""}
                    // onChange={(e) =>
                    //   setSpecialToEdit({
                    //     ...specialToEdit,
                    //     expiration_date: e.target.value,
                    //   })
                    // }
                    onChange={(e) => {
                      const selectedDate = new Date(e.target.value);
                      const today = new Date();
                      today.setHours(0, 0, 0, 0); // Normalize today's date for comparison
                  
                      if (selectedDate < today) {
                        setFormErrors({
                          ...formErrors,
                          expiration_date: "Expiration date cannot be in the past",
                        });
                      } else {
                        setSpecialToEdit({
                          ...specialToEdit,
                          expiration_date: e.target.value,
                        });
                  
                        if (formErrors.expiration_date) {
                          setFormErrors({
                            ...formErrors,
                            expiration_date: "",
                          });
                        }
                      }
                    }}
                    error={!!formErrors.expiration_date}
                    helperText={formErrors.expiration_date}
                    sx={{
                      borderRadius: "6px",
                      borderColor: "#C5C5C5",
                      my: "10px",
                    }}
                    InputProps={{
                      sx: {
                        padding: "3px 11px",
                        borderRadius: "12px",
                      },
                    }}
                    inputProps={{
                      min: new Date().toISOString().split("T")[0],
                      style: {
                        padding: "5px 6px",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ width: "50%" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    lineHeight: "100%",
                    color: "#000000",
                  }}
                >
                  Status
                </Typography>
                <Select
                  name="status"
                  // value={specialToEdit?.status === "1" ? "Active" : "Inactive"}
                  value={specialToEdit?.status}

                  onChange={(e) =>
                    setSpecialToEdit({
                      ...specialToEdit,
                      // status: e.target.value === "Active" ? "1" : "0"
                      status: e.target.value,
                    })
                  }
                  fullWidth
                  displayEmpty
                  sx={{
                    borderRadius: "12px",
                    my: "10px",
                    padding: "4px 12px",
                    "& .MuiSelect-select": {
                      padding: "5px 7px",
                      borderRadius: "12px",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "12px",
                      },
                    },
                  }}
                >
                  <MenuItem
                    value="Active"
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      lineHeight: "100%",
                      fontWeight: "400",
                    }}
                  >
                    Active
                  </MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </Box>
            </Box>

            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: "16px",
                gap: "6px",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleCloseEditOffer}
                disabled={isSubmitting}
                sx={{
                  borderRadius: "27px",
                  borderColor: "#0343AA",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  textTransform: "capitalize",
                  width: "28%",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleEditSubmit}
                disabled={isSubmitting}
                sx={{
                  backgroundColor: "#0041A8",
                  borderRadius: "27px",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  textTransform: "capitalize",
                  width: "28%",
                }}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Update"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DashboardContent;
