import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NetworkError() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 7,
        pt: 5,
        px: { xs: "16px", textAlign: "center" },
      }}
    >
      <Typography
        sx={{
          fontFamily: "Nunito",
          fontSize: "90px",
          fontWeight: "900",
          color: "rgb(57, 69, 129)",
        }}
      >
        404
      </Typography>
      <Typography
        sx={{
          fontFamily: "Nunito",
          fontWeight: "600",
          fontSize: "18px",
          color: "#8e8686",
        }}
      >
        Ooops, The page not found
      </Typography>
      <Box sx={{ pt: "30px" }}>
        <Button
          sx={{
            fontFamily: "Nunito",
            fontWeight: "600",
            fontSize: "14px",
            backgroundColor: "rgb(57, 69, 129)",
            color: "white",
            px: "20px",
            borderRadius: "25px",
          }}
          onClick={() => navigate("/")}
        >
          Back to Home
        </Button>
      </Box>
    </Box>
  );
}

export default NetworkError;
