import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "../axios";
import {
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const Profile = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    dob: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false); // State for password change dialog
  const [fetching, setFetching] = useState(true);
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordMessage, setPasswordMessage] = useState("");
  const [passwordLoading, setPasswordLoading] = useState(false);

  // 📌 Email validation function
  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  // 📌 Phone formatting function (XXX-XXX-XXXX)
  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, ""); // Remove non-numeric characters

    if (cleaned.startsWith("91") && cleaned.length > 10) {
      return cleaned; // Allow full 12-digit Indian number (91 + 10 digits)
    }

    if (cleaned.length === 10) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(
        6
      )}`; // US Format
    }

    return cleaned; // Return as-is for other cases
  };

  const GetUserDetails = async () => {
    try {
      const response = await axiosInstance.post(
        "user.php?method=get_user_details"
      );

      if (response.data.user) {
        const userData = response.data.user;
        setFormData({
          fullname: userData.name || "",
          email: userData.email || "",
          phone: userData.phone_number || "",
          dob: userData.date_of_birth || "",
        });
      } else {
        console.warn("No user details received from API.");
        return null;
      }
    } catch (error) {
      console.log("Error getting user details", error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    GetUserDetails();
  }, []);

  const handleChange = (e, value) => {
    let name, updatedValue;

    if (e && e.target) {
        name = e.target.name;
        updatedValue = e.target.value;

        if (name === "dob") {
            // Enforce YYYY-MM-DD format
            if (updatedValue.length > 10) {
                updatedValue = updatedValue.slice(0, 10); // Truncate to 10 characters
            }

            // check if the value is in YYYY-MM-DD format.
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            if (updatedValue && !dateRegex.test(updatedValue)){
                updatedValue = updatedValue.replace(/[^0-9-]/g, '');
            }
        }

    } else {
        name = "phone";
        updatedValue = value;
    }

    let error = "";

    if (name === "phone") {
        updatedValue = formatPhoneNumber(updatedValue);

        const isValidUSNumber =
            (updatedValue.length === 12 && updatedValue.includes("-")) ||
            (updatedValue.length === 11 && updatedValue.startsWith("1"));

        const isValidIndianNumber =
            updatedValue.length === 12 && updatedValue.startsWith("91");

        if (!isValidUSNumber && !isValidIndianNumber) {
            error = "Invalid phone number format";
        } else if (!updatedValue.trim()) {
            error = "Please fill the required field";
        }
    }

    setFormData((prev) => ({ ...prev, [name]: updatedValue }));
    setErrors((prev) => ({ ...prev, [name]: error }));
};

  // // 📌 Update Profile API call
  // const handleUpdateProfile = async () => {
  //   setLoading(true);
  //   setMessage("");

  //   // Check if any field is empty
  //   const emptyFields = Object.values(formData).some(
  //     (field) => field.trim() === ""
  //   );
  //   if (emptyFields) {
  //     setMessage("Please fill in all details");
  //     setLoading(false);
  //     return;
  //   }

  //   // Check if any validation errors exist
  //   if (errors.email || errors.phone) {
  //     setMessage("Please fix errors before submitting");
  //     setLoading(false);
  //     return;
  //   }

  //   // Construct payload with concatenated name and fixed about field
  //   const payload = {
  //     name: formData.fullname,
  //     email: formData.email,
  //     phone_number: formData.phone,
  //     date_of_birth: formData.dob,
  //     about: "new user",
  //   };

  //   try {
  //     const response = await axiosInstance.post(
  //       "user.php?method=update_user_details",
  //       payload
  //     );

  //     if (response.data?.status === "success") {
  //       setMessage("Profile updated successfully!");

  //       // ✅ Clear form fields on success
  //       setFormData({
  //         fullname: payload.name,
  //         email: payload.email,
  //         phone: payload.phone_number,
  //         dob: payload.date_of_birth,
  //       });
  //       setErrors({});
  //     } else {
  //       setMessage("Profile updation failed!");
  //     }
  //   } catch (error) {
  //     setMessage("Error updating profile. Please try again.");
  //     console.error("Update Profile Error:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleUpdateProfile = async () => {
    setLoading(true);
    setMessage("");

    let newErrors = {};
    let firstEmptyField = null;

    Object.entries(formData).forEach(([key, value]) => {
      if (!value.trim()) {
        newErrors[key] = "Please fill the required field";
        if (!firstEmptyField) {
          firstEmptyField = key; // Store the first empty field
        }
      }
    });

    if (!isValidEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      if (!firstEmptyField) firstEmptyField = "email"; 
    }


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);

      // Focus the first empty field
      if (firstEmptyField) {
        document.getElementsByName(firstEmptyField)[0]?.focus();
      }
      return;
    }

    if (errors.email || errors.phone) {
      setMessage("Please fix errors before submitting");
      setLoading(false);
      return;
    }

    const payload = {
      name: formData.fullname,
      email: formData.email,
      phone_number: formData.phone,
      date_of_birth: formData.dob,
      about: "new user",
    };

    try {
      const response = await axiosInstance.post(
        "user.php?method=update_user_details",
        payload
      );

      if (response.data?.status === "success") {
        setMessage("Profile updated successfully!");
        setErrors({}); // Clear errors on success
      } else {
        setMessage("Profile updation failed!");
      }
    } catch (error) {
      setMessage("Error updating profile. Please try again.");
      console.error("Update Profile Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = () => {
    setPasswordMessage(""); // Reset message when modal opens
    setPasswords({ oldPassword: "", newPassword: "", confirmPassword: "" }); // Reset password fields
    setOpen(true);
  };

  const isValidPassword = (password) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/.test(
      password
    );
  };
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });

    if (name === "newPassword") {
      if (!isValidPassword(value)) {
        setPasswordMessage(
          "Password must be 6-16 characters long, include an uppercase, lowercase, digit, and special character."
        );
      } else {
        setPasswordMessage("");
      }
    }
  };
  const handleUpdatePassword = async () => {
    setPasswordMessage("");
    const auth = getAuth();

    // ✅ Ensure the email is not empty
    if (!formData.email || formData.email.trim() === "") {
      setPasswordMessage("Email is required to update the password.");
      return;
    }

    // ✅ Ensure password fields are filled
    if (
      !passwords.oldPassword ||
      !passwords.newPassword ||
      !passwords.confirmPassword
    ) {
      setPasswordMessage("Please fill in all fields.");
      return;
    }

    // ✅ Ensure new password matches confirmation
    if (passwords.newPassword !== passwords.confirmPassword) {
      setPasswordMessage("New passwords do not match.");
      return;
    }

    try {
      setPasswordLoading(true);
      const user = auth.currentUser;

      if (!user) {
        setPasswordMessage("No user is logged in.");
        return;
      }

      // ✅ Re-authenticate the user before updating the password
      await signInWithEmailAndPassword(
        auth,
        formData.email,
        passwords.oldPassword
      );

      // ✅ Update the password after successful re-authentication
      await updatePassword(user, passwords.newPassword);

      setPasswordMessage("Password updated successfully!");
      setPasswords({ oldPassword: "", newPassword: "", confirmPassword: "" });
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    } catch (error) {
      setPasswordMessage("check your old Password try again!", error.message);
    } finally {
      setPasswordLoading(false);
    }
  };

  if (fetching) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
      <Paper
        elevation={0}
        sx={{
          p: 4,
          maxWidth: 500,
          width: "100%",
          borderRadius: "12px",
          // border:"solid gray",
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="h5" fontWeight="bold" textAlign="center" mb={2}>
          Profile
        </Typography>
        <TextField
          fullWidth
          label="Full Name"
          name="fullname"
          value={formData.fullname}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          error={!!errors.fullname}
          helperText={errors.fullname}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          error={!!errors.email}
          helperText={errors.email}
        />
        <Box sx={{ width: "100%", mt: 2 }}>
          <PhoneInput
            country={"us"}
            value={formData.phone}
            onChange={(value) => handleChange(null, value)}
            inputProps={{
              name: "phone",
              required: true,
            }}
            containerStyle={{ width: "100%" }} // Makes the entire component full width
            inputStyle={{
              width: "100%", // Makes the input field full width
              height: "56px", // Matches MUI TextField height
              borderRadius: "4px", // Matches MUI border-radius
              border: "1px solid rgba(0, 0, 0, 0.23)", // Matches MUI default border color
              paddingLeft: "48px", // Ensures flag doesn't overlap input text
              fontSize: "16px",
            }}
          />
          {errors.phone && (
            <Typography color="error" sx={{ mt: 1, fontSize: "14px" }}>
              {errors.phone}
            </Typography>
          )}
        </Box>

        <TextField
          fullWidth
          label="Date of Birth"
          name="dob"
          value={formData.dob || ""}
          onChange={handleChange}
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true }}
          inputProps={{ pattern: "\\d{4}-\\d{2}-\\d{2}" }} // Ensures YYYY-MM-DD format
          placeholder="YYYY-MM-DD" // Helps users understand input format
          margin="normal"
          error={!!errors.dob}
          helperText={errors.dob}
        />

        {message && (
          <Typography
            color={message.includes("successfully") ? "green" : "red"}
            textAlign="center"
            mt={1}
          >
            {message}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, py: 1.2 }}
          onClick={handleUpdateProfile}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Update Profile"
          )}
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          sx={{ mt: 2, py: 1.2 }}
          onClick={() => setOpen(true)}
        >
          Change Password
        </Button>
      </Paper>

      {/* Password Change Dialog */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Change Password
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            type="password"
            label="Old Password"
            name="oldPassword"
            value={passwords.oldPassword}
            onChange={handlePasswordChange}
            margin="normal"
          />
          <TextField
            fullWidth
            type="password"
            label="New Password"
            name="newPassword"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
            margin="normal"
          />
          <TextField
            fullWidth
            type="password"
            label="Confirm New Password"
            name="confirmPassword"
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
            margin="normal"
          />
          {passwordMessage && (
            <Typography color="error" sx={{ mt: 1 }}>
              {passwordMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdatePassword}
            color="primary"
            disabled={passwordLoading}
          >
            {passwordLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Update Password"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Profile;
