import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Grid, Radio, Typography, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import uploadimage from "../images/carousel-banner1.png";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axiosInstance from "./axios";
import { isAuthenticated } from "../globals/helper";
import greylogo from "../images/greylogo.png";
import PhoneInput from "react-phone-input-2";
import TermsModal from "./TermsModal";
import "react-phone-input-2/lib/material.css";
import PrivacyPolicy from "./PrivacyPolicy";

const CustomCheckbox = styled(Checkbox)({
  width: 18,
  height: 18,
  padding: 0,
  "& .MuiSvgIcon-root": {
    fontSize: 0,
  },
  "& .custom-icon": {
    width: 18,
    height: 18,
    borderRadius: "4px",
    border: "2px solid #5C84FF",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "&.Mui-checked .custom-icon": {
    backgroundColor: "#5C84FF",
    border: "2px solid #5C84FF",
  },
  "&.Mui-checked .custom-icon svg": {
    fontSize: 14,
    color: "white",
  },
});

const Header = styled(Box)({
  // width: "98%",
  borderWidth: "1px",
  borderBottom: "1px solid rgb(184, 183, 183)",
  // padding: "16px 0px 16px 40px",
});

const stripekey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
const stripePromise = loadStripe(stripekey);

const CheckoutForm = ({
  selectedPrice,
  onSuccess,
  formData,
  userId,
  totalPrice,
  shippingData,
  validateForm,
  shippingFormData, // Add this
  useBillingAsShipping, //
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { placeid, producId } = location.state || {};
  if (!stripekey) {
    console.error(
      "Stripe publishable key is missing. Check your environment variables."
    );
  }

  const handlePurchase = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Validate form before proceeding
      validateForm();

      const { error: cardError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            name: formData.firstName,
            email: formData.email,
          },
        });

      if (cardError) {
        throw new Error(cardError.message);
      }

      // Send payment method ID to your server
      const response = await axiosInstance.post(
        "payments.php?method=process_payment",
        {
          payment_method_id: paymentMethod.id,
          amount: totalPrice * 100, // Convert to cents
          email: formData.email,
          name: formData.firstName,
        }
      );
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      const transactionId = response.data.paymentIntent?.id;
      const orderPayload = {
        user_id: userId || "",
        name: formData.firstName,
        email: formData.email,
        phone: formData.phone,
        product_id: producId,
        transaction_id: transactionId,
        amount: parseFloat(totalPrice),
        place_id: placeid,
        shipping_id: shippingData?.courier_service?.id,
        shipping_name: `${shippingData?.courier_service?.name || ""} ${
          shippingData?.courier_service?.umbrella_name || ""
        }`,
        shipping_description: shippingData?.full_description,
        shipping_price: shippingData?.total_charge,
        ship_details:  {
          city: formData.city,
          state: formData.state,
          address: formData.address,
          country: formData.country,
          pincode: formData.zipCode,
          name: formData.firstName,
        },
        bill_details:  useBillingAsShipping
          ? {
              city: formData.city,
              state: formData.state,
              address: formData.address,
              country: formData.country,
              pincode: formData.zipCode,
              name: formData.firstName,
            }
          : {
              city: shippingFormData.city,
              state: shippingFormData.state,
              address: shippingFormData.address,
              country: shippingFormData.country,
              pincode: shippingFormData.zipCode,
              name: shippingFormData.firstName,
            },
      };
      if (formData.company) {
        orderPayload.bill_details.company = formData.company;
      }

      const orderResponse = await axiosInstance.post(
        "payments.php?method=create_order",
        orderPayload
      );
      if (orderResponse.data.status !== "success") {
        throw new Error("Order creation failed");
      }

      if (onSuccess) onSuccess();
      navigate("/thankyou");
    } catch (err) {
      setError(
        err.message || "An error occurred while processing your payment"
      );
      console.error("Payment error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: { xs: "12px", sm: "10px" } }}>
      {error && (
        <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
          {error}
        </Typography>
      )}
      <Button
        onClick={handlePurchase}
        disabled={isLoading || !stripe}
        sx={{
          backgroundColor: "#2B56F5",
          mt: "30px",
          color: "white",
          width: "100%",
          mb: { xs: "8px", sm: "0px" },
          "&:disabled": {
            backgroundColor: "#a0a0a0",
            color: "#ffffff",
          },
        }}
      >
        <Typography
          fontFamily={"Montserrat"}
          py={{ xs: "6px", sm: "8px" }}
          fontSize={"16px"}
          fontWeight={"600"}
          textTransform={"capitalize"}
        >
          {isLoading ? "Processing..." : "Complete Purchase"}
        </Typography>
      </Button>
    </Box>
  );
};

function Checkout() {
  const location = useLocation();
  const {
    plates,
    price,
    placeName,
    fullPlaceName,
    placeid,
    producId,
    retailPrice,
  } = location.state || {};
  const [plateOptions, setPlateOptions] = useState([]);
  const [selectedPlate, setSelectedPlate] = useState(plates || "");
  const [selectedPrice, setSelectedPrice] = useState(price || "");
  const [selectedreatilPrice, setSelectedreatilPrice] = useState(
    retailPrice || ""
  );
  const [cardChecked, setCardChecked] = useState(true);
  const [error, setError] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userId, setUserId] = useState("");
  const [countries, setcountries] = useState([]);
  const [states, setStates] = useState([]);
  const [shippingStates, setShippingStates] = useState([]);
  const [shippingCostFetched, setShippingCostFetched] = useState(false);
  const [rates, setRates] = useState([]);
  const [prevFormData, setPrevFormData] = useState(null); // Track previous form data
  const [open, setOpen] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  const navigate = useNavigate();
  const [isBusinessAvailable, setIsBusinessAvailable] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    country: "",
    firstName: "",
    lastName: "",
    company: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
  });
  const [shippingFormData, setShippingFormData] = useState({
    country: "",
    firstName: "",
    lastName: "",
    company: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
  });
  const [useBillingAsShipping, setUseBillingAsShipping] = useState(true);
  const emailRef = useRef(null);
  const countryRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const companyRef = useState(null);
  const addressRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const zipCodeRef = useRef(null);
  const phoneRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isAuthenticated()) {
      setUserLoggedIn(true);
      fetchUserDetails(); // Fetch user details
    }
  }, []);
  useEffect(() => {
    if (placeName === undefined || null) {
      navigate("/");
    } else {
      setIsBusinessAvailable(true);
    }
  }, [placeName]);

  const handleBillingShippingCheckbox = (event) => {
    setUseBillingAsShipping(event.target.checked);
    if (event.target.checked) {
      // When checked, copy billing address to shipping address
      setShippingFormData(formData);
    }
  };

  const handleShippingChange = (e) => {
    if (typeof e === "string") {
      // Remove spaces and dashes from the phone number
      const cleanedPhone = e.replace(/\D/g, ""); // Keeps only numbers

      setShippingFormData((prevData) => ({
        ...prevData,
        phone: cleanedPhone, // Store cleaned phone number
      }));
    } else {
      const { name, value } = e.target;
      setShippingFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const requiredFields = {
      email: { label: "Email", ref: emailRef },
      country: { label: "Country", ref: countryRef },
      firstName: { label: "First Name", ref: firstNameRef },
      lastName: { label: "Last Name", ref: lastNameRef },
      address: { label: "Address", ref: addressRef },
      city: { label: "City", ref: cityRef },
      state: { label: "State", ref: stateRef },
      zipCode: { label: "Zip Code", ref: zipCodeRef },
      phone: { label: "Phone Number", ref: phoneRef },
    };

    for (const [field, { label, ref }] of Object.entries(requiredFields)) {
      if (!formData[field] || formData[field].trim() === "") {
        if (ref?.current) {
          if (field === "state") {
            ref?.current?.node?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          } else {
            ref?.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
          ref?.current?.focus(); // Optional: focus the field
        }
        throw new Error(`${label} is required`);
      }
      if (!useBillingAsShipping) {
        const shippingFields = [
          "firstName",
          "lastName",
          "address",
          "city",
          "state",
          "zipCode",
        ];
        for (const field of shippingFields) {
          if (
            !shippingFormData[field] ||
            shippingFormData[field].trim() === ""
          ) {
            throw new Error(
              `Shipping ${
                field.charAt(0).toUpperCase() + field.slice(1)
              } is required`
            );
          }
        }
      }
    }

    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    if (!phoneRegex.test(formData.phone)) {
      if (phoneRef.current) {
        phoneRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        phoneRef.current.focus();
      }
      throw new Error("Please enter a valid phone number");
    }

    const zipRegex = /^(?:\d{4,8}|[A-Za-z0-9\s-]+)$/;
    if (!zipRegex.test(formData.zipCode)) {
      if (zipCodeRef.current) {
        zipCodeRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        zipCodeRef.current.focus();
      }
      throw new Error("Please enter a valid ZIP code");
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      if (emailRef.current) {
        emailRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        emailRef.current.focus();
      }
      throw new Error("Please enter a valid email address");
    }
  };

  const requiredFields = ["country", "address", "city", "state", "zipCode"];
  const areAllFieldsFilled = () => {
    return requiredFields.every(
      (field) => formData[field] && formData[field].trim() !== ""
    );
  };
  useEffect(() => {
    if (!areAllFieldsFilled()) return; // Exit if required fields are not filled

    // If it's the first time, call the API
    if (!shippingCostFetched) {
      shppingCost();
      return;
    }

    // If it's not the first time, check for field changes
    if (prevFormData) {
      const hasChanged = requiredFields.some(
        (field) => prevFormData[field] !== formData[field]
      );

      if (hasChanged) {
        shppingCost();
      }
    }

    // Update previous form data for next change detection
    setPrevFormData({ ...formData });
  }, [formData]);

  const shppingCost = async () => {
    try {
      const response = await axiosInstance.post(
        "payments.php?method=shipping_cost",
        {
          destination_address: {
            city: formData.city,
            state: formData.state,
            address: formData.address,
            country: formData.country,
            pincode: formData.zipCode,
            product_id: producId,
          },
        }
      );
      if (!response.data.rates || response.data.rates.length === 0) {
        setRates([]); // Ensure UI handles empty rates
        return;
      }
      // ✅ Ensure cost_rank is a number and sort properly
      const sortedRates = [...response.data.rates].sort(
        (a, b) => a.cost_rank - b.cost_rank
      );
      setShippingCostFetched(true); // ✅ Prevent multiple calls
      setRates(sortedRates);
    } catch (err) {
      console.error("❌ Error fetching shipping cost:", err);
    }
  };
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axiosInstance.post(
          "payments.php?method=countries"
        );
        if (response.data.countries) {
          setcountries(response.data.countries);

          // ✅ Set "United States" as the default country if available
          const defaultCountry = response.data.countries.find(
            (c) => c.name === "United States"
          );

          if (defaultCountry) {
            setFormData((prev) => ({
              ...prev,
              country: defaultCountry.shortname,
            }));

            // ✅ Fetch states for United States
            fetchStates(defaultCountry.id);
          }
        }
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (formData.country) {
      const selectedCountry = countries.find(
        (c) => c.shortname === formData.country
      );
      if (selectedCountry) {
        fetchStates(selectedCountry.id);
      }
    }
  }, [formData.country]);

  useEffect(() => {
    if (shippingFormData.country) {
      const selectedCountry = countries.find(
        (c) => c.shortname === shippingFormData.country
      );
      if (selectedCountry) {
        fetchShippingStates(selectedCountry.id);
      }
    }
  }, [shippingFormData.country]);

  const fetchStates = async (countryId) => {
    try {
      const response = await axiosInstance.post("payments.php?method=states", {
        country_id: countryId,
      });

      if (response.data.states) {
        setStates(response.data.states);
      } else {
        setStates([]);
      }
    } catch (err) {
      console.error("Error fetching states:", err);
      setStates([]);
    }
  };

  const fetchShippingStates = async (countryId) => {
    try {
      const response = await axiosInstance.post("payments.php?method=states", {
        country_id: countryId,
      });

      if (response.data.states) {
        setShippingStates(response.data.states);
      } else {
        setShippingStates([]);
      }
    } catch (err) {
      console.error("Error fetching shipping states:", err);
      setShippingStates([]);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await axiosInstance.post(
        "user.php?method=get_user_details"
      );
      if (response.data.user) {
        console.log("Fetched User Data:", response.data.user);
        setUserId(response.data.user.id || ""); // Store user ID
        setFormData((prev) => ({
          ...prev,
          email: response.data.user.email || "",
        }));
      } else {
        console.warn("No user details received from API.");
      }
    } catch (error) {
      console.error("Error getting user details", error);
    }
  };
  const handleCardChange = (event) => {
    if (event.complete || event.empty === false) {
      setCardChecked(true);
    } else if (event.empty) {
      setCardChecked(false);
    }
  };

  useEffect(() => {
    const fetchPlates = async () => {
      try {
        const response = await axiosInstance.post(
          "payments.php?method=products"
        );
        setPlateOptions(response.data.places);
      } catch (err) {
        console.error("Error fetching plates:", err);
      }
    };
    fetchPlates();
  }, []);

  const handlePlateChange = (event) => {
    const selectedItem = plateOptions.find(
      (item) => item.name === event.target.value
    );
    setSelectedPlate(event.target.value);
    setSelectedPrice(selectedItem ? selectedItem.price : selectedPrice);
    setSelectedreatilPrice(
      selectedItem ? selectedItem.retail_price : selectedreatilPrice
    );
  };

  // Handle form input change
  const handleChange = (e, value) => {
    if (typeof e === "string") {
      // Remove spaces and dashes from the phone number
      const cleanedPhone = e.replace(/\D/g, ""); // Keeps only numbers

      setFormData((prevData) => ({
        ...prevData,
        phone: cleanedPhone, // Store cleaned phone number
      }));
    } else {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  //for api passing
  const TotalPrice =
    rates?.length > 0 && rates[0]?.total_charge
      ? parseFloat(
          (Number(selectedPrice) + Number(rates[0]?.total_charge)).toFixed(2)
        )
      : parseFloat(Number(selectedPrice).toFixed(2));

  if (!isBusinessAvailable) {
    return null;
  }

  return (
    <Elements stripe={stripePromise}>
      <Box>
        <Header
          sx={{
            width: { xs: "100%", sm: "98%" },
            padding: { xs: "16px 4px 16px 4px", sm: "16px 0px 16px 0px" },
            mx: "auto",
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={0} sm={5}></Grid>
            <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
              <Box component="img" src={greylogo} />
            </Grid>
            <Grid item xs={0} sm={4}></Grid>
            <Grid></Grid>
          </Grid>
        </Header>
        <Grid container px={{ xs: "12px", sm: "0px" }}>
          <Grid
            items
            xs={12}
            sm={6}
            borderRight={{ sm: "2px solid #D9D9D9" }}
            pt={{ xs: "20px", sm: "60px" }}
            position={"relative"}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: { xs: "16px", sm: "20px" },
                  letterSpacing: "1px",
                  lineHeight: "34px",
                }}
              >
                Express checkout
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"center"}
                pt={"10px"}
                gap={{ xs: "2px", sm: "0px" }}
              ></Box>
            </Box>
            <Box
              component="section"
              sx={{
                paddingLeft: { xs: "", sm: "75px" },
                paddingRight: { xs: "", sm: "75px" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontsize: "15px",
                  fontWeight: "500",
                  lineHeight: "34px",
                  letterSpacing: "1px",
                  color: "#000000",
                  paddingTop: { xs: "10px", sm: "0px" },
                }}
              >
                Email or Phone
              </Typography>
              <TextField
                inputRef={emailRef}
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                type="email"
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    height: 40,
                  },
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                  },
                  "& input:-webkit-autofill": {
                    WebkitBoxShadow: "0 0 0 1000px white inset !important", // Removes autofill background
                    backgroundColor: "transparent !important",
                  },
                }}
                disabled={userLoggedIn}
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontsize: "15px",
                    fontFamily: "Montserrat",
                    lineHeight: "34px",
                    letterSpacing: "1px",
                    paddingTop: { xs: "10px", sm: "0px" },
                  }}
                >
                  Delivery Address
                </Typography>
                <TextField
                  inputRef={countryRef}
                  select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  SelectProps={{
                    displayEmpty: true,
                    IconComponent: KeyboardArrowDownIcon, // Custom arrow
                    sx: {
                      height: "40px",
                      "& .MuiSelect-icon": {
                        color: "rgba(0, 0, 0, 0.6)", // Arrow color
                        fontSize: "24px", // Arrow size
                        fontWeight: "medium",
                      },
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      height: "40px",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      border: "1px solid #D9D9D9",
                    },
                  }}
                  sx={{
                    mb: { xs: "5px", sm: "0px" },
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      color: formData.country ? "black" : "rgba(0, 0, 0, 0.5)", // Placeholder color effect
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Country
                  </MenuItem>

                  {/* Country List */}
                  {countries.map((option) => (
                    <MenuItem key={option.id} value={option.shortname}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Grid
                  container
                  display={"flex "}
                  justifyContent={"space-between"}
                  pt={"5px"}
                >
                  <Grid items xs={12} sm={5.9}>
                    <TextField
                      inputRef={firstNameRef}
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="First name"
                      variant="outlined"
                      fullWidth
                      sx={{
                        mb: { xs: "10px", sm: "0px" },
                        "& .MuiInputBase-root": {
                          height: 40,
                        },
                        "& .MuiInputBase-input": {
                          padding: "12px 14px", // Decreased padding
                        },
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow:
                            "0 0 0 1000px white inset !important", // Removes autofill background
                          backgroundColor: "transparent !important",
                        },
                      }}
                    />
                  </Grid>
                  <Grid items xs={12} sm={6}>
                    <TextField
                      inputRef={lastNameRef}
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Last name"
                      variant="outlined"
                      fullWidth
                      sx={{
                        mb: { xs: "5px", sm: "0px" },
                        "& .MuiInputBase-root": {
                          height: 40,
                        },
                        "& .MuiInputBase-input": {
                          padding: "12px 14px", // Decreased padding
                        },
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow:
                            "0 0 0 1000px white inset !important", // Removes autofill background
                          backgroundColor: "transparent !important",
                        },
                      }}
                    />
                  </Grid>
                  <Grid items xs={12} sm={12} pt={"5px"}>
                    <TextField
                      inputRef={companyRef}
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      placeholder="Company (optional)"
                      type="text"
                      variant="outlined"
                      fullWidth
                      sx={{
                        mb: { xs: "5px", sm: "0px" },
                        "& .MuiInputBase-root": {
                          height: 40,
                        },
                        "& .MuiInputBase-input": {
                          padding: "12px 14px", // Decreased padding
                        },
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow:
                            "0 0 0 1000px white inset !important", // Removes autofill background
                          backgroundColor: "transparent !important",
                        },
                      }}
                    />
                  </Grid>
                  <Grid items xs={12} sm={12} pt={"5px"}>
                    <TextField
                      inputRef={addressRef}
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      placeholder="Address"
                      type="text"
                      variant="outlined"
                      fullWidth
                      sx={{
                        mb: { xs: "5px", sm: "0px" },
                        "& .MuiInputBase-root": {
                          height: 40,
                        },
                        "& .MuiInputBase-input": {
                          padding: "12px 14px", // Decreased padding
                        },
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow:
                            "0 0 0 1000px white inset !important", // Removes autofill background
                          backgroundColor: "transparent !important",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  display={"flex "}
                  justifyContent={"space-between"}
                  pt={"5px"}
                >
                  <Grid items xs={12} sm={3.9}>
                    <TextField
                      inputRef={cityRef}
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      placeholder="City"
                      variant="outlined"
                      fullWidth
                      sx={{
                        mb: { xs: "10px", sm: "0px" },
                        "& .MuiInputBase-root": {
                          height: 40,
                        },
                        "& .MuiInputBase-input": {
                          padding: "12px 14px", // Decreased padding
                        },
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow:
                            "0 0 0 1000px white inset !important", // Removes autofill background
                          backgroundColor: "transparent !important",
                        },
                      }}
                    />
                  </Grid>

                  <Grid items xs={12} sm={4}>
                    <TextField
                      inputRef={stateRef}
                      select
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      SelectProps={{
                        displayEmpty: true,
                        IconComponent: KeyboardArrowDownIcon, // Custom arrow
                        sx: {
                          height: "40px",

                          "& .MuiSelect-icon": {
                            color: "rgba(0, 0, 0, 0.6)", // Arrow color
                            fontSize: "24px", // Arrow size
                            fontWeight: "medium",
                          },
                        },
                        "& .MuiInputBase-input": {
                          padding: "12px 14px", // Decreased padding
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow:
                            "0 0 0 1000px white inset !important", // Removes autofill background
                          backgroundColor: "transparent !important",
                        },
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "8px",
                          height: "40px",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          border: "1px solid #D9D9D9",
                        },
                      }}
                      sx={{
                        mb: { xs: "10px", sm: "0px" },
                        "& .MuiInputBase-input": {
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          color: formData.state
                            ? "black"
                            : "rgba(0, 0, 0, 0.5)", // Placeholder effect
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select State
                      </MenuItem>

                      {states.length > 0 ? (
                        states.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No states available</MenuItem>
                      )}
                    </TextField>
                  </Grid>

                  <Grid items xs={12} sm={3.9}>
                    <TextField
                      inputRef={zipCodeRef}
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleChange}
                      placeholder="Zip code"
                      variant="outlined"
                      fullWidth
                      sx={{
                        mb: { xs: "5px", sm: "0px" },
                        "& .MuiInputBase-root": {
                          height: 40,
                        },
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                        },
                        "& .MuiInputBase-input": {
                          padding: "12px 14px", // Decreased padding
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow:
                            "0 0 0 1000px white inset !important", // Removes autofill background
                          backgroundColor: "transparent !important",
                        },
                      }}
                    />
                  </Grid>
                  <Grid items xs={12} sm={12} pt={"5px"}>
                    <Box sx={{ width: "100%", mt: 2 }}>
                      <PhoneInput
                        country={"us"}
                        name="phone"
                        value={formData.phone}
                        onChange={(phone) => handleChange(phone)}
                        placeholder="Phone"
                        variant="outlined"
                        inputProps={{
                          ref: phoneRef, // Add ref here
                          name: "phone",
                        }}
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": {
                            height: 40,
                          },
                          "& .MuiInputBase-input": {
                            padding: "12px 14px", // Decreased padding
                          },
                          "& .MuiInputBase-input::placeholder": {
                            fontSize: "14px",
                            fontFamily: "Montserrat",
                          },
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow:
                              "0 0 0 1000px white inset !important", // Removes autofill background
                            backgroundColor: "transparent !important",
                          },
                        }}
                        containerStyle={{ width: "100%" }} // Makes the entire component full width
                        inputStyle={{
                          width: "100%", // Makes the input field full width
                          height: "56px", // Matches MUI TextField height
                          borderRadius: "4px", // Matches MUI border-radius
                          border: "1px solid rgba(0, 0, 0, 0.23)", // Matches MUI default border color
                          paddingLeft: "48px", // Ensures flag doesn't overlap input text
                          fontSize: "16px",
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <>
              <Box
                sx={{
                  paddingLeft: { xs: "", sm: "75px" },
                  paddingRight: { xs: "", sm: "75px" },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontsize: "15px",
                    fontWeight: "500",
                    lineHeight: "34px",
                    letterSpacing: "1px",
                    color: "#000000",
                    pt: { xs: "12px", sm: "10px" },
                  }}
                >
                  Secure Checkout
                </Typography>
              </Box>

              <Box
                pt={{ xs: "10px", sm: "12px" }}
                sx={{
                  paddingLeft: { xs: "", sm: "75px" },
                  paddingRight: { xs: "", sm: "75px" },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "rgb(247, 247, 247)",
                    border: "1px solid  rgb(196, 196, 196)",
                    borderRadius: "5px 5px 0 0",
                  }}
                >
                  <Box
                    paddingLeft={"4px"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    p={{ xs: "10px", sm: "10px" }}
                    sx={{
                      border: "1px solid #2B56F5",
                      borderRadius: "5px 5px 0 0",
                    }}
                  >
                    <Radio
                      size="small"
                      checked={cardChecked}
                      sx={{
                        color: "#2B56F5",
                        width: "8px",
                        height: "8px",
                        "&.Mui-checked": {
                          color: "#2B56F5",
                          backgroundColor: "#2B56F5",
                          width: "8px",
                          height: "8px",
                          "&::after": {
                            content: '""',
                            width: "6px",
                            height: "6px",
                            backgroundColor: "white",
                            display: "block",
                            borderRadius: "50%",
                            position: "absolute",
                          },
                        },
                      }}
                    />
                    <Typography
                      fontSize={"13px"}
                      fontFamily={"Montserrat"}
                      fontWeight={"600"}
                    >
                      {" "}
                      Credit Card
                    </Typography>
                  </Box>
                  <Box sx={{ p: { xs: "12px", sm: "10px" } }}>
                    <Grid items xs={12} sm={12} backgroundColor={"white"}>
                      <CardElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              fontFamily: "Montserrat, sans-serif",
                              color: "#424770",
                              "::placeholder": {
                                color: "#aab7c4",
                              },
                            },
                            invalid: {
                              color: "#9e2146",
                            },
                          },
                        }}
                        onChange={handleCardChange}
                      />
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      paddingLeft: "10px",
                      display: "flex",
                      gap: "6px",
                      marginBottom: "10px",
                    }}
                  >
                    <CustomCheckbox
                      checked={useBillingAsShipping}
                      onChange={handleBillingShippingCheckbox}
                      icon={<span className="custom-icon" />}
                      checkedIcon={
                        <span className="custom-icon">
                          <CheckIcon />
                        </span>
                      }
                    />
                    <Typography
                      fontSize={{ xs: "12px", sm: "14px" }}
                      fontFamily={"Montserrat"}
                    >
                      Use billing address as Shipping address
                    </Typography>
                  </Box>
                  {!useBillingAsShipping && (
                    <>
                      <TextField
                        select
                        name="country"
                        value={shippingFormData.country}
                        onChange={handleShippingChange}
                        fullWidth
                        variant="outlined"
                        SelectProps={{
                          displayEmpty: true,
                          IconComponent: KeyboardArrowDownIcon, // Custom arrow
                          sx: {
                            height: "40px",
                            "& .MuiSelect-icon": {
                              color: "rgba(0, 0, 0, 0.6)", // Arrow color
                              fontSize: "24px", // Arrow size
                              fontWeight: "medium",
                            },
                          },
                        }}
                        InputProps={{
                          sx: {
                            borderRadius: "8px",
                            height: "40px",
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Montserrat",
                            border: "1px solid #D9D9D9",
                          },
                        }}
                        sx={{
                          mb: { xs: "5px", sm: "0px" },
                          "& .MuiInputBase-input": {
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Montserrat",
                            color: shippingFormData.country
                              ? "black"
                              : "rgba(0, 0, 0, 0.5)", // Placeholder color effect
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Country
                        </MenuItem>

                        {/* Country List */}
                        {countries.map((option) => (
                          <MenuItem key={option.id} value={option.shortname}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Grid
                        container
                        display={"flex "}
                        justifyContent={"space-between"}
                        pt={"5px"}
                      >
                        <Grid
                          items
                          xs={12}
                          sm={5.9}
                          ml={"5px"}
                          marginRight={{ xs: "5px", sm: "0px" }}
                        >
                          <TextField
                            name="firstName"
                            value={shippingFormData.firstName}
                            onChange={handleShippingChange}
                            placeholder="First name"
                            variant="outlined"
                            fullWidth
                            sx={{
                              mb: { xs: "10px", sm: "0px" },
                              "& .MuiInputBase-root": {
                                height: 40,
                              },
                              "& .MuiInputBase-input::placeholder": {
                                fontSize: "14px",
                                fontFamily: "Montserrat",
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px white inset !important", // Removes autofill background
                                backgroundColor: "transparent !important",
                              },
                              "& .MuiInputBase-input": {
                                padding: "12px 14px", // Decreased padding
                              },
                            }}
                          />
                        </Grid>
                        <Grid
                          items
                          xs={12}
                          sm={5.8}
                          mr={"5px"}
                          marginLeft={{ xs: "5px", sm: "0px" }}
                        >
                          <TextField
                            name="lastName"
                            value={shippingFormData.lastName}
                            onChange={handleShippingChange}
                            placeholder="Last name"
                            variant="outlined"
                            fullWidth
                            sx={{
                              mb: { xs: "5px", sm: "0px" },
                              "& .MuiInputBase-root": {
                                height: 40,
                              },
                              "& .MuiInputBase-input::placeholder": {
                                fontSize: "14px",
                                fontFamily: "Montserrat",
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px white inset !important", // Removes autofill background
                                backgroundColor: "transparent !important",
                              },
                              "& .MuiInputBase-input": {
                                padding: "12px 14px", // Decreased padding
                              },
                            }}
                          />
                        </Grid>
                        <Grid
                          items
                          xs={12}
                          sm={12}
                          pt={"5px"}
                          mr={"6px"}
                          ml={"6px"}
                        >
                          <TextField
                            name="address"
                            value={shippingFormData.address}
                            onChange={handleShippingChange}
                            placeholder="Address"
                            type="text"
                            variant="outlined"
                            fullWidth
                            sx={{
                              mb: { xs: "5px", sm: "0px" },
                              "& .MuiInputBase-root": {
                                height: 40,
                              },
                              "& .MuiInputBase-input::placeholder": {
                                fontSize: "14px",
                                fontFamily: "Montserrat",
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px white inset !important", // Removes autofill background
                                backgroundColor: "transparent !important",
                              },
                              "& .MuiInputBase-input": {
                                padding: "12px 14px", // Decreased padding
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        display={"flex "}
                        justifyContent={"space-between"}
                        py={"5px"}
                      >
                        <Grid
                          items
                          xs={12}
                          sm={3.9}
                          ml={"5px"}
                          marginRight={{ xs: "5px", sm: "0px" }}
                        >
                          <TextField
                            name="city"
                            value={shippingFormData.city}
                            onChange={handleShippingChange}
                            placeholder="City"
                            variant="outlined"
                            fullWidth
                            sx={{
                              mb: { xs: "10px", sm: "0px" },
                              "& .MuiInputBase-root": {
                                height: 40,
                              },
                              "& .MuiInputBase-input::placeholder": {
                                fontSize: "14px",
                                fontFamily: "Montserrat",
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px white inset !important", // Removes autofill background
                                backgroundColor: "transparent !important",
                              },
                              "& .MuiInputBase-input": {
                                padding: "12px 14px", // Decreased padding
                              },
                            }}
                          />
                        </Grid>

                        <Grid
                          items
                          xs={12}
                          sm={4}
                          mx={{ xs: "5px", sm: "0px" }}
                        >
                          <TextField
                            select
                            name="state"
                            value={shippingFormData.state}
                            onChange={handleShippingChange}
                            fullWidth
                            variant="outlined"
                            SelectProps={{
                              displayEmpty: true,
                              IconComponent: KeyboardArrowDownIcon, // Custom arrow
                              sx: {
                                height: "40px",

                                "& .MuiSelect-icon": {
                                  color: "rgba(0, 0, 0, 0.6)", // Arrow color
                                  fontSize: "24px", // Arrow size
                                  fontWeight: "medium",
                                },
                                "& input:-webkit-autofill": {
                                  WebkitBoxShadow:
                                    "0 0 0 1000px white inset !important", // Removes autofill background
                                  backgroundColor: "transparent !important",
                                },
                                "& .MuiInputBase-input": {
                                  padding: "12px 14px", // Decreased padding
                                },
                              },
                            }}
                            InputProps={{
                              sx: {
                                borderRadius: "8px",
                                height: "40px",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Montserrat",
                                border: "1px solid #D9D9D9",
                              },
                            }}
                            sx={{
                              mb: { xs: "10px", sm: "0px" },
                              "& .MuiInputBase-input": {
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Montserrat",
                                color: shippingFormData.state
                                  ? "black"
                                  : "rgba(0, 0, 0, 0.5)", // Placeholder effect
                              },
                            }}
                          >
                            <MenuItem value="" disabled>
                              Select State
                            </MenuItem>

                            {shippingStates.length > 0 ? (
                              shippingStates.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No states available</MenuItem>
                            )}
                          </TextField>
                        </Grid>

                        <Grid
                          items
                          xs={12}
                          sm={3.7}
                          mr={"5px"}
                          ml={{ xs: "5px", sm: "0px" }}
                        >
                          <TextField
                            name="zipCode"
                            value={shippingFormData.zipCode}
                            onChange={handleShippingChange}
                            placeholder="Zip code"
                            variant="outlined"
                            fullWidth
                            sx={{
                              mb: { xs: "5px", sm: "0px" },
                              "& .MuiInputBase-root": {
                                height: 40,
                              },
                              "& .MuiInputBase-input::placeholder": {
                                fontSize: "14px",
                                fontFamily: "Montserrat",
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px white inset !important", // Removes autofill background
                                backgroundColor: "transparent !important",
                              },
                              "& .MuiInputBase-input": {
                                padding: "12px 14px", // Decreased padding
                              },
                            }}
                          />
                        </Grid>
                        {/* <Grid
                          items
                          xs={12}
                          sm={12}
                          pt={"5px"}
                          ml={"6px"}
                          mr={"6px"}
                          mb={"5px"}
                        >
                         <PhoneInput
                     country={"us"}
                      name="phone"
                      value={shippingFormData.phone}
                      onChange={(phone) => {
                        setShippingFormData(prev => ({
                          ...prev,
                          phone: phone
                        }));
                      }}
                      placeholder="Phone"
                      variant="outlined"
                      inputProps={{
                        ref: phoneRef, // Add ref here
                        name: "phone",
                      }}
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: 40,
                        },
                        "& .MuiInputBase-input": {
                          padding: "12px 14px", // Decreased padding
                        },
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow:
                            "0 0 0 1000px white inset !important", // Removes autofill background
                          backgroundColor: "transparent !important",
                        },
                      }}
                      containerStyle={{ width: "100%" }} // Makes the entire component full width
                      inputStyle={{
                        width: "100%", // Makes the input field full width
                        height: "56px", // Matches MUI TextField height
                        borderRadius: "4px", // Matches MUI border-radius
                        border: "1px solid rgba(0, 0, 0, 0.23)", // Matches MUI default border color
                        paddingLeft: "48px", // Ensures flag doesn't overlap input text
                        fontSize: "16px",
                      }}
                    />
                        </Grid> */}
                      </Grid>
                    </>
                  )}
                </Box>
                {error && (
                  <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                    {error}
                  </Typography>
                )}
                <CheckoutForm
                  selectedPrice={selectedPrice}
                  formData={formData}
                  userId={userId}
                  totalPrice={TotalPrice}
                  shippingData={rates[0]}
                  validateForm={validateForm}
                  shippingFormData={shippingFormData} // Add this new prop
                  useBillingAsShipping={useBillingAsShipping}
                />
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontFamily: "Montserrat",
                    pt: "5px",
                    pb: "20px",
                  }}
                >
                  Your info will be saved to a shop account. By continuing, you
                  agree to shop's{" "}
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "11px",
                      fontFamily: "Montserrat",
                      pt: "5px",
                      color: "#2B56F5",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpen(true)}
                  >
                    Terms of service
                  </Typography>{" "}
                  and acknowledge the{" "}
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "11px",
                      fontFamily: "Montserrat",
                      pt: "5px",
                      color: "#2B56F5",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setPrivacyModal(true)}
                  >
                    PrivacyPolicy
                  </Typography>
                </Typography>
              </Box>
            </>
          </Grid>
          <Grid items xs={12} sm={6} backgroundColor={"#F2F2F2"}>
            <Grid
              container
              sx={{
                py: { xs: "35px", sm: "60px" },
                px: { xs: "20px", sm: "50px" },
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2} alignItems="flex-start">
                {/* Column 1 - Image */}
                <Grid item xs={2} sm={2} md={2}>
                  <Box
                    component="img"
                    src={uploadimage}
                    width={{ xs: "40px", sm: "60px", xl: "70px" }}
                  />
                </Grid>

                {/* Column 2 - GeniePlate, Dropdown, Place Name */}
                <Grid item xs={8} sm={6} md={6}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          fontSize: { xs: "14px", sm: "18px" },
                        }}
                      >
                        Genie Plate
                      </Typography>
                      <Select
                        value={selectedPlate}
                        onChange={handlePlateChange}
                        displayEmpty
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: { xs: "12px", sm: "16px" },
                          width: { xs: "100px", sm: "120px" },
                          height: "32px",
                        }}
                      >
                        {plateOptions.map((option) => (
                          <MenuItem key={option.name} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "grey",
                        fontWeight: "500",
                        fontSize: { xs: "12px", sm: "16px", xl: "14px" },
                        mt: 1, // Add margin to separate from the above row
                      }}
                    >
                      {placeName} {fullPlaceName}
                    </Typography>
                  </Box>
                </Grid>

                {/* Column 3 - Retail Price & Price */}
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: { xs: "14px", sm: "18px" },
                      pt: 0.5, // Ensures alignment with Genie Plate text
                      color: "gray",
                    }}
                  >
                    <del>${selectedreatilPrice}</del>
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: { xs: "16px", sm: "18px" },
                    }}
                  >
                    ${selectedPrice}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                pb: { xs: "30px", sm: "60px" },
                px: { xs: "20px", sm: "50px" },
              }}
            >
              <Grid items xs={12} sm={10}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  pb={{ xs: "4px", sm: "15px" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: { xs: "16px", sm: "18px" },
                      fontWeight: "500",
                    }}
                  >
                    Sub total
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: { xs: "16px", sm: "18px" },
                      fontWeight: "500",
                    }}
                  >
                    ${selectedPrice}
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"start"}
                  flexDirection={"column"}
                  pb={{ xs: "6px", sm: "20px" }}
                >
                  {/* Top Row: Shipping Label & Price/Input */}
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Typography
                      sx={{
                        fontFamily: "inter",
                        fontSize: { xs: "16px", sm: "18px" },
                        fontWeight: "500",
                      }}
                    >
                      Shipping
                    </Typography>
                    {rates[0]?.total_charge ? (
                      <Typography
                        sx={{
                          fontFamily: "inter",
                          fontSize: { xs: "16px", sm: "18px" },
                          fontWeight: "500",
                        }}
                      >
                        ${rates[0]?.total_charge}
                      </Typography>
                    ) : (
                      <TextField
                        placeholder="Enter Shipping address"
                        variant="outlined"
                        border="0"
                        sx={{
                          width: { xs: "50%", sm: "auto" },
                          border: "none",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { border: "none" },
                          },
                          "& .MuiInputBase-input": {
                            padding: "0px", // Decreased padding
                          },
                          "& .MuiInputBase-root": { height: 45 },
                          "& .MuiInputBase-input::placeholder": {
                            fontSize: { xs: "10px", sm: "16px", xl: "14px" },
                            fontFamily: "Montserrat",
                            fontWeight: "600",
                            color: "black",
                            textAlign: { xs: "end", sm: "start" },
                          },
                        }}
                      />
                    )}
                  </Box>

                  {/* Below Shipping: Description */}
                  {rates?.length > 0 && rates[0]?.full_description && (
                    <Typography
                      sx={{
                        fontFamily: "inter",
                        fontSize: { xs: "12px", sm: "15px", xl: "15px" },
                        color: "gray",
                        mt: 1, // Margin top to separate from the main row
                        width: "60%",
                      }}
                    >
                      {rates[0]?.full_description}
                    </Typography>
                  )}
                </Box>

                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: { xs: "16px", sm: "20px" },
                      fontWeight: "600",
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "500",
                      color: "grey",
                    }}
                  >
                    {" "}
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "inter",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      ${TotalPrice}
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TermsModal open={open} handleClose={() => setOpen(false)} />
        <PrivacyPolicy
          open={privacyModal}
          handleModalClose={() => setPrivacyModal(false)}
        />
      </Box>
    </Elements>
  );
}

export default Checkout;
