import React, { useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { TextField } from "@mui/material";

const AutoCompleteInput = ({ setSelectedAddress }) => {
  const autoCompleteRef = useRef(null);

  const onLoad = (autocomplete) => {
    autoCompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autoCompleteRef.current !== null) {
      const place = autoCompleteRef.current.getPlace();
      if (place.formatted_address) {
        setSelectedAddress(place.formatted_address);
      }
    }
  };

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <TextField
        fullWidth
        variant="outlined"
        // placeholder="Search for an address"
        label="Search for an address"
        sx={{
          borderRadius: "10px",
          fontWeight: 500,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: "10px",
            },
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
          "& .MuiInputLabel-root": {
            fontWeight: "bold",
            top: "-6px",
            fontsize: "14px",
            fontFamily: "Goldplay",
          },
        }}
      />
    </Autocomplete>
  );
};

export default AutoCompleteInput;
