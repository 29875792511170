import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCredential, GoogleAuthProvider,RecaptchaVerifier,signInWithPhoneNumber } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDhZm7zk0D_oRPzd392fzKzAyndMcPbpeo",
    authDomain: "cedar-channel-293812.firebaseapp.com",
    databaseURL: "https://cedar-channel-293812.firebaseio.com",
    projectId: "cedar-channel-293812",
    storageBucket: "cedar-channel-293812.appspot.com",
    messagingSenderId: "415317090534",
    appId: "1:415317090534:web:52c4981717c40174da1b27",
    measurementId: "G-8GJG9E2G6L"
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { auth,analytics, signInWithCredential, GoogleAuthProvider,RecaptchaVerifier,signInWithPhoneNumber };
