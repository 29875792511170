import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  Dashboard as DashboardIcon,
  Restaurant as PlatesIcon,
  ShoppingCart as OrdersIcon,
  Menu as MenuIcon,
  Logout as LogoutIcon,
  AccountCircle as ProfileIcon,
  ShoppingBag as ShopNowIcon,
} from "@mui/icons-material";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { BusinessContext } from "../BusinessContext";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/FirebaseConfig";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axios";
// Styled Components
const StyledSelect = styled(Select)({
  borderRadius: "8px",
  border: "2px solid white",
  padding: "4px 8px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: "12px",
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    padding: "4px 8px",
  },
  "& fieldset": {
    border: "none",
  },
});

const StyledMenuItem = styled(MenuItem)({
  fontSize: "16px",
  fontFamily: "Nunito",
});

const menuItems = [
  {
    id: 1,
    text: "Dashboard",
    icon: <DashboardIcon />,
    url: "/dashboard/dashboard",
    color: "#2196f3",
  },
  {
    id: 2,
    text: "Menu",
    icon: <MenuIcon />,
    url: "/dashboard/menu",
    color: "#9c27b0",
  },
 
  {
    id: 3,
    text: "Orders",
    icon: <OrdersIcon />,
    url: "/dashboard/orders",
    color: "#ff9800",
  },
  {
    id: 4,
    text: "Plates",
    icon: <PlatesIcon />,
    url: "/dashboard/plates",
    color: "#4caf50",
  },

  {
    id: 5,
    text: "Social Networks",
    icon: <RateReviewIcon />,
    url: "/dashboard/sociallinks",
    color: "#4caf50",
  },
  {
    id: 6,
    text: "Shop Now",
    icon: <ShopNowIcon />,
    // url: "/dashboard/Shop Now",
    color: "#f10cd9",
  },
 
  {
    id: 7,
    text: "Profile",
    icon: <ProfileIcon />,
    url: "/dashboard/profile",
    color: "#ffffff ",
  },
  {
    id: 8,
    text: "Logout",
    icon: <LogoutIcon />,
    color: "#f44336",
  },
];

const Sidebar = ({ selectedItem, onMenuItemClick }) => {
  const { businesses, selectedBusinessId, updateBusinessId, isLoading } =
    useContext(BusinessContext);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [userdetails,setUserDetails]=useState([]);
  const [username, setUserName] = useState(""); // Ensure state is initialized

  const handleLogout = async () => {
    try {
      console.log("Logging out...");
      await signOut(auth);
      console.log("Sign out successful!");

      // Clear all auth-related data
      localStorage.removeItem("idToken");
      localStorage.removeItem("firebaseUser");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("username");
      localStorage.removeItem("PhoneNumber");
      localStorage.removeItem("plateCode");
      localStorage.removeItem("firstName");
      localStorage.removeItem("businessId");
      localStorage.removeItem("googleToken");
      localStorage.removeItem("lastName");
      localStorage.removeItem("BusinessName");
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleItemClick = (item) => {
    if (item.id === 8) {
      handleLogout();
    } else if (item.id === 6){
      const currentUrl = window.location.origin;
      window.open(`${currentUrl}#products-section`, "_blank");
    }
    else {
      onMenuItemClick(item.id);
      if (item.url) {
        navigate(item.url);
      }
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axiosInstance.post("user.php?method=get_user_details");
  
        console.log("Full API Response:", response.data);
  
        if (response.data?.user?.name) {
          console.log("User Name from API:", response.data.user.name);
          const firstName = response.data.user.name.split(" ")[0];
  
          setUserName(firstName); // Update state
        } else {
          console.error("Name field is missing or empty.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
  
    fetchUserDetails();
  }, []);
  
  
  
  
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        backgroundColor: "#1a237e",
        color: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ px: 2, pt: 1 }}>
        <Typography sx={{ fontSize: "23px" }}>
          Welcome,{" "}
          <span style={{ color: "green", fontWeight: "bold" }}>
          {username}
          </span>
        </Typography>
      </Box>
      <Box sx={{ px: "8px", pt: "13px" }}>
        <FormControl sx={{ width: "100%" }}>
          <StyledSelect
            value={selectedBusinessId}
            onChange={(e) => updateBusinessId(e.target.value)}
            displayEmpty
            sx={{ color: "white", "& .MuiSvgIcon-root": { color: "white" } }}
            renderValue={() => {
              const selectedBusiness = businesses.find(
                (b) => b.id === selectedBusinessId
              );
              return (
                <Box display="flex" alignItems="center" gap={1} color="white">
                  {selectedBusiness
                    ? (() => {
                        const words = selectedBusiness.name.split(" ");
                        const displayedText =
                          words.length > 3
                            ? words.slice(0, 3).join(" ") + "..."
                            : selectedBusiness.name;
                        return displayedText;
                      })()
                    : "Select Business"}
                </Box>
              );
            }}
          >
            {isLoading ? (
              <StyledMenuItem disabled>Loading businesses...</StyledMenuItem>
            ) : businesses.length > 0 ? (
              businesses.map((business,index) => {
                const words = business.name.split(" ").filter((word) => word);
                const initials =
                  words.length > 1 ? words[0][0] + words[1][0] : words[0][0];

                return (
                  <StyledMenuItem
                    key={business.id}
                    value={business.id}
                    sx={{
                      borderBottom: index === businesses?.length - 1 ? "none" : "1px solid #ccc",
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          backgroundColor: "#1976d2",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "12px",
                        }}
                      >
                        {initials}
                      </Box>
                      {business.name}
                    </Box>
                  </StyledMenuItem>
                );
              })
            ) : (
              <StyledMenuItem disabled>No businesses available</StyledMenuItem>
            )}
          </StyledSelect>
        </FormControl>
      </Box>
      {/* 
      <Box sx={{ px: 2, pb: 1, pt: 3 }}>
        <Typography variant="h6" sx={{ color: "white" }}>
          Dashboard
        </Typography>
      </Box> */}

      <List sx={{ flexGrow: 1 }}>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.id}
            onClick={() => handleItemClick(item)}
            sx={{
              backgroundColor:
                selectedItem === item.text
                  ? "rgba(255, 255, 255, 0.1)"
                  : "transparent",
              "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
              borderRadius: "8px",
              margin: "4px 8px",
              cursor:"pointer"
            }}
          
          >
            <ListItemIcon sx={{ color: item.color }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Sidebar;
