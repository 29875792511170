import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams} from "react-router-dom";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import { GoogleLogin } from "@react-oauth/google";
import {
  auth,
  signInWithCredential,
  GoogleAuthProvider,
} from "../firebase/FirebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { BusinessContext } from "./BusinessContext";
import axiosInstance from "./axios";
import { CircularProgress } from "@mui/material";
import bluelogo from "../images/bluelogo.png";


const StyledTextField = styled(TextField)({
  marginBottom: "10px", // <-- Add your desired bottom margin here
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",

    "& fieldset": {
      border: "1px solid #BDBDBD",
      // fixed typo "soild" to "solid"
    },
    "&:hover fieldset": {
      borderColor: "#BDBDBD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#BDBDBD",
    },
    "& input::placeholder": {
      color: "#949494",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "500",
      opacity: 1,
    },
  },
});

function SignUp() {
  const navigate = useNavigate();
  const [userDetails, getUserDetails] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const { fetchBusinesses } = useContext(BusinessContext);
  const { code } = useParams();
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[0-9]{10}$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

  useEffect(() => {
    const idToken = localStorage.getItem("idToken");
    if (idToken && !code) {
      // Only redirect if there's no registration code
      navigate("/dashboard");
    }
  }, [navigate, code]);

  useEffect(() => {
    localStorage.removeItem("googleToken");
    localStorage.removeItem("firebaseUser");
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.post(
        "payments.php?method=fetch_business",
        { plate_code: code }
      );
      console.log("response", response);
    } catch (err) {
      console.error("Failed to load data.");
    }
  };

  useEffect(() => {
    const fetchAndUpdateUser = async () => {
      const userData = await GetUserDetails(); // Fetch user details first
      if (userData && Object.keys(userData).length > 0 && code) {
        console.log("Updating user details with registration_code:", code);
        await UpdateUserDetails(userData);
      } else {
        console.warn(
          "User details not available or registration code is missing."
        );
      }
    };
    fetchAndUpdateUser();
  }, []);

  const responseMessage = async (response) => {
    console.log("Google Response:", response);

    if (response.credential) {
      setGoogleLoading(true);
      const token = response.credential;

      try {
        // Create a Google credential with the token
        const credential = GoogleAuthProvider.credential(token);

        // Sign in to Firebase with the credential
        const userCredential = await signInWithCredential(auth, credential);
        const user = userCredential.user;

        console.log("Firebase User:", user);

        const idToken = await user.getIdToken(); // Get ID token for API requests

        // Store user details in localStorage
        localStorage.setItem("firebaseUser", JSON.stringify(user));
        localStorage.setItem("googleToken", token);
        localStorage.setItem("idToken", idToken);
        localStorage.setItem("userEmail", user.email);
        localStorage.setItem("firstName", user.displayName?.split(" ")[0]);
        localStorage.setItem("lastName", user.displayName?.split(" ")[1]);
        // Navigate to Register page after successful login
        await UpdateToken(idToken);
        const userData = await GetUserDetails();
        if (userData && Object.keys(userData).length > 0) {
          await UpdateUserDetails(userData); // Pass the latest userData
        } else {
          console.error("User data is empty or invalid:", userData);
        }
        fetchBusinesses();
        fetchData();
        setGoogleLoading(false);
        navigate(`/plates/${code}`);
      } catch (error) {
        console.error("Firebase Authentication Error:", error.message);
      }
    } else {
      console.error("No token received from Google.");
    }
  };

  const errorMessage = (error) => {
    console.log("Google Login Error:", error);
  };

  const GetUserDetails = async () => {
    try {
      const response = await axiosInstance.post(
        "user.php?method=get_user_details"
      );

      if (response.data.user) {
        console.log("Fetched User Data:", response.data.user);

        // Ensure state updates before returning the data
        getUserDetails(response.data.user); // Assuming this updates state

        return response.data.user;
      } else {
        console.warn("No user details received from API.");
        return null;
      }
    } catch (error) {
      console.log("Error getting user details", error);
    }
  };

  const UpdateUserDetails = async (updatedUserDetails) => {
    try {
      console.log("Updating User Details with:", updatedUserDetails, code);

      const userdetails = {
        name: updatedUserDetails?.name || "Default Name",
        about: updatedUserDetails?.about || "Default about text",
        date_of_birth: updatedUserDetails?.date_of_birth,
        gender: updatedUserDetails?.gender,
        phone_number: updatedUserDetails?.phone_number,
        registration_code: code || "default_code",
      };

      const response = await axiosInstance.post(
        "user.php?method=update_user_details",
        userdetails
      );

      console.log("User details updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length <= 3) {
      return cleaned;
    } else if (cleaned.length <= 6) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    } else {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(
        6,
        10
      )}`;
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone_number") {
      const formattedValue = formatPhoneNumber(value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const UpdateDetails = async (userDetails) => {
    try {
      const response = axiosInstance.post(
        "user.php?method=update_user_details",
        userDetails
      );
      console.log("user details Updated Suucessfully", response);
    } catch (error) {
      console.log("Error Updating user details", error);
    }
  };

  const UpdateToken = async (deviceToken) => {
    try {
      const response = await axiosInstance.post(
        "user.php?method=update_device_token",
        { device_token: deviceToken } // ✅ Send device_token in request body
      );
      console.log("Token Updated:", response.data);
    } catch (error) {
      console.error(
        "Update Token Failed:",
        error.response?.data || error.message
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    const { name, email, phone_number, password, confirmPassword } = formData;

    if (!emailRegex.test(email)) {
      setError("Give valid email ");
      setLoading(false);
      return;
    }

    if (!phoneRegex.test(phone_number.replace(/-/g, ""))) {
      setError("Phone number must be 10 digits");
      setLoading(false);
      return;
    }

    // ✅ Password Validation
    if (!passwordRegex.test(password)) {
      setError(
        "Password must be at least 6 characters, include 1 uppercase, 1 lowercase, 1 special character and 1 number."
      );
      setLoading(false);
      return;
    }

    // ✅ Confirm Password Match
    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      setLoading(false);
      return;
    }

    try {
      // Firebase Registration
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      console.log("Registered User:", user);

      const idToken = await user.getIdToken(true);
      localStorage.clear();
      // Store user details locally
      localStorage.setItem("firebaseUser", JSON.stringify(user));
      localStorage.setItem("userEmail", user.email);
      localStorage.setItem("username", name);
      localStorage.setItem("PhoneNumber", phone_number);
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("plateCode", code);

      await UpdateToken();

      const userDetails = {
        name: name,
        about: "New User",
        date_of_birth: "2000-02-01",
        gender: "male",
        phone_number: phone_number,
        registration_code: code,
      };

      await UpdateDetails(userDetails);

      fetchBusinesses();
      fetchData();
      navigate(`/plates/${code}`);
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        setError("User is already registered! Please login.");
      } else {
        setError(err.message);
      }
      console.error("Registration Error:", err);
    } finally {
      setLoading(false); // Hide loader after process
    }
  };

  return (
    <Box sx={{ pt: "50px", px: { xs: "18px", sm: "0px" } }}>
      <Container maxWidth="md" sx={{ padding: 0 }}>
        <Box sx={{ textAlign: "center" }}>
          <Box
            component="img"
            src={bluelogo}
            sx={{
              cursor: "pointer",
              width: { xs: "55%", sm: "192.99px" },
              height: { xs: "", sm: "43px" },
            }}
          />
        </Box>

        <Box
          sx={{
            py: { xs: "30px", sm: "40px" },
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontWeight: "600",
              mb: 0,
              fontSize: { xs: "16px", sm: "21px" },
              fontFamily: "Roboto",
            }}
          >
            Sign Up
          </Typography>
          <Typography
            component={"span"}
            sx={{
              textAlign: "center",
              fontWeight: "600",
              mb: 0,
              fontSize: { xs: "14px", sm: "16px" },
              fontFamily: "Roboto",
              color: "#00000080",
            }}
          >
            {" "}
            To start setting up your new plate
          </Typography>
        </Box>
        {/* Flex container for form and button */}
        <Box sx={{ display: { sm: "flex" }, alignItems: { sm: "center" } }}>
          {/* Left side - Form */}
          <Box sx={{ flex: 1 }}>
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <form onSubmit={handleSubmit}>
              <StyledTextField
                fullWidth
                // label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                required
                placeholder="Name"
              />
              <StyledTextField
                fullWidth
                type="email"
                // label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                required
                placeholder="Email "
              />
              <StyledTextField
                fullWidth
                type="tel"
                // label="Phone"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                variant="outlined"
                required
                placeholder="Phone"
              />
              <StyledTextField
                fullWidth
                type="password"
                // label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                variant="outlined"
                required
                placeholder="Password "
              />
              <StyledTextField
                fullWidth
                type="password"
                // label="Confirm Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                variant="outlined"
                required
                placeholder="Confirm Password "
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  marginTop: 4,
                  backgroundColor: "#0041A8",
                  color: "white",
                  borderRadius: "58px",
                  py: "18px",
                  fontSize: "15px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  lineHeight: "16px",
                  textTransform: "capitalize",
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Sign Up"
                )}
              </Button>
            </form>
          </Box>

          <Box
            sx={{
              flexDirection: "column",
              alignItems: "center",
              mx: 2,
              position: "relative",
              mx: "25px",
              display: { xs: "none", sm: "flex" },
            }}
          >
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: "210px",
                mb: "0px",
              }}
            />

            <Typography
              sx={{
                margin: { xs: "10px 0px 0px 0px", sm: "5px 0px 5px 0px " },
                fontSize: "16px",
                fontWeight: "bold",
                position: { sm: "absolute" },
                top: "47%",
              }}
            >
              or
            </Typography>

            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: "200px",
                mt: "20px",
              }}
            />
          </Box>

          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              alignItems: "center",
              justifyContent: "center",
              my: 3,
            }}
          >
            <Divider sx={{ flex: 0.1, borderColor: "black" }} />
            <Typography
              sx={{
                mx: 0.4,
                color: "#222B45",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "Roboto",
              }}
            >
              Or
            </Typography>
            <Divider sx={{ flex: 0.1, borderColor: "black" }} />
          </Box>

          {/* Right side - Google Sign In */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{ width: "100%", borderRadius: "11px", textAlign: "center" }}
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "#fff",
                  fontSize: "15px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                }}
                disabled={googleLoading}
                onClick={() =>
                  document.getElementById("googleSignInButton").click()
                }
              >
                {googleLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <GoogleLogin
                      onSuccess={responseMessage}
                      onError={errorMessage}
                      width="100%"
                      id="googleSignInButton"
                      style={{ display: "none" }}
                    />
                  </>
                )}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ textAlign: "center", my: 3 }}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Roboto",
              letterSpacing: "-0.5px",
            }}
          >
            Already have an account?{" "}
            <Typography
              component={"span"}
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                fontFamily: "Roboto",
                letterSpacing: "-0.5px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/login")}
            >
              Sign In
            </Typography>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default SignUp;