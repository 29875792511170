import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../firebase/FirebaseConfig"; // Firebase setup
import { confirmPasswordReset } from "firebase/auth";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Paper,
} from "@mui/material";
import applogo from "../images/bluelogo.png";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get("oobCode"); // Firebase reset token

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("red"); // Default error color
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async () => {
    if (!password || !confirmPassword) {
      setMessage("Please enter a new password.");
      setMessageColor("red");
      return;
    }

    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      setMessageColor("red");
      return;
    }

    setLoading(true);
    try {
      await confirmPasswordReset(auth, oobCode, password); // Reset password
      setMessage("Password reset successful! Redirecting to login...");
      setMessageColor("green");

      setTimeout(() => navigate("/login"), 1000); // Navigate to login after 2 seconds
    } catch (error) {
      setMessage("Error resetting password. Try again.");
      setMessageColor("red");
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: 4,
          width: "100%",
          maxWidth: 400,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Logo Section */}
        <Box sx={{ mb: 2 }}>
          <img
            src={applogo}
            alt="Logo"
            style={{
              width: "150px",
              height: "auto",
              display: "block",
              margin: "0 auto",
            }}
          />
        </Box>

        <Typography variant="h5" fontWeight="bold">
          Reset Password
        </Typography>
        <TextField
          fullWidth
          type="password"
          label="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          fullWidth
          type="password"
          label="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{ mt: 2 }}
        />

        {message && (
          <Typography sx={{ color: messageColor, mt: 2 }}>{message}</Typography>
        )}

        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 3 }}
          onClick={handleResetPassword}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Reset Password"}
        </Button>
      </Paper>
    </Box>
  );
};

export default ResetPassword;
