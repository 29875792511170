import React,{useEffect} from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "../src/components/AppRouter"; // Adjust the path if needed
import { BusinessProvider } from "./components/BusinessContext";

function App() {

  return (
   <BusinessProvider>
     <BrowserRouter>
      <AppRouter />
    </BrowserRouter> 
   </BusinessProvider>
  );
}

export default App;
