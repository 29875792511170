import React, { useState } from "react";
import {
  Box,
  styled,
  Typography,
  Grid,
  Container,
  Button,
  Accordion, AccordionSummary, AccordionDetails,
  IconButton
} from "@mui/material";
import plussymbol from "../images/plussymbol.png";
import StarIcon from '@mui/icons-material/Star';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BuildIcon from '@mui/icons-material/Build';
import ConstructionIcon from '@mui/icons-material/Construction';
import CachedIcon from '@mui/icons-material/Cached';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

function Sectionfive() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Grid container>
      <Grid item sm={2.5}>
        {" "}
      </Grid>
      <Grid item xs={12} sm={7} py={{ xs: 5, sm: 8 }}>
        
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            pb: { xs: "20px", sm: "30px" },
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "28px", sm: "40px" },
              fontWeight: "bold",
              lineHeight: { xs: "38px", sm: "46.3px" },
              letterSpacing: "0.77px",
              py: "35px",
            }}
          >
            Frequently Asked Questions{" "}
          </Typography>
          <Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff",  // Add background color to match Box
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>


<AccordionSummary
  expandIcon={
    <ExpandMoreIcon />}
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
    minHeight: "unset",
   
    "& .MuiAccordionSummary-content": {
      margin: 0,
      display: "flex",
      alignItems: "center",
    }, "& .MuiAccordionSummary-expandIconWrapper": {
      marginRight: "15px",   
    },
    gap: "3px",
  }}
>
  <Typography
    sx={{
      fontFamily: "Montserrat",
      fontSize: { xs: "12px", sm: "16px" },
      fontWeight: "medium",
      lineHeight: { xs: "18px", sm: "14.4px" },
      letterSpacing: "0.5px",
      py: "16px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    }}
  >
    {/* <IconButton size="small" sx={{ p: 0, color: "yellow" }}> */}
      <StarIcon  sx={{color: "yellow"}}/>
    {/* </IconButton> */}
    Do I need a Google My Business local listing?
  </Typography>
</AccordionSummary>


  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px", color:"#686767"
      }}
    >
     Yes, your business must have a Google My Business listing to receive Google reviews. If you don’t have one, our team can help you create it.
    </Typography>
  

  </AccordionDetails>
</Accordion>
<Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff", 
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>
  <AccordionSummary
      expandIcon={
        <ExpandMoreIcon />}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 0,
        minHeight: "unset",
       
        "& .MuiAccordionSummary-content": {
          margin: 0,
          display: "flex",
          alignItems: "center",
        }, "& .MuiAccordionSummary-expandIconWrapper": {
          marginRight: "15px",   
        },
        gap: "3px",
      }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "16px" },
        fontWeight: "medium",
        lineHeight: { xs: "18px", sm: "14.4px" },
        letterSpacing: "0.5px",
        py: "16px",
        display: "flex",
        alignItems: "center",
        gap: "8px",  
      }}
    >
       {/* <IconButton size="small" sx={{ p: 0 }}> */}
      <RemoveRedEyeIcon/>
    {/* </IconButton> */}
     Do my customers need a Google account to write a review?
    </Typography>
  </AccordionSummary>

  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px", color:"#686767"
      }}
    >
    Yes, customers must have a Google account to leave a review. However, most smartphone users already have one through YouTube, Gmail, or Google Maps—93% of smartphone owners, in fact.
    </Typography>

  </AccordionDetails>
</Accordion>
<Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff",  // Add background color to match Box
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>
  <AccordionSummary
     expandIcon={
      <ExpandMoreIcon />}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
      minHeight: "unset",
     
      "& .MuiAccordionSummary-content": {
        margin: 0,
        display: "flex",
        alignItems: "center",
      }, "& .MuiAccordionSummary-expandIconWrapper": {
        marginRight: "15px",   
      },
      gap: "3px",
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "16px" },
        fontWeight: "medium",
        lineHeight: { xs: "18px", sm: "14.4px" },
        letterSpacing: "0.5px",
        py: "16px",
        display: "flex",
        alignItems: "center",
        gap: "8px",  
      }}
    >
       {/* <IconButton size="small" sx={{ p: 0 }}> */}
      <PhoneIphoneIcon sx={{color:"blue"}}/>
    {/* </IconButton> */}
     Are the NFC plates compatible with all smartphones?
    </Typography>
  </AccordionSummary>

  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px", color:"#686767"
      }}
    >
       Yes! 90% of smartphones have built-in NFC technology, and 100% of smartphones can scan QR codes, making it easy for all customers to interact.

    </Typography>
  </AccordionDetails>
</Accordion>
<Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff",  // Add background color to match Box
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>
  <AccordionSummary
      expandIcon={
        <ExpandMoreIcon />}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 0,
        minHeight: "unset",
       
        "& .MuiAccordionSummary-content": {
          margin: 0,
          display: "flex",
          alignItems: "center",
        }, "& .MuiAccordionSummary-expandIconWrapper": {
          marginRight: "15px",   
        },
        gap: "3px",
      }}
  >
    <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: { xs: "12px", sm: "16px" },
          fontWeight: "medium",
          lineHeight: { xs: "18px", sm: "14.4px" },
          letterSpacing: "0.5px",
          py: "16px",
          display: "flex",
          alignItems: "center",
          gap: "8px",  
        }}
      >
         {/* <IconButton size="small" sx={{ p: 0 }}> */}
        <SettingsInputAntennaIcon sx={{color:"black"}}/>
      {/* </IconButton> */}
     How does contactless technology work?
    </Typography>
  </AccordionSummary>

  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px", color:"#686767"
      }}
    >
      Our plates use NFC (Near Field Communication), the same technology that powers contactless payments. When customers tap or scan, a link to your business review page instantly opens—no app downloads needed. Our chip only transmits a link and does not collect any personal information.
    </Typography>
  </AccordionDetails>
</Accordion>
<Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff",  // Add background color to match Box
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>
  <AccordionSummary
     expandIcon={
      <ExpandMoreIcon />}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
      minHeight: "unset",
     
      "& .MuiAccordionSummary-content": {
        margin: 0,
        display: "flex",
        alignItems: "center",
      }, "& .MuiAccordionSummary-expandIconWrapper": {
        marginRight: "15px",   
      },
      gap: "3px",
    }}
  >
    <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: "medium",
            lineHeight: { xs: "18px", sm: "14.4px" },
            letterSpacing: "0.5px",
            py: "16px",
            display: "flex",
            alignItems: "center",
            gap: "8px",  
          }}
        >
           {/* <IconButton size="small" sx={{ p: 0 ,color:"orange"}}> */}
          <AirportShuttleIcon />
        {/* </IconButton> */}
     Where do you ship, and how long does delivery take?
    </Typography>
  </AccordionSummary>

  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px", color:"#686767"
      }}
    >
       We currently only ship within the United States. Orders are processed within 1-2 business days, and delivery typically takes 4-7 business days.
    </Typography>
  </AccordionDetails>
</Accordion>
<Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff",  // Add background color to match Box
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>
  <AccordionSummary
     expandIcon={
      <ExpandMoreIcon />}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
      minHeight: "unset",
     
      "& .MuiAccordionSummary-content": {
        margin: 0,
        display: "flex",
        alignItems: "center",
      }, "& .MuiAccordionSummary-expandIconWrapper": {
        marginRight: "15px",   
      },
      gap: "3px",
    }}
  >
    <Typography
           sx={{
            fontFamily: "Montserrat",
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: "medium",
            lineHeight: { xs: "18px", sm: "14.4px" },
            letterSpacing: "0.5px",
            py: "16px",
            display: "flex",
            alignItems: "center",
            gap: "8px",  
          }}
        >
           {/* <IconButton size="small" sx={{ p: 0 }}> */}
          <CreditCardIcon sx={{color:"blue"}}/>
        {/* </IconButton> */}
    Do I need an app or subscription to use PointGenie NFC tags?

    </Typography>
  </AccordionSummary>

  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px",
        color:"#686767", 
      }}
    >
      No app or subscription is required! Our NFC plates work instantly by directing customers to Our proprietary Landing page from where they go to your Google review page when they tap or scan the tag.

    </Typography>
  </AccordionDetails>
</Accordion>
<Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff",  // Add background color to match Box
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>
  <AccordionSummary
     expandIcon={
      <ExpandMoreIcon />}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
      minHeight: "unset",
     
      "& .MuiAccordionSummary-content": {
        margin: 0,
        display: "flex",
        alignItems: "center",
      }, "& .MuiAccordionSummary-expandIconWrapper": {
        marginRight: "15px",   
      },
      gap: "3px",
    }}
  >
    <Typography
           sx={{
            fontFamily: "Montserrat",
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: "medium",
            lineHeight: { xs: "18px", sm: "14.4px" },
            letterSpacing: "0.5px",
            py: "16px",
            display: "flex",
            alignItems: "center",
            gap: "8px",  
          }}
        >
           {/* <IconButton size="small" sx={{ p: 0 }}> */}
          <BuildIcon/>
        {/* </IconButton> */}
     How do I install my NFC plate?
    </Typography>
  </AccordionSummary>

  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px", color:"#686767"
      }}
    >
      Our plates are designed for easy installation. Simply peel and stick to a flat, clean surface like your front desk, tables, or checkout counter. For best results, place them in high-visibility areas.

    </Typography>
  </AccordionDetails>
</Accordion>

<Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff",  // Add background color to match Box
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>
  <AccordionSummary
      expandIcon={
        <ExpandMoreIcon />}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 0,
        minHeight: "unset",
       
        "& .MuiAccordionSummary-content": {
          margin: 0,
          display: "flex",
          alignItems: "center",
        }, "& .MuiAccordionSummary-expandIconWrapper": {
          marginRight: "15px",   
        },
        gap: "3px",
      }}
  >
    <Typography
 sx={{
  fontFamily: "Montserrat",
  fontSize: { xs: "12px", sm: "16px" },
  fontWeight: "medium",
  lineHeight: { xs: "18px", sm: "14.4px" },
  letterSpacing: "0.5px",
  py: "16px",
  display: "flex",
  alignItems: "center",
  gap: "8px",  
}}
>
 {/* <IconButton size="small" sx={{ p: 0 }}> */}
<ConstructionIcon sx={{color:'black'}}/>
{/* </IconButton> */}
    Can I customize the NFC plate with my logo or branding?

    </Typography>
  </AccordionSummary>

  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px", color:"#686767"
      }}
    >
  On Request! We offer custom branding options to match your business identity. Contact us for details on personalized designs At support@pointgenie.com.


    </Typography>
  </AccordionDetails>
</Accordion>
<Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff",  // Add background color to match Box
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>
  <AccordionSummary
     expandIcon={
      <ExpandMoreIcon />}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
      minHeight: "unset",
     
      "& .MuiAccordionSummary-content": {
        margin: 0,
        display: "flex",
        alignItems: "center",
      }, "& .MuiAccordionSummary-expandIconWrapper": {
        marginRight: "15px",   
      },
      gap: "3px",
    }}
  >
    <Typography
 sx={{
  fontFamily: "Montserrat",
  fontSize: { xs: "12px", sm: "16px" },
  fontWeight: "medium",
  lineHeight: { xs: "18px", sm: "14.4px" },
  letterSpacing: "0.5px",
  py: "16px",
  display: "flex",
  alignItems: "center",
  gap: "8px",  
}}
>
 {/* <IconButton size="small" sx={{ p: 0 }}> */}
<CachedIcon sx={{color:"blue"}}/>
{/* </IconButton> */}
   Can I update the link on my NFC plate?


    </Typography>
  </AccordionSummary>

  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px", color:"#686767"
      }}
    >
  Our standard NFC plates are pre-programmed to link to your proprietary landing page from where they can go to google on one tap. However,With the purchase of your tag you get access to your business dashboard from where you can add more links and take advantage of other features like Digital Menus and Specials.


    </Typography>
  </AccordionDetails>
</Accordion>
<Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff",  // Add background color to match Box
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>
  <AccordionSummary
     expandIcon={
      <ExpandMoreIcon />}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
      minHeight: "unset",
     
      "& .MuiAccordionSummary-content": {
        margin: 0,
        display: "flex",
        alignItems: "center",
      }, "& .MuiAccordionSummary-expandIconWrapper": {
        marginRight: "15px",   
      },
      gap: "3px",
    }}
  >
    <Typography
 sx={{
  fontFamily: "Montserrat",
  fontSize: { xs: "12px", sm: "16px" },
  fontWeight: "medium",
  lineHeight: { xs: "18px", sm: "14.4px" },
  letterSpacing: "0.5px",
  py: "16px",
  display: "flex",
  alignItems: "center",
  gap: "8px",  
}}
>
 {/* <IconButton size="small" sx={{ p: 0 }}> */}
<MarkunreadMailboxIcon sx={{color:"yellow"}}/>
{/* </IconButton> */}
   Do you offer bulk pricing for large orders?



    </Typography>
  </AccordionSummary>

  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px", color:"#686767"
      }}
    >
  Yes! If you need multiple NFC plates, we offer discounted bulk pricing. Contact us for a custom quote based on your order size at Support@pointgenie.com.
 </Typography>
  </AccordionDetails>
</Accordion>
<Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff",  // Add background color to match Box
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>
  <AccordionSummary
     expandIcon={
      <ExpandMoreIcon />}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
      minHeight: "unset",
     
      "& .MuiAccordionSummary-content": {
        margin: 0,
        display: "flex",
        alignItems: "center",
      }, "& .MuiAccordionSummary-expandIconWrapper": {
        marginRight: "15px",   
      },
      gap: "3px",
    }}
  >
    <Typography
 sx={{
  fontFamily: "Montserrat",
  fontSize: { xs: "12px", sm: "16px" },
  fontWeight: "medium",
  lineHeight: { xs: "18px", sm: "14.4px" },
  letterSpacing: "0.5px",
  py: "16px",
  display: "flex",
  alignItems: "center",
  gap: "8px",  
}}
>
 {/* <IconButton size="small" sx={{ p: 0 }}> */}
<CachedIcon sx={{color:'blue'}}/>
{/* </IconButton> */}
  What is your return policy?
    </Typography>
  </AccordionSummary>

  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px", color:"#686767"
      }}
    >
  We stand by the quality of our NFC plates! If you receive a damaged or defective product, you can request a replacement or refund within 30 days of delivery. Simply contact our support team with your order details.
 </Typography>
  </AccordionDetails>
</Accordion>
<Accordion
  disableGutters
  elevation={0}
  square
  sx={{
    border: "1px solid #bbb6b6",
    borderRadius: "5px",
    paddingLeft: "14px",
    mt: "10px",
    mb: "20px",
    mx: { xs: "20px", md: "50px" },
    backgroundColor: "#fff",  // Add background color to match Box
    boxShadow: "none",
    "&:before": { display: "none" },
    backgroundColor:"#F5F5F5"
  }}
>
  <AccordionSummary
  expandIcon={
    <ExpandMoreIcon />}
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
    minHeight: "unset",
   
    "& .MuiAccordionSummary-content": {
      margin: 0,
      display: "flex",
      alignItems: "center",
    }, "& .MuiAccordionSummary-expandIconWrapper": {
      marginRight: "15px",   
    },
    gap: "3px",
  }}
  >
    <Typography
 sx={{
  fontFamily: "Montserrat",
  fontSize: { xs: "12px", sm: "16px" },
  fontWeight: "medium",
  lineHeight: { xs: "18px", sm: "14.4px" },
  letterSpacing: "0.5px",
  py: "16px",
  display: "flex",
  alignItems: "center",
  gap: "8px",  
}}
>
 {/* <IconButton size="small" sx={{ p: 0 }}> */}
<PhoneEnabledIcon sx={{color:"red"}}/>
{/* </IconButton> */}
   What if I have more questions or need support?

    </Typography>
  </AccordionSummary>

  <AccordionDetails
    sx={{
      textAlign: "start",
      paddingRight: { xs: "6px", sm: "auto" },
      paddingLeft: 0,
      paddingBottom: "15px",
      backgroundColor:"#F5F5F5" 
    }}
  >
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: { xs: "12px", sm: "14px" },
        fontWeight: "medium",
        lineHeight: { xs: "20px", sm: "30px" },
        letterSpacing: "0.5px", color:"#686767"
      }}
    >
   We’re happy to help! Contact our support team anytime, and we’ll assist you with any questions or concerns please reach out to our Whatsapp Number 305-497-5941 or write to support@pointgenie.com.
 </Typography>
  </AccordionDetails>
</Accordion>
        </Box>
      </Grid>
      <Grid item sm={2.5}>
        {" "}
      </Grid>
    </Grid>
  );
}

export default Sectionfive;
