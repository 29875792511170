import React from "react";
import { Box, styled, Typography, Grid } from "@mui/material";
import arrow from "../images/arrow.png";
import howtosetup1 from "../images/howtosetup1.png";
import howtosetup2 from "../images/howtosetup2.png";
import howtosetup3 from "../images/howtosetup3.png";

const Sectionfour = styled(Box)({});

function SectionHome() {
  return (
    <Box>
      <Sectionfour sx={{ py: { xs: 0 }, px: { xs: "20px", sm: "85px" } }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          pt={{ xs: 3, sm: 3, xl: 6 }}
          pb={{ xs: 4, sm: 8 }}
          borderBottom="1px solid #a39b9b"
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: { xs: "28px", sm: "80px", xl: "60px" },
                lineHeight: { xs: "40px", sm: "115.2px" },
                letterSpacing: { sm: "-5px" },
                fontWeight:"bold"
              }}
            >
              How to Setup your Product
            </Typography>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                // fontWeight: "400",
                fontWeight:"semi-bold",
                lineHeight: { xs: "24px", sm: "40px" },
                fontSize: { xs: "16px", sm: "20px", xl: "30px" },
                maxWidth: "800px",
                margin: "auto",
              }}
            >
              {/* Manage your experience from start to finish, from integrations to
              registration and from interactive stage elements to post-event
              data, it's all here. */}
              Boost Your Google Reviews in Just 10 Seconds- Delight 
              Your Customers Effortlessly!
            </Typography>
          </Grid>
        </Grid>

        {[
          {
            step: "Step 1",
            title: "Scan the QR code",
            description: "When you receive your plate, Scan the QR code.",
            image: howtosetup1,
          },
          {
            step: "Step 2",
            title: "Activate your card",
            description:
              "You will arrive on the PointGenie App: Enter the activation code on your plate and activate it.",
            image: howtosetup2,
          },
          {
            step: "Step 3",
            title: "You're all set!",
            description:
              "And that's all there is to it! You're now ready to collect your first reviews!",
            image: howtosetup3,
          },
        ].map(({ step, title, description, image }, index) => (
          <Grid
            key={index}
            container
            alignItems="center"
            justifyContent="center"
            textAlign="left"
            py={{ xs: 3, sm: 6 }}
            borderBottom="1px solid #a39b9b"
          >
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: { xs: "14px", sm: "18px" },
                  letterSpacing: "-0.2px",
                  lineHeight: "26px",
                }}
              >
                {step}
              </Typography>
              <Box display="flex" alignItems="left" justifyContent="start" gap={2}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "20px", sm: "29px", xl: "59px" },
                    lineHeight: { xs: "40px", sm: "80px", xl: "120px" },
                    letterSpacing: "-2px",
                  }}
                >
                  {title}
                </Typography>
                <Box component="img" src={arrow} width={{ xs: "45px", sm: "auto" }} />
              </Box>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: { xs: "16px", sm: "24px" },
                  lineHeight: { xs: "24px", sm: "34px" },
                  maxWidth: "600px",
                  // margin: "auto",
                }}
              >
                {description}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} pt={{ xs: "15px", sm: "auto" }} textAlign={"center"} >
              <Box component="img" src={image} width={{xs:"80%",sm:"140%",xl:"auto"}} />
            </Grid>
          </Grid>
        ))}
      </Sectionfour>
    </Box>
  );
}

export default SectionHome;
