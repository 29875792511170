import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useJsApiLoader } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import AutoCompleteInput from "./AutoComplete";
import {
  Box,
  Button,
  Card,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Configure = () => {
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [placeId,setPlaceId]=useState(null);

  const handleAddressSelect = async (address) => {
    setSelectedAddress(address);
    try {
      const results = await getGeocode({ address });
      console.log("result",results);
      const { lat, lng } = await getLatLng(results[0]);
      setCoordinates({ lat, lng });
      setPlaceId(results[0]?.place_id);
      console.log("Latitude:", lat, "Longitude:", lng);
    } catch (error) {
      console.error("Error fetching coordinates: ", error);
    }
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBK2LtRcuFRSz4ZYZwaz8XAaj3RMTOKfh8",
    libraries: ["places"],
  });

  if (loadError)
    return <Typography color="error">Error loading maps</Typography>;
  if (!isLoaded) return <CircularProgress />;

  console.log("placeId",placeId)
  return (
    <Box sx={{ backgroundColor: "#122272", height: "100vh" }}>
      <Container maxWidth="sm" sx={{ padding: 0, flexGrow: 1 }}>
        <Box
          className="heading"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            pt: 5,
            pb: 3,
          }}
        >
          <i
            className="fa-sharp fa-solid fa-location-dot"
            style={{ fontSize: "30px", color: "white" }}
          ></i>
          <Typography
            variant="h2"
            sx={{ fontSize: "28px", fontFamily: "Goldplay-bold" }}
            className="fw-bold text-white  mb-0"
          >
            PointGenie
          </Typography>
        </Box>
        <Card
          sx={{
            backgroundColor: "white",
            borderRadius: "35px",
            padding: "35px 40px 50px ",
          }}
        >
          <Box display="flex" gap={2} alignItems="center" paddingBottom="8px">
            <Typography
              variant="h6"
              fontWeight="bold"
              fontFamily="Goldplay-bold"
            >
              Configuring
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              color="rgb(51, 201, 51)"
              fontFamily="Goldplay-bold"
            >
              HJK254
            </Typography>
          </Box>
          <Typography
            mb={3}
            fontFamily="Goldplay"
            fontSize="14px"
            lineHeight="1.4"
          >
            Step 2: Enter the name of the venue as well as the Address
          </Typography>
          <AutoCompleteInput setSelectedAddress={handleAddressSelect} />
          <Button
            variant="contained"
            sx={{
              mt: 4,
              borderRadius: "25px",
              width: "50%",
              // backgroundColor: "#6880f5",
              fontSize: "18px",
              fontFamily: "Goldplay-bold",
              padding: { xs: "5px" },
              textTransform: "capitalize",
            }}
            onClick={() => navigate("/newplate")}
          >
            Save
          </Button>
        </Card>
      </Container>
    </Box>
  );
};

export default Configure;
