import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  ClickAwayListener,
} from "@mui/material";
import menustar from "../images/menustar.png";
import callingicon from "../images/callingicon.png";
import INSTAGRAM from "../images/INSTAGRAM.png";
import google from "../images/google.png";
import grouplogo from "../images/Grouplogo.png";
import TIKTOK from "../images/TIKTOK.png";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LanguageIcon from "@mui/icons-material/Language";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import StarIcon from "@mui/icons-material/Star";
import CircleIcon from "@mui/icons-material/Circle";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PublicIcon from "@mui/icons-material/Public";
import nexticon from "../images/nexticon.png";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import call from "../images/call.png";
import axiosInstance from "./axios";
import { useMediaQuery } from "@mui/material";
import moment from "moment-timezone";
import { BusinessContext } from "./BusinessContext";
import PhoneIcon from "@mui/icons-material/Phone";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

function LandingPage() {
  const navigate = useNavigate();
  const { code } = useParams();

  const [getBusinessData, setGetBusinessData] = useState({});

  const [placeId, setPlaceId] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [validImages, setValidImages] = useState([]);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [totalPageLoading, setTotalPageLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:500px)");
  const API_KEY = "AIzaSyBK2LtRcuFRSz4ZYZwaz8XAaj3RMTOKfh8";
  const { categoriesData } = useContext(BusinessContext);
  const currentUrl = window.location.href;

  useEffect(() => {
    const fetchBusinessData = async () => {
      // setLoading(true);
      setTotalPageLoading(true);
      try {
        const response = await axiosInstance.post(
          "payments.php?method=fetch_business",
          { plate_code: code }
        );
        if (
          response.data?.status === "success" &&
          response.data?.place_details
        ) {
          setGetBusinessData(response.data.place_details);
          setValidImages(response.data.place_details.images || []);
        } else {
          console.error("Invalid API response structure:", response.data);
        }
        const fetchedPlaceId = response.data?.place_details?.place_id;
        if (fetchedPlaceId) {
          setPlaceId(fetchedPlaceId);
        }

        // Convert images to valid URLs
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        // setLoading(false);
        setTotalPageLoading(false);
      }
    };

    fetchBusinessData();
  }, [code]);

  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      // Swipe Left (Next Image)
      setCurrentImageIndex(
        (prev) => (prev + 1) % getBusinessData?.images?.length
      );
    } else if (touchStartX - touchEndX < -50) {
      // Swipe Right (Previous Image)
      setCurrentImageIndex((prev) =>
        prev === 0 ? getBusinessData?.images?.length - 1 : prev - 1
      );
    }
  };

  // Function to navigate images
  const handleImageNavigation = (direction) => {
    if (validImages.length === 0) return;

    setCurrentImageIndex((prev) =>
      direction === "next"
        ? (prev + 1) % validImages.length
        : (prev - 1 + validImages.length) % validImages.length
    );
  };

  // Function to generate valid image URLs
  // const getValidImageUrls = (images) => {
  //   return images
  //     ?.filter((img) => img && img !== null && img.trim() !== "") // Remove empty/null values
  //     .map(
  //       (photoRef) =>
  //         `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${photoRef}&key=${API_KEY}`
  //     );
  // };

  // Opens the dropdown when the icon is clicked
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Closes the dropdown manually
  const handleClosesocialmedia = () => {
    setAnchorEl(null);
  };

  // Handle social media click (share URL)
  const handleSocialClick = (platform) => {
    let shareUrl = "";

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      case "instagram":
        shareUrl = `https://www.instagram.com/`; // Instagram doesn't allow direct text sharing
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      case "telegram":
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      default:
        break;
    }

    // Open the share URL in a new tab
    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }

    // Close the dropdown after clicking
    handleClosesocialmedia();
  };

  return (
    <Box sx={{overflowX:"hidden"}}>
      {totalPageLoading ? (
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            // backgroundColor: "#f0f0f0",
            padding: "20px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "250px",
              background:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "shimmer 1.8s infinite",
              borderRadius: "8px",
              "@keyframes shimmer": {
                "0%": { backgroundPosition: "-200% 0" },
                "100%": { backgroundPosition: "200% 0" },
              },
            }}
          />

          {/* Shimmer Text */}
          <Box
            sx={{
              width: "70%",
              height: "20px",
              mt: 4,
              borderRadius: "6px",
              background:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "shimmer 1.8s infinite",
            }}
          />

          <Box
            sx={{
              width: "50%",
              height: "20px",
              mt: 2,
              borderRadius: "6px",
              background:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "shimmer 1.8s infinite",
            }}
          />

          {/* Shimmer Button */}
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box
              sx={{
                width: "48%",
                height: "45px",
                mt: 4,
                borderRadius: "8px",
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.8s infinite",
              }}
            />

            <Box
              sx={{
                width: "50%",
                height: "45px",
                mt: 4,
                borderRadius: "8px",
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.8s infinite",
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              mt: 2,
              borderRadius: "8px",
              background:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "shimmer 1.8s infinite",
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "45px",
              mt: 2,
              borderRadius: "8px",
              background:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "shimmer 1.8s infinite",
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "45px",
              mt: 2,
              borderRadius: "8px",
              background:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "shimmer 1.8s infinite",
            }}
          />
        </Box>
      ) : (
        <>
          <Box sx={{ width: "100%", height: "250px", position: "relative" }}>
            {getBusinessData?.images?.filter((img) => img && img !== "")
              .length > 0 ? (
              <>
                <Box
                  component="img"
                  src={
                    getBusinessData?.images?.filter((img) => img && img !== "")[
                      currentImageIndex
                    ]
                  }
                  sx={{
                    width: "100%",
                    height: "250px",
                    objectFit: "cover",
                  }}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    px: 2,
                    transform: "translateY(-50%)",
                  }}
                >
                  <IconButton
                    onClick={() => handleImageNavigation("prev")}
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      display: isMobile ? "none" : "flex", // Hides button on mobile
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => handleImageNavigation("next")}
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      display: isMobile ? "none" : "flex", // Hides button on mobile
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    left: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  {getBusinessData?.images
                    ?.filter((img) => img && img !== "")
                    .map((_, index) => (
                      <CircleIcon
                        key={index}
                        sx={{
                          fontSize: 8,
                          color:
                            index === currentImageIndex
                              ? "#fff"
                              : "rgba(255, 255, 255, 0.5)",
                          cursor: "pointer",
                        }}
                        onClick={() => setCurrentImageIndex(index)}
                      />
                    ))}
                </Box>
              </>
            ) : null}

            <Box sx={{ position: "absolute", top: "15px", right: "20px" }}>
              <Box
                component="img"
                src={nexticon}
                width={"32px"}
                height={"32px"}
                onClick={handleClick}
                sx={{ cursor: "pointer" }}
              />

              {/* Dropdown Menu */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)} // Check if dropdown should be open
                onClose={handleClosesocialmedia}
                PaperProps={{
                  sx: {
                    mt: 1,
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                    width: "200px",
                    p: "5px",
                  },
                }}
              >
                {/* WhatsApp */}
                <MenuItem
                  onClick={() => handleSocialClick("whatsapp")}
                  sx={{ gap: 1 }}
                >
                  <WhatsAppIcon sx={{ color: "#25D366" }} />
                  <Typography>WhatsApp</Typography>
                </MenuItem>

                {/* Instagram */}
                <MenuItem
                  onClick={() => handleSocialClick("instagram")}
                  sx={{ gap: 1 }}
                >
                  <InstagramIcon sx={{ color: "#E4405F" }} />
                  <Typography>Instagram</Typography>
                </MenuItem>

                {/* Facebook */}
                <MenuItem
                  onClick={() => handleSocialClick("facebook")}
                  sx={{ gap: 1 }}
                >
                  <FacebookIcon sx={{ color: "#E4405F" }} />
                  <Typography>Facebook</Typography>
                </MenuItem>

                {/* Twitter */}
                <MenuItem
                  onClick={() => handleSocialClick("twitter")}
                  sx={{ gap: 1 }}
                >
                  <TwitterIcon sx={{ color: "#1DA1F2" }} />
                  <Typography>Twitter</Typography>
                </MenuItem>

                {/* Telegram */}
                <MenuItem
                  onClick={() => handleSocialClick("telegram")}
                  sx={{ gap: 1 }}
                >
                  <TelegramIcon sx={{ color: "#0088CC" }} />
                  <Typography>Telegram</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>

          <Box sx={{ px: "20px", pt: "16px", pb: "22px" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "Sansita",
                fontWeight: "700",
                lineHeight: "27px",
                pb: "3px",
                wordBreak: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {getBusinessData?.name || ""}
            </Typography>

            <Grid
              container
              sx={{
                // display: "flex",
                // flexDirection: "row",
                gap: "6px",
                alignItems: "start",
              }}
            >
              <Grid item xs={6.7}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    pl: "2px",
                    //   lineHeight: "25.88",
                    // letterSpacing: "-0.65px",
                    color: "",
                  }}
                >
                  {getBusinessData?.address || ""}
                </Typography>
                {getBusinessData?.website && (
                  <Box >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "13px",
                      fontWeight: "400",
                      letterSpacing: "0px",
                      lineHeight: "14px",
                      pt: "5px",
                      pl: "4px",
                      color: "#0041A8",
                    }}
                  >
                    <a
                      href={getBusinessData?.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                       <LanguageIcon
                         sx={{ color: "#0041A8",fontSize:"18px",mr:"4px" }}
                        />
                     Website
                    </a>
                  </Typography>
                </Box>
                )}
                
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pt: "3px",
                    paddingLeft: "4px",
                  }}
                >
                  {/* <Box
                    component="img"
                    src={call}
                    width={"15px"}
                    height={"15px"}
                    sx={{ marginRight: "6px", }}
                  /> */}
                  <PhoneIcon
                    fontSize="14px"
                    sx={{ marginRight: "6px", color: "#0041A8" }}
                  />
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "12px",
                      fontFamily: "Roboto",
                      lineHeight: "14px",
                      letterSpacing: "0px",
                      color: "#0041A8",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(`tel:${getBusinessData.telephone}`)
                    }
                  >
                    {getBusinessData?.telephone || "No phone number available"}
                  </Typography>
                </Box>
             
              </Grid>

              <Grid item xs={5}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    // paddingTop: "6px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      gap: "6px",
                    }}
                  >
                    <Box component="img" src={menustar} />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        fontFamily: "Roboto",
                        // lineHeight: "25.88px",
                        letterSpacing: "-0.65px",
                        color: "#F97731",
                        display: "flex",
                        alignItems: "start",
                      }}
                    >
                      {getBusinessData?.rating}
                      <Box
                        component="span"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          fontFamily: "Roboto",
                          // lineHeight: "25.88px",
                          letterSpacing: "-0.3px",
                          color: "#454343",
                          paddingLeft: "4px",
                        }}
                      >
                        ({getBusinessData?.review_count} reviews)
                      </Box>
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "16px",
                      color: getBusinessData?.is_open_now
                        ? "#53A557"
                        : "#FF0000",
                      py: "3px",
                    }}
                  >
                    {getBusinessData?.is_open_now ? "Open Now" : "Closed"}
                  </Typography>
                  {getBusinessData?.is_open_now &&
                  getBusinessData?.start_end_time ? (
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "18px",
                        color: "#81889D",
                        textAlign: "center",
                      }}
                    >
                      {getBusinessData.start_end_time}
                    </Typography>
                  ) : null}{" "}
                </Box>
              </Grid>
            </Grid>
          </Box>
          {getBusinessData.business_categories > 0 && (
            <Box sx={{ display: "flex", gap: "8px", px: "20px" }}>
              <Box sx={{ width: "50%" }} pb={"8px"}>
                <Button
                  sx={{
                    border: "1px solid #0041A8",
                    borderRadius: "8px",
                    backgroundColor: "#0041A8",
                    width: "100%",
                    fontSize: "12Px",
                    lineHeight: "14px",
                    fontWeight: "400",
                    fontFamily: "Roboto",
                    textTransform: "capitalize",
                    color: "#FFFFFF",
                    py: "12px",
                  }}
                  onClick={() => navigate(`/menu/${code}`)}
                >
                  Check Menu
                </Button>
              </Box>

              <Box sx={{ width: "50%" }} pb={"8px"}>
                <Button
                  sx={{
                    border: "1px solid #0041A8",
                    borderRadius: "8px",
                    width: "100%",
                    fontSize: "12Px",
                    lineHeight: "14px",
                    fontWeight: "600",
                    fontFamily: "Roboto",
                    color: "#0041A8",
                    textTransform: "capitalize",
                    py: "12px",
                  }}
                >
                  Specials
                </Button>
              </Box>
            </Box>
          )}
          <Box sx={{ textAlign: "center", pb: "25px", px: "20px" }}>
            <Button
              sx={{
                color: "white",
                width: "100%",
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: "500",
                backgroundColor: "#0041A8",
                textTransform: "capitalize",
                borderRadius: "8px",
                py: "8px",
              }}
              onClick={() => {
                const url = `https://search.google.com/local/writereview?placeid=${getBusinessData?.place_id}`;
                window.open(url, "_blank");
              }}
            >
              <img
                src={google}
                alt="Google"
                style={{ width: "28px", height: "auto", marginRight: "12px" }}
              />
              Leave us a Google Review
            </Button>
          </Box>
          <Box sx={{ px: "35px", textAlign: "center", mb: 3 }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                fontFamily: "Roboto",
                lineHeight: "14px",
              }}
            >
              Connect With Us and Share Your Feedback Your Opinion Matter to Us!
            </Typography>
          </Box>

          {/* Providers Section */}

          {getBusinessData?.provider &&
            (() => {
              const validProviders = Object.entries(getBusinessData.provider)
                .filter(
                  ([provider, data]) =>
                    data.proivder_link && provider.toLowerCase() !== "google"
                )
                .map(([provider, data]) => {
                  let providerIcon;
                  let providerName = data.proivder_label; // Use the label from data
                  let providerUrl = data.proivder_link; // Use the link from data

                  if (provider.toLowerCase() === "yelp") {
                    providerIcon = (
                      <StarIcon sx={{ color: "#D32323", fontSize: 30 }} />
                    );
                  } else if (provider.startsWith("customprovider_")) {
                    providerIcon = (
                      <InsertLinkIcon sx={{ color: "#0041A8", fontSize: 30 }} />
                    );
                  } else {
                    switch (provider.toLowerCase()) {
                      case "facebook":
                        providerIcon = (
                          <FacebookIcon
                            sx={{ color: "#1877F2", fontSize: 30 }}
                          />
                        );
                        break;
                      case "instagram":
                        providerIcon = (
                          <InstagramIcon
                            sx={{ color: "#E4405F", fontSize: 30 }}
                          />
                        );
                        break;
                      case "tiktok":
                        providerIcon = (
                          <Box
                            component="img"
                            src={TIKTOK}
                            sx={{ width: 30, height: 30 }}
                          />
                        );
                        break;
                      case "whatsapp":
                        providerIcon = (
                          <WhatsAppIcon
                            sx={{ color: "#25D366", fontSize: 30 }}
                          />
                        );
                        break;
                      case "tripadvisor":
                        providerIcon = (
                          <StarIcon sx={{ color: "#00AF87", fontSize: 30 }} />
                        );
                        break;
                      case "pointgenie":
                        providerIcon = (
                          <Box
                            component="img"
                            src={grouplogo}
                            sx={{ width: 30, height: 30 }}
                          />
                        );
                        break;
                      default:
                        return null;
                    }
                  }

                  return { provider, providerUrl, providerIcon, providerName };
                })
                .filter(Boolean);

              // Group providers into pairs
              const pairs = [];
              for (let i = 0; i < validProviders.length; i += 2) {
                pairs.push(validProviders.slice(i, i + 2));
              }

              return pairs.map((pair, index) => (
                <Grid
                  container
                  key={index}
                  sx={{ px: "20px", mb: "5px", gap: "4px" }}
                >
                  {pair.map((item) => (
                    <Grid
                      item
                      xs={
                        (pair.length === 1 && validProviders.length > 2) ||
                        validProviders.length === 1
                          ? 12
                          : 5.9
                      }
                      key={item.provider}
                      sx={{
                        border: "1px solid #0041A8",
                        borderRadius: "8px",
                        py: "2px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        gap: "6px",
                      }}
                      onClick={() => {
                        if (item.provider.toLowerCase() === "whatsapp") {
                          const whatsappUrl = `https://wa.me/${item.providerUrl.replace(
                            /\D/g,
                            ""
                          )}`;
                          window.open(whatsappUrl, "_blank");
                        } else {
                          window.open(
                            item.providerUrl.startsWith("http")
                              ? item.providerUrl
                              : `https://${item.providerUrl}`,
                            "_blank"
                          );
                        }
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {item.providerIcon}
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          fontFamily: "Roboto",
                          lineHeight: "14px",
                          color: "#0041A8",
                        }}
                      >
                        {item.providerName}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              ));
            })()}

          <Box sx={{ px: "10px", pt: "20px", position: "relative" }}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: "400px",
                fontSize: "14px",
                lineHeight: "16px",
                textAlign: "center",
                pt: "4px",
                pb: "5px",
                borderBottom: "1px solid #b7b7b761",
              }}
            >
              Latest Reviews
            </Typography>
            {getBusinessData?.reviews?.length > 0 && (
              <Box sx={{ position: "relative", minHeight: "200px" }}>
                {getBusinessData.reviews
                  .slice(0, showAllReviews ? getBusinessData.reviews.length : 1)
                  .map((review, index) => (
                    <Box
                      key={index}
                      sx={{ p: "10px", borderBottom: "1px solid #ddd" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "Roboto",
                          lineHeight: "16px",
                        }}
                      >
                        {review.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          pt: "6px",
                          pb: "4px",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: "4px" }}>
                          {[...Array(5)].map((_, starIndex) => (
                            <Box
                              key={starIndex}
                              component="img"
                              src={menustar}
                              sx={{
                                filter:
                                  starIndex < review.rating
                                    ? "none"
                                    : "grayscale(100%)",
                                opacity: starIndex < review.rating ? 1 : 0.5,
                              }}
                            />
                          ))}
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            lineHeight: "16px",
                            color: "#8F9BB3",
                          }}
                        >
                          {new Date(review.created_at).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "Roboto",
                          lineHeight: "20px",
                        }}
                      >
                        {review.comment}
                      </Typography>
                    </Box>
                  ))}

                {getBusinessData.reviews.length > 1 && (
                  <Box sx={{ textAlign: "center", mt: 2 }}>
                    <Typography
                      onClick={() => setShowAllReviews(!showAllReviews)}
                      sx={{
                        color: "#0041A8",
                        fontSize: "14px",
                        fontWeight: "500",
                        cursor: "pointer",
                        pb: "25px",
                      }}
                    >
                      {showAllReviews ? "Show Less..." : "View More..."}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default LandingPage;
