import React, { useState, useRef,useEffect } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import  {RecaptchaVerifier,signInWithPhoneNumber} from '../firebase/FirebaseConfig';
import { auth } from "../firebase/FirebaseConfig";
import { useNavigate } from "react-router-dom";

const LoginOpt = () => {
  const [phone, setPhone] = useState( "");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [confrimationResult,setConfirmationResult]=useState(null)
  const otpInputs = useRef([]);
  const navigate =useNavigate();



  const initializeRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
        callback: (response) => {
          console.log("reCAPTCHA solved:", response);
        },
      });
    }
  };




  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPhone(value);
    }
  };

  const handleOtpChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      let newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field
      if (value && index < otp.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && otp[index] === "" && index >0) {
      if (index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };

  const handleSentOtp = async (e) => {
    e.preventDefault();
  
    if (phone.length !== 10) {
      setError("Please enter a valid 10-digit phone number.");
      return;
    }
    setError("");
  
    try {
      initializeRecaptcha(); // Ensure reCAPTCHA is initialized
  
      const confirmation = await signInWithPhoneNumber(auth, `+91${phone}`, window.recaptchaVerifier);
      setConfirmationResult(confirmation);
      setOpen(true);
    } catch (error) {
      setError("Error sending OTP. Try again.");
      console.error("Error sending OTP:", error.message);
    }
  };

  const handleVerifyOtp =async ()=>
  {
    const enteredOtp = otp.join('');
    if(enteredOtp.length !==6)
    {
      setError("Enter a valid 6-digit OTP.");
      return;
    }
    try {
      await confrimationResult.confirm(enteredOtp);
      alert("OTP Verified successfullly!");
      setOpen(false);
      navigate('/configure')
    }catch (err)
    {
      setError("Invalid OTP. Please try again.");
      console.log("error verifiedotp",err);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#122272",
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container maxWidth="xs">
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "20px",
            padding: "40px 20px",
          }}
        >
          <Typography
            variant="h4"
            sx={{ textAlign: "center", fontWeight: "bold", marginBottom: 2 }}
          >
            Login with OTP
          </Typography>
          {error && (
            <Typography
              color="error"
              sx={{ textAlign: "center", marginBottom: 2 }}
            >
              {error}
            </Typography>
          )}
          <form onSubmit={handleSentOtp}>
            <TextField
              fullWidth
              type="tel"
              label="Phone Number"
              name="phone"
              value={phone}
              onChange={handlePhoneChange}
              margin="normal"
              required
              inputProps={{ maxLength: 10 }}
            />
        <div id="recaptcha-container"></div>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ marginTop: 2, backgroundColor: "#122272", color: "white" }}
            >
              Get OTP
            </Button>
          </form>
        </Box>
      </Container>

      {/* OTP Verification Modal */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Verify OTP
          </Typography>

          {/* OTP Inputs */}
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1, mb: 2 }}>
            {otp.map((value, index) => (
              <TextField
                key={index}
                inputRef={(el) => (otpInputs.current[index] = el)}
                variant="outlined"
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: "center",
                    width: "20px",
                    height: "20px",
                    fontSize: "16px",
                  },
                }}
                value={value}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
              />
            ))}
          </Box>

          <Button
            variant="contained"
            sx={{ backgroundColor: "#122272", color: "white", mt: 1 }}
            fullWidth
            onClick={handleVerifyOtp}
          >
            Verify
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default LoginOpt;
