import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Radio,
  TextField,
  CircularProgress,
  Card,
  IconButton,
  Container,
} from "@mui/material";
import Star from "../../src/images/Star.png";
import { useJsApiLoader } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";
import { getGeocode } from "use-places-autocomplete";
import sectionimg from "../images/sectionimg.png";
import google from "../images/google.png";
import sectionuploadimg from "../images/sectionuploadimg.png";
import axiosInstance from "./axios";
import reviewImage from "../images/carousel-banner1.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import imagescroll1 from "../images/carousel-banner1.png";
import imagescroll3 from "../images/carousel-banner2.png";
import imagescroll5 from '../images/carousel-banner3.png';
import imagescroll2 from '../images/carousel-banner4.png';
import imagescroll4 from '../images/carousel-banner5.png';
import { useMediaQuery } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { motion, AnimatePresence } from "framer-motion";
import days from "../images/days.png";
import truck from "../images/truck.png";
import easyreturn from "../images/easyreturn.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import tick from "../images/tick.jpeg"

const libraries = ["places"];
function SectionThree({ sectionThreeRef}) {
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState("");
  const autoCompleteRef = useRef(null);
  const [placeId, setPlaceId] = useState(null);
  const [PlaceName, setPlaceName] = useState();
  const [selectedValue, setSelectedValue] = useState({
    productId: "",
    plates: "",
    price: "",
  });
  const [addressError, setAddressError] = useState("");
  const [products, setProducts] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const [FullPlaceName,setFullPlaceName]=useState();
  const imagesPerPage = 4;
  const isMobile = useMediaQuery("(max-width:600px)");
  const addressInputRef = useRef(null);

  const images = [
    imagescroll1,
    imagescroll2,
    imagescroll3,
    imagescroll4,
    imagescroll5,
  ];

  const onLoad = (autocomplete) => {
    autoCompleteRef.current = autocomplete;
  };

  // const onPlaceChanged = async () => {
  //   if (autoCompleteRef.current !== null) {
  //     const place = autoCompleteRef.current.getPlace();
  //     console.log("place",place);
  //     if (place.formatted_address) {
  //       setSelectedAddress(place.formatted_address);
  //       setAddressError(""); // Clear any previous error
  //       // Assuming `getGeocode` is defined somewhere in your app, using the correct address
  //       try {
  //         const results = await getGeocode({
  //           address: place.formatted_address,
  //         });
  //         setPlaceId(results[0]?.place_id);
  //         console.log("Geocode results: ", results,placeId); // handle the results here
  //       } catch (error) {
  //         console.error("Error fetching geocode: ", error);
  //       }
  //     }
  //   }
  // };

  const onPlaceChanged = async () => {
    if (autoCompleteRef.current !== null) {
      const place = autoCompleteRef.current.getPlace();
      console.log("Full Place Details:", place);

      if (place) {
        // ✅ Get the correct place_id from place object
        if (place.place_id) {
          setPlaceId(place.place_id);
          setPlaceName(place.name)
          setFullPlaceName(place.formatted_address)
          console.log("Correct Place ID:", place.place_id);
        } else {
          console.warn("Place ID not found in place object.");
        }

        // ✅ Set address if available
        if (place.formatted_address) {
          setSelectedAddress(place.formatted_address);
          setAddressError(""); // Clear any previous error
        }

        try {
          // Fetch geocode details if needed
          const results = await getGeocode({
            address: place.formatted_address,
          });
          console.log("Geocode results:", results);
        } catch (error) {
          console.error("Error fetching geocode:", error);
        }
      }
    }
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBK2LtRcuFRSz4ZYZwaz8XAaj3RMTOKfh8",
    libraries: ["places"],
  });

  const handleCheckout = () => {
    if (!selectedAddress) {
      setAddressError(
        "Please enter your location before purchasing the product"
      );
    // Scroll to the address input field
    if (addressInputRef.current) {
      addressInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      addressInputRef.current.focus(); // Optional: Focus the input
    }
    return;
    }
    
    navigate("/checkout", {
      state: {
        plates: selectedValue.plates,
        price: selectedValue.price,
        retailPrice:selectedValue.retailPrice,
        producId: selectedValue.productId,
        placeid: placeId,
        placeName:PlaceName,
        fullPlaceName:FullPlaceName,
      },
    });
  };

  const handleOptionChange = (productId, plates, price,retailPrice) => {
    setSelectedValue({ productId, plates, price,retailPrice });
  };

  useEffect(() => {
    const scrollToSection = () => {
      const hash = window.location.hash; // Get URL hash
      if (hash) {
        const sectionId = hash.replace("#", ""); // Remove `#`
        const section = document.getElementById(sectionId);

        if (section) {
          console.log("Scrolling to:", sectionId); // Debugging log
          setTimeout(() => {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }, 500); // Delay ensures section is fully rendered before scrolling
        } else {
          console.warn("Section not found:", sectionId); // Debugging log
        }
      }
    };

   
    setTimeout(scrollToSection, 400); // Ensure section is available before scrolling

    // Scroll when hash changes
    window.addEventListener("hashchange", scrollToSection);

    return () => {
      window.removeEventListener("hashchange", scrollToSection);
    };
  }, []);


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axiosInstance.post(
          "payments.php?method=products"
        );
        if (
          response.data &&
          response.data.status === "success" &&
          response.data.places
        ) {
          setProducts(response.data.places);
          // Set the first product as default selection if no product is currently selected
          if (!selectedValue.productId && response.data.places.length > 0) {
            const firstProduct = response.data.places[0];
            setSelectedValue({
              productId: firstProduct.id,
              plates: firstProduct.name,
              price: firstProduct.price,
              retailPrice:firstProduct.retail_price
            });
          }
        }
      } catch (err) {
        console.error("Error fetching products:", err);
      }
    };
    fetchProducts();
  }, []); // Add baseUrl as dependency

  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleNext = () => {
    if (startIndex + imagesPerPage < images.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handleThumbnailClick = (index) => {
    const absoluteIndex = startIndex + index;
    setCurrentImageIndex(absoluteIndex);
  
    if (index + startIndex !== currentImageIndex) {
      setDirection(1); // Right slide effect
      setCurrentImageIndex(index + startIndex);
    }
  
    // Only scroll forward when clicking the last visible thumbnail
    if (
      index === imagesPerPage - 1 && // Last image in the row
      startIndex + imagesPerPage < images.length // More images exist
    ) {
      setStartIndex(startIndex + 1);
    }
  };
  
  const handleSwipeChange = (index) => {
    setCurrentImageIndex(index);
  };

  if (loadError)
    return <Typography color="error">Error loading maps</Typography>;
  if (!isLoaded) return <CircularProgress />;
  return (
    <Box sx={{ padding: { xs: "0px 15px",  sm: "0px  25px",  xl: "0px 100px 0px 80px" } }}>
      {/* <Container> */}
      <Grid container position={"relative"} px={"0px"} gap={{sm:"18px",xl:"0px"}}>
        {/* <Grid items xs={12} sm={0.2} xl={0}></Grid> */}
        <Grid items xs={12} sm={6} xl={6.5}   sx={{ 
      position: {xs:"none",sm:"sticky"}, 
      top: "40px", 
      alignSelf: "flex-start", 
      height: "fit-content"
    }}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: { xs: "300px", sm: "400px", xl: "500px" }, // Adjust these values as needed
              overflow: "hidden",
            }}
          >
            {isMobile ? (
              <>
                <SwipeableViews
                  index={currentImageIndex}
                  onChangeIndex={handleSwipeChange}
                  enableMouseEvents
                >
                  {images.map((img, index) => (
                    <Box
                      key={index}
                      component="img"
                      src={img}
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "auto", // Allowing auto height prevents cropping
                        maxHeight: { xs: "300px", sm: "400px", xl: "500px" },
                        overflow: "hidden",
                      }}
                    />
                  ))}
                </SwipeableViews>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: "flex",
                    gap: "6px",
                    zIndex: 2, // Ensures dots are above image
                  }}
                >
                  {images.map((_, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        backgroundColor:
                          currentImageIndex === index ? "#2B56F5" : "#000000",
                        opacity: currentImageIndex === index ? 1 : 0.6,
                        transition: "background-color 0.3s ease",
                        cursor: "pointer",
                      }}
                      onClick={() => setCurrentImageIndex(index)}
                    />
                  ))}
                </Box>
              </>
            ) : (
              <AnimatePresence mode="wait">
              <motion.img
                key={currentImageIndex} // Forces re-render for animation
                src={images[currentImageIndex]}
                initial={{ opacity: 0, x: 100 * direction }} // Slide in from right
                animate={{ opacity: 1, x: 0 }} // Show image
                exit={{ opacity: 0, x: -100 * direction }} // Slide out left
                transition={{ duration: 0.2, ease: "easeInOut" }}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  position: "absolute",
                }}
              />
            </AnimatePresence>
            )}
          </Box>
          {!isMobile && (
            <Box
              sx={{
                paddingTop: { xs: "10px", sm: "38px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <IconButton
                onClick={handlePrevious}
                disabled={startIndex === 0}
                sx={{
                  // backgroundColor: "rgba(160, 160, 160, 0.8)",
                  cursor: startIndex === 0 ? "not-allowed" : "pointer",
                  fontSize: "20px",
                  color:"black",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                  },
                  // "&.Mui-disabled": {
                  //   backgroundColor: "rgba(160, 160, 160, 0.4)",
                  // },
                }}
              >
              <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
              </IconButton>
              <Box sx={{ display: "flex", gap: "10px" }}>
                {images
                  .slice(startIndex, startIndex + imagesPerPage)
                  .map((img, index) => (
                    <Box
                      key={startIndex + index}
                      component="img"
                      src={img}
                      sx={{
                        width: { xs: "50px", sm: "80px", md: "100px" },
                        height: { xs: "50px", sm: "80px", md: "100px" },
                        objectFit: "contain",
                        cursor: "pointer",
                        border:
                          currentImageIndex === startIndex + index
                            ? "1px solid rgb(122, 122, 122)"
                            : "none",
                        borderRadius: "4px",
                        transition: "transform 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.05)",
                        },
                      }}
                      onClick={() => handleThumbnailClick(index)}
                    />
                  ))}
              </Box>
              <IconButton
                onClick={handleNext}
                disabled={startIndex + imagesPerPage >= images.length}
                sx={{
                  // backgroundColor: "rgba(160, 160, 160, 0.8)",
                  cursor: startIndex === 0 ? "not-allowed" : "pointer",
                  fontSize: "20px",
                  color:"black",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                  },
                  // "&.Mui-disabled": {
                  //   backgroundColor: "rgba(160, 160, 160, 0.4)",
                  // },
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </Box>
          )}
        </Grid>
        {/* <Grid items xs={0} sm={0} xl={0}></Grid> */}

        <Grid items xs={12} sm={5.7} xl={4.7}  sx={{ 
    maxHeight: "fit-content", 
    overflowY: 'auto' 
  }} >
          <Box>
            <Box
              display="flex"
              gap={{ xs: 0.2, sm: 0.4 }}
              mt={{ xs: 2, sm: 1.5 }}
              alignItems={"center"}
              justifyContent={{ xs: "center", sm: "start" }}
            >
              {/* {[...Array(5)].map((_, index) => (
                <Box
                  component="img"
                  src={Star}
                  sx={{
                    width: { xs: "10px", sm: "16px" },
                    height: { xs: "10px", sm: "16px" },
                  }}
                />
              ))} */}
              {/* <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  paddingLeft: "2px",
                }}
              >
                5.0 | +1403 Reviews
              </Typography> */}
            </Box>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: { xs: "28px", sm: "40px" },
                lineHeight: "46.34px",
                fontWeight: "700",
                justifyContent: { xs: "center", sm: "start" },
                display: { xs: "flex", sm: "" },
              }}
            >
              Turn Customers into Fans with Genie Tag
            </Typography>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: { xs: "14px", sm: "16px" },
                lineHeight: "20px",
                fontWeight: "500",
                py: "16px",
                letterSpacing: "1px",
                borderBottom: "1px solid #000000",
              }}
            >
             Get more Google reviews, boost engagement, and showcase your menu — all in seconds.
            </Typography>
            <Box paddingTop={"25px"}>
<Box sx={{display:"flex"}}>
            <CheckCircleIcon sx={{ color: '#0868ff', mr: 1, mt: '4px' }} />
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: { xs: "14px", sm: "20px" },
                  lineHeight: { xs: "20px", sm: "28px" },
                  fontWeight: "500",
                  letterSpacing: "1px",
                }}
              >
                {/* <CheckCircleIcon/> */}
                {/* <img component="span" src={tick} width={"20px"}/> */}
               
           Instant Review Boost – Customers scan, tap, and leave a review in 3 seconds
              </Typography>
              </Box>
              <Box sx={{display:"flex", py: "7px",}}>
              <CheckCircleIcon sx={{ color: '#0868ff', mr: 1, mt: '4px' }} />
          
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: { xs: "14px", sm: "20px" },
                  lineHeight: { xs: "30px", sm: "28px" },
                  fontWeight: "500",
                  letterSpacing: "1px",
                 
                }}
              >
                Highlight Your Best – Show off your menu, daily specials, and services
              </Typography>
              </Box>
              <Box sx={{display:"flex", py: "7px",}}>
              <CheckCircleIcon sx={{ color: '#0868ff', mr: 1, mt: '4px' }} />
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: { xs: "14px", sm: "20px" },
                  lineHeight: { xs: "20px", sm: "28px" },
                  fontWeight: "500",
                  letterSpacing: "1px",
                }}
              >
                Plug-and-Play Setup – No app installs. Works right out of the box
              </Typography>
              </Box>
<Box sx={{display:"flex", pt: "7px",}}>
<CheckCircleIcon sx={{ color: '#0868ff', mr: 1, mt: '4px' }} />
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: { xs: "14px", sm: "20px" },
                  lineHeight: { xs: "20px", sm: "28px" },
                  fontWeight: "500",
                  letterSpacing: "1px",
                
                }}
              >
                Increase Visibility – More reviews = higher ranking on Google
              </Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "#d7d7d7",
                  pt: { xs: "15px", sm: "6px" },
                  pb: { xs: "20px", sm: "50px" },
                  px: { xs: "10px", sm: "10px" },
                  mt: { xs: "16px", sm: "20px" },
                }}
                ref={sectionThreeRef}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    fontSize: "16px",
                    letterSpacing: "1px",
                    lineHeight: { xs: "18px", sm: "34px" },
                    marginLeft: { xs: "10px", sm: "10px" },
                    py: { xs: "", sm: "8px" },
                  }}
                >
                  Search for your business to get set up{" "}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    py: { xs: "15px", sm: "0px" },
                    marginLeft: { xs: "10px", sm: "10px" },
                  }}
                >
                  <Box component="img" src={google} />
                  <Box sx={{ width: "80%" }}>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                      options={{ types: [] }}
                    >
                      <TextField
                         id="products-section"
                       inputRef={addressInputRef}
                        fullWidth
                        variant="outlined"
                        // placeholder="Search for an address"
                        label="Search for an address"
                        error={!!addressError}
                        helperText={addressError}
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "10px",
                          fontWeight: 500,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "10px",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "10px",
                          },
                          "& .MuiInputLabel-root": {
                            fontWeight: "bold",
                            top: "-6px",
                            fontsize: "14px",
                            fontFamily: "Goldplay",
                          },
                        }}
                      />
                    </Autocomplete>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingLeft: { xs: "8px", sm: "42px" },
                    pt: { xs: "", sm: "6px" },
                  }}
                >
                  <Box component="ul" sx={{ margin: 0 }}>
                    <Box
                      component="li"
                      sx={{ "::marker": { fontSize: "10px" } }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: "11px",
                          fontWeight: "500",
                          lineHeight: "15px",
                          letterSpacing: "1px",
                        }}
                      >
                        Can't find your business?No worries. Enter your business
                        name,
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: "11px",
                          fontWeight: "500",
                          lineHeight: "15px",
                          letterSpacing: "1px",
                        }}
                      >
                        city and postal code.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                pb={{ xs: 2, sm: 1 }}
                pt={{ xs: "2px", sm: "3" }}
               justifyContent={"center"}
              >
                <Typography
                  component="span"
                  sx={{
                    borderTop: "1px solid ",
                    width: { xs: "28%", sm: "34%" },
                  }}
                ></Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "14px" ,xl:"16px"},
                    lineHeight: "34px",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    px: 1,
                    fontFamily: "Montserrat",
                  }}
                >
                  BUNDLE & Save
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    borderTop: "1px solid ",
                    width: { xs: "28%", sm: "34%" },
                  }}
                ></Typography>
       
              </Box>
              <Grid container spacing={2} pb={"15px"}>
{products.map((product, index) => (
  <Grid
    item
    xs={12}
    sm={6}
    md={12}
    xl={12}
    key={product.id}
    position={product.product_label ? "relative" : "static"}
    // mx={"auto"}
  >
    <Card
      sx={{
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "15px",
        my: { xs: "5px", sm: "5px", xl: "5px" },
        pb: "10px",
        pt: "10px",
      }}
    >
      <Box
        display="flex"
        // gap={{xs:"0px",sm:12}}
        alignItems="center"
        px={{xs:"12px ",sm:"20px"}}
        justifyContent={{ xs: "space-between", sm: "space-between" }}
      >
       <Box sx={{display:"flex",gap:{xs:"4px",sm:"8px"}}}>
        <Radio
          checked={selectedValue.productId === product.id}
          onChange={() => handleOptionChange(product.id, product.name, product.price,product.retail_price)}
          sx={{
            color: "white",
            "&.Mui-checked": { color: "#2B56F5", },
            "& .MuiSvgIcon-root": {
              fontSize: { xs: "35px", sm: "35px" },
              borderRadius: "10px",
              backgroundColor: "#ffffff",
              border: "2px solid rgba(0, 0, 0, 0.2)",
              padding: "2px",
            },
            "&.Mui-checked::after": {
              content: '""',
              width: { xs: "14px", sm: "16px", xl: "16px" },
              height: { xs: "14px", sm: "16px", xl: "16px" },
              backgroundColor: "#2B56F5",
              borderRadius: "50%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        />
        <Box>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "18px", sm: "20px" },
              fontWeight:{ xs:"600",sm:"600"},
              letterSpacing: "1px",
            }}
          >
            {product.name}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "12px", sm: "14px" },
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            + FreeBook & Gift
          </Typography>
        </Box>
        </Box>
        <Box textAlign="center">
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "18px", sm: "24px", xl: "20px" },
              fontWeight:{ xs:"600",sm:"600"},
              letterSpacing: "1px",
            }}
          >
            ${product.price}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "13px", sm: "14px" },
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            <del>${product.retail_price}</del>
          </Typography>
          <Button
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "14px", sm: "15px" },
              fontWeight: "500",
              backgroundColor: "#2B56F5",
              color: "white",
              height: "28px",
              width: { xs: "max-content", xl: "auto" },
              textTransform: "capitalize",
            }}
          >
            {Math.round(((product.retail_price - product.price) / product.retail_price) * 100)}%
          </Button>
        </Box>
      </Box>
    </Card>

    {/* Dynamic Label Button */}
    {product.product_label && (
      <Box position="absolute" top={{ xs: "6px", sm: "7px" }} right={{ xs: "31%", sm: "32%" }}>
        <Button
          sx={{
            backgroundColor: "#34A853",
            color: "#FFFFFF",
            fontSize: "15px",
            fontWeight: "500",
            fontFamily: "Montserrat",
            width: { xs: "141px", sm: "141px" },
            height: { xs: "30px", sm: "28px" },
            borderRadius: "5px",
            textTransform: "capitalize",
          }}
        >
          {product.product_label}
        </Button>
      </Box>
    )}
  </Grid>
))}

      
      </Grid>
            </Box>
            <Box
            onClick={handleCheckout}
              sx={{
                backgroundColor: "#2B56F5",
                border: "1px solid #2B56F5",
                display: "flex",
                alignItems: "center",
                justifyContent:{xs:"space-between",sm:"space-between"},
                gap: { xs: "px", sm: "35px", xl: "0px" },
                py:{ xs:2,sm:3},
                pr:{xs:"10px",sm:"20px"},
                pl:{xs:"0px",sm:"10px"},
                borderRadius: "16px",
               
              }}
             
              
            >
              <Box display={"flex"} alignItems={"center"}>
                <Radio
                  checked
                  value="option1"
                  sx={{
                    color: "#2B56F5",
                    "&.Mui-checked": {
                      color: "#2B56F5",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: { xs: "35px", sm: "50px" },
                      borderRadius: "10px",
                      backgroundColor: "#ffffff",
                      border: "2px solid #2B56F5",
                      padding: "2px",
                    },
                    "&.Mui-checked::after": {
                      content: '""',
                      width: { xs: "18px", sm: "22px" },
                      height: { xs: "18px", sm: "22px" },
                      backgroundColor: "#2B56F5",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    },
                  }}
                />
                <Typography
               
                  component="span"
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "14px", sm: "22px" },
                    lineHeight: "34px",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    color: "white",
                    cursor:"pointer",
                  }}
                >
                  {selectedValue.plates}
                </Typography>
              </Box>
              <Button  sx={{padding:{xs:"0px",sm:"auto"}}}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "13px", sm: "24px" },
                    lineHeight: "34px",
                    fontWeight: "800",
                    letterSpacing: "1px",
                    color: "white",
                    "@media (max-width:367px)": {
                      fontSize: "12px", 
                    },
                  }}
                >
                  Buy Now
                </Typography>
              </Button>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: { xs: "16px", sm: "24px" },
                  lineHeight: "34px",
                  fontWeight: "600",
                  letterSpacing: "1px",
                  color: "white",
                  "@media (max-width:367px)": {
                    fontSize: "12px", 
                  },
                  cursor:"pointer"
                }}
              >
                ${selectedValue.price}
              </Typography>
            </Box>
            <Box sx={{display:"flex", gap:"55px",justifyContent:"center",pt:"14px"}} >
            <Box textAlign={"center"}>
            <Box
              component="img"
              src={days}
              width={{ xs: "40px", sm: "auto" }}
              py={{ xs: "10px", sm: "0px" }}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: { xs: "12px", sm: "16px" },
                lineHeight: { xs: "16px", sm: "34px" },
                fontWeight: "900",
              }}
            >
              30-Day Risk-Free trial
            </Typography>
          </Box>
          <Box textAlign={"center"}>
            <Box
              component="img"
              src={truck}
              width={{ xs: "40px", sm: "auto" }}
              py={{ xs: "10px", sm: "0px" }}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: { xs: "12px", sm: "16px" },
                lineHeight: { xs: "16px", sm: "34px" },
                fontWeight: "900",
              }}
            >
              Free Shipping
            </Typography>
          </Box>
          <Box textAlign={"center"}>
            <Box
              component="img"
              src={easyreturn}
              width={{ xs: "40px", sm: "auto" }}
              py={{ xs: "10px", sm: "0px" }}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: { xs: "12px", sm: "16px" },
                lineHeight: { xs: "16px", sm: "34px" },
                fontWeight: "900",
              }}
            >
              Easy & Free Return
            </Typography>
          </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* </Container> */}
    </Box>
  );
}

export default SectionThree;