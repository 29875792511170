import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Container,
  styled,
  Typography,
  Avatar,
  Grid,
  backdropClasses,
  colors,
  Image,
  Radio,
  Card,
  Modal,
} from "@mui/material";
import {
  AccountCircleOutlined,
  ShoppingBagOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Star from "../../src/images/Star.png";
import sendarrow from "../images/sendarrow.png";
import appstore from "../images/appstore.png";
import googleplay from "../images/googleplay.png";
import image10 from "../images/image10.png";
import background from "../images/backgroundimg.png";
import cart from "../images/cart.png";
import search from "../images/search.png";
import profile from "../images/profile.png";
import logo from "../images/logo.png";
import image5 from "../images/image5.png";
import Group from "../images/Group.png";
import div from "../images/div.png";
import SectionHome from "./SectionHome";
import Sectionfive from "./Sectionfive";
import Footer from "./Footer";
import TweetCard from "./Reviews";
import SectionThree from "./SectionThree";
import maniImage from "../images/main-banner.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import features from "../images/features.png";
import featuresanalytics from "../images/featuresanalytics.png";
import featuresapps from "../images/featuresapps.png";
import featuregoogle from "../images/featuresgoogle.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { BusinessContext } from "./BusinessContext";
import { isAuthenticated } from "../globals/helper";
import video from "../videosection/video.mov";

const Root = styled(Box)({
  background: "linear-gradient(140.92deg, #2B56F5 8.43%, #19328F 100%)",
  width: "100%",

  top: "5px",
  backgroundRepeat: "no-repeat",
  opacity: 1,
});

const Header = styled(Box)({
  width: "96%",
  borderWidth: "1px",
  borderBottom: "1px solid #FFFFFF",
  padding: "20px 10px",
});
const HeaderText = styled(Typography)({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "33px",
  letterSpacing: "1px",
  textAlign: "center",
  color: "white",
  fontFamily: "Montserrat",
  cursor: "pointer",
});
const MobileHeaderText = styled(Typography)({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "33px",
  letterSpacing: "1px",
  color: "black",
  paddingTop: "5px",
  paddingBottom: "5px",
  fontFamily: "Montserrat",
  cursor: "pointer",
  width: "80%",
  borderBottom: "1px solid #ccc",
});
const GridText = styled(Typography)(({ theme }) => ({
  fontFamily: "Montserrat",
  fontWeight: "700",
  fontSize: "64px",
  lineHeight: "88px",
  letterSpacing: "1px",
  color: "white",
}));

const Secondsection = styled(Box)({
  padding: "80px 10px 0px 10px",
});

const Thirdsection = styled(Box)({
  padding: "0px 85px ",
});

const brands = [
  { name: "yahoo! finance", bold: "yahoo!", normal: "finance" },
  { name: "Bloomberg" },
  { name: "BUSINESS INSIDER" },
  { name: "Fortune", bold: "Fortune" },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: true,
  swipeToSlide: true,
  cssEase: "ease-in-out",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const newsList = ["Yahoo! Finance", "Bloomberg", "Business Insider", "Forbes"];

function LayoutDesign() {
  const [selectedValue, setSelectedValue] = useState("option1");
  const sectionThreeRef = useRef(null);
  const sectiontwo = useRef(null);
  const sectionHomeScroll = useRef(null);
  const navigate = useNavigate();
  const { fetchBusinesses } = useContext(BusinessContext);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
  const [open, setOpen] = useState(false);

  const handleShopnow = () => {
    handleClose();
    sectionThreeRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleFeatures = () => {
    sectiontwo.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleFeaturesmobile = () => {
    handleClose();
    sectiontwo.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleSetup = () => {
    sectionHomeScroll.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleSetupmobile = () => {
    handleClose();
    sectionHomeScroll.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleMenuClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNavigate = () => {
    if (isAuthenticated()) { 
      fetchBusinesses();
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
};


  return (
    <Box>
      <Root sx={{ height: { xs: "auto", sm: "796px", xl: "650px" } }}>
        <Box
          sx={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Header
              sx={{
                width: { xs: "100%", sm: "98%" },
                padding: {
                  xs: "16px 4px 16px 4px",
                  sm: "16px 0px 16px 40px",
                  xl: "16px 0px 16px 105px",
                },
              }}
            >
              <Grid container alignItems="center">
                {isMobile && (
                  <Grid item xs={1} sm={0}>
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: { xs: 8, sm: 15 },
                        left: 10,
                        zIndex: 3,
                        px: "0",
                      }}
                      onClick={handleMenuClick}
                    >
                      <MenuIcon fontSize="large" sx={{ color: "white" }} />
                    </IconButton>
                  </Grid>
                )}

                <Grid
                  item
                  xs={9}
                  sm={3}
                  sx={{ textAlign: { xs: "center", sm: "start" } }}
                >
                
                  <Box>
                    <Box
                      component="img"
                      src={logo}
                      sx={{
                        cursor: "pointer",
                        width: { xs: "55%", sm: "auto" },
                      }}
                    />
                  </Box>
                  
                  <Modal open={open} onClose={handleClose}>
                    <Box
                      sx={{
                        width: "80%",
                        maxWidth: "300px",
                        height: "100vh",
                        bgcolor: "#fff",
                        p: 3,
                        position: "fixed",
                        top: 0,
                        left: 0,
                        boxShadow: 4,
                        transform: open ? "translateX(0)" : "translateX(-100%)",
                        transition: "transform 0.4s ease-in-out",
                        zIndex: 5,
                      }}
                    >
                      {/* Cancel Icon */}
                      <IconButton
                        onClick={handleClose}
                        sx={{ position: "absolute", top: 8, right: 8 }}
                      >
                        <CloseIcon />
                      </IconButton>

                      <Box py={{ xs: "20px", sm: "0px" }}>
                        <MobileHeaderText onClick={handleShopnow}>
                          Shop Now
                        </MobileHeaderText>
                        <MobileHeaderText onClick={handleFeaturesmobile}>
                          Features
                        </MobileHeaderText>
                        <MobileHeaderText onClick={handleSetupmobile}>
                          How to Setup
                        </MobileHeaderText>
                        <a
                          href="https://app.pointgenie.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          <MobileHeaderText>Our App</MobileHeaderText>
                        </a>
                        <MobileHeaderText>Business</MobileHeaderText>
                      </Box>
                    </Box>
                  </Modal>
                </Grid>
                <Grid item xs={0} md={6.8} sm={6} lg={5} xl={5}>
                  {!isMobile && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      gap={{ xs: "12px", sm: "0px" }}
                      py={{ xs: "16px", sm: "0px" }}
                    >
                      <HeaderText
                        sx={{
                          fontSize: { xs: "12px", sm: "14px" },
                          lineHeight: { xs: "20px", sm: "33px" },
                        }}
                        onClick={handleShopnow}
                      >
                        Shop Now
                      </HeaderText>
                      <HeaderText
                        sx={{
                          fontSize: { xs: "12px", sm: "14px" },
                          lineHeight: { xs: "20px", sm: "33px" },
                        }}
                        onClick={handleFeatures}
                      >
                        Features
                      </HeaderText>
                      <HeaderText
                        sx={{
                          fontSize: { xs: "12px", sm: "14px" },
                          lineHeight: { xs: "20px", sm: "33px" },
                        }}
                        onClick={handleSetup}
                      >
                        How to Setup
                      </HeaderText>
                      <a
                        href="https://app.pointgenie.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <HeaderText
                          sx={{
                            fontSize: { xs: "12px", sm: "14px" },
                            lineHeight: { xs: "20px", sm: "33px" },
                            color: "white",
                            cursor: "pointer",
                          }}
                        >
                          Our App
                        </HeaderText>
                      </a>

                      <HeaderText
                        sx={{
                          fontSize: { xs: "12px", sm: "14px" },
                          lineHeight: { xs: "20px", sm: "33px" },
                        }}
                      >
                        Business
                      </HeaderText>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={0} sm={1} md={0.2} lg={2} xl={2}></Grid>
                <Grid item xs={2} sm={2} sx={{ textAlign: "center" }}>
                  <Box
                    display={"flex"}
                    gap={{ xs: 1.5, sm: 2 }}
                    justifyContent={"center"}
                    sx={{
                      pt: { xs: "7px", sm: "0px" },
                      mr: { xs: "6px", sm: "0px" },
                    }}
                  >
                    <Box
                      component="img"
                      src={profile}
                      onClick={handleNavigate}
                      sx={{
                        width: { xs: "20px", sm: "22px" },
                        height: { xs: "20px", sm: "22px" },
                        color: "white",
                        cursor: "pointer",
                      }}
                    ></Box>
                     </Box>
                </Grid>
              </Grid>
            </Header>
          </Box>
          <Box
            sx={{
              paddingTop: { xs: "10px", sm: "70px", xl: "90px" },
              mx: { xs: "0", sm: "2" },
              
            }}
          >
            <Grid container alignItems="center" >
              <Grid item md={0.2} xl={0.49}></Grid>
              <Grid
                item
                xs={12}
                sm={6.5}
                md={6}
                xl={6.5}
                pt={{ xs: "10px", sm: "auto" }}
                sx={{ order: { xs: 2, md: 1 }, }}
                px={{ xs: "18px", sm: 5 }}
              >
                <GridText
                  sx={{
                    ps: 3,
                    fontSize: { xs: "26px", sm: "50px", md: "64px" },
                    lineHeight: { xs: "40px", sm: "88px" },
                  }}
                >
                  Seamless Check-Ins
                 
                </GridText>
                <GridText
                   sx={{
                      fontSize: { xs: "26px", sm: "64px" },
                      lineHeight: { xs: "40px", sm: "88px" }, position:"relative"
                      }}
                  >
                    &
                    <GridText
                      component={"span"}
                      sx={{
                        background: "white",
                        px: 1,
                        py: 0,
                        color: "#2B56F5",
                        marginLeft: "12px",
                        fontSize: { xs: "26px", sm: "64px" },
                        lineHeight: { xs: "40px", sm: "88px" },
                       
                      }}
                    >
                      Reviews
                    </GridText>
                    <Typography
                    component={"span"}
                      sx={{
                        position: "absolute",
                        left:{sm:"363px"},
                        top: { sm: "83px" },
                        // left: {  sm: "410px" },
                      }}
                    >
                      <Box component="img" sx={{display:{xs:"none",sm:"block"}}} src={sendarrow}/>
                    </Typography>
                  </GridText>
                <Box
                  display="flex"
                  gap={{ xs: 1, sm: 2 }}
                  mx={{ xs: 0, sm: 2 }}
                  mt={{ xs: 1, sm: 1.5 }}
                >
                  {[...Array(5)].map((_, index) => (
                    <Box
                      component="img"
                      src={Star}
                      sx={{
                        width: { xs: "25px", sm: "55px" },
                        height: { xs: "25px", sm: "55px" },
                      }}
                    />
                  ))}
                </Box>
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "33px",
                    letterSpacing: "1px",
                    width: { xs: "100%", xl: "740px" },
                    pt: 2,
                  }}
                >
                  PointGenie makes customer reviews seamless with NFC
                  technology. No apps, no hassle—just tap and leave a review in
                  seconds. Increase your visibility on Google and gain customer
                  trust effortlessly!
                </Typography>
                <Box
                  sx={{
                    pt: 3,
                    pb: 5,
                    display: "flex",
                    justifyContent: { xs: "center", sm: "start" },
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      background: "white",
                      color: "black",
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "600",
                      padding: { xs: "10px 20px", sm: "12px 28px" },
                      borderRadius: "8px",
                      textTransform: "none",
                      // "&:hover": { background: "#1A44C2" },
                    }}
                    onClick={handleShopnow}
                  >
                    Get Started Now!
                  </Button>
                </Box>
              </Grid>
              <Grid item md={0.3} xl={0.01}></Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={5.5}
                xl={5}
                textAlign={"center"}
                sx={{
                  order: { xs: 1, md: 2 },
                  pl: { xs: "0px", sm: 0 },
                  pr: { xs: "10px", sm: 5 },
                  pt: { xs: "10px", sm: "0px" },
                }}
              >
                <Box
                  component="img"
                  src={maniImage}
                  sx={{
                    width: { xs: "100%", xl: "100%" },
                    height: { xs: "100%",  },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Root>
      <Secondsection sx={{ pt: { xs: "30px", sm: "80px" } }} ref={sectiontwo}>
        <Container>
          {/* <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: "14.45px",
              lineHeight: "18.54px",
              paddingBottom: { sm: "10px" },
            }}
          >
            They Trust us
          </Typography> */}
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: { xs: "18px", sm: "40px" },
              fontWeight: "700",
              lineHeight: "46.34px",
            }}
          >
            Your customers will leave you
            <Typography
            component="span"
              sx={{
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: { xs: "18px", sm: "40px" },
                fontWeight: "700",
                lineHeight: { xs: "20px", sm: "46.34px" },
                display:"flex",
                justifyContent:"center"
              }}
            >
              a Review in Only 3 Seconds!
            </Typography>
          </Typography>
          <Box
            display={{ sm: "flex" }}
            gap={6}
            justifyContent={"center"}
            pt={{ xs: "1", sm: 5 }}
            textAlign={"center"}
          >
            {/* <Box
              component="img"
              src={image5}
              sx={{
                width: { xs: "100%", sm: "auto" },
                pt: { xs: "18px", sm: "0px" },objectFit:"contain"
              }}
            /> */}
            <Box
  component="video"
  src={video} // Use imported video
  // controls
  autoPlay
  loop
  muted
  sx={{
    width: { xs: "100%", sm: "50%" },
    pt: { xs: "18px", sm: "0px" },
    objectFit: "contain"
  }}
/>
            <Box
              display={"flex"}
              flexDirection={{ xs: "row", sm: "column" }}
              gap={{ xs: 5, sm: 4 }}
              pt={{ xs: 5, sm: 1 }}
              pb={{ xs: "30px", sm: "auto" }}
            >
              <Box>
                <Box sx={{ textAlign: "center" }}>
                  <Box
                    component="img"
                    src={featuregoogle}
                    sx={{ width: { xs: "70px", sm: "80px" } }}
                  />
                </Box>

                <Typography
                  sx={{
                    fontSize: { xs: "18px", sm: "24px" },
                    lineHeight: "29px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    pt: { xs: 2, sm: 3 },
                  }}
                >
                  Instant Google Review Access
                </Typography>
                <Typography
                component={"span"}
                  sx={{
                    fontSize: { xs: "12px", sm: "14.45px" },
                    lineHeight: "18.54px",
                    fontWeight: "400",
                    fontFamily: "Montserrat",
                    pt: { xs: 1, sm: 2 },
                    display:"flex"
                  }}
                >
                  Boost Your Online Presence in Seconds with just a tap,
                  customers are instantly directed to your Google review page —
                  no hassle, no delays. Watch your ratings soar!
                </Typography>
              </Box>
              <Box>
                <Box sx={{ textAlign: "center" }}>
                  <Box
                    component="img"
                    src={featuresapps}
                    sx={{ width: { xs: "70px", sm: "80px" } }}
                  />
                </Box>

                <Typography
                  sx={{
                    fontSize: { xs: "18px", sm: "24px" },
                    lineHeight: "29px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    pt: { xs: 2, sm: 3 },
                  }}
                >
                  Seamless Social Media Integration
                </Typography>
                <Typography
                component="span"
                  sx={{
                    fontSize: { xs: "12px", sm: "14.45px" },
                    lineHeight: "18.54px",
                    fontWeight: "400",
                    fontFamily: "Montserrat",
                    pt: { xs: 1, sm: 2 },
                    display:"flex",
                  }}
                >
                  Turn Visitors into Followers Get more likes, shares, and loyal
                  fans. Customers can follow your social media profiles
                  instantly with a simple tap.
                </Typography>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={{ xs: "row", sm: "column" }}
              gap={{ xs: 5, sm: 4 }}
              pt={{ xs: 3, sm: 1 }}
              textAlign={"center"}
            >
              <Box>
                <Box sx={{ textAlign: "center" }}>
                  <Box
                    component="img"
                    src={features}
                    sx={{ width: { xs: "70px", sm: "80px" } }}
                  />
                </Box>

                <Typography
                  sx={{
                    fontSize: { xs: "18px", sm: "24px" },
                    lineHeight: "29px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    pt: 3,
                  }}
                >
                  Touchless Digital Menu Access
                </Typography>
                <Typography
                component={"span"}
                  sx={{
                    fontSize: { xs: "12px", sm: "14.45px" },
                    lineHeight: "18.54px",
                    fontWeight: "400",
                    fontFamily: "Montserrat",
                    pt: { xs: 1, sm: 2 },
                    display:"flex"
                  }}
                >
                  Your Menu, Instantly in Their Hands Forget printed menus —
                  showcase your latest specials, offers, and updates digitally
                  with a seamless NFC experience.
                </Typography>
              </Box>
              <Box>
                <Box sx={{ textAlign: "center" }}>
                  <Box
                    component="img"
                    src={featuresanalytics}
                    sx={{ width: { xs: "70px", sm: "80px" } }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: "18px", sm: "24px" },
                    lineHeight: "29px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    pt: 3,
                  }}
                >
                  ⁠⁠Door Traffic Analytics
                </Typography>
                <Typography
                component="span"
                  sx={{
                    fontSize: { xs: "12px", sm: "14.45px" },
                    lineHeight: "18.54px",
                    fontWeight: "400",
                    fontFamily: "Montserrat",
                    pt: { xs: 1, sm: 2 },
                    display:"flex"
                  }}
                >
                  Understand Your Customer Flow Track foot traffic patterns in
                  real-time. Gain insights into peak hours, customer behavior,
                  and more to optimize staffing and operations.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>

        <Box
          sx={{
            padding: { xs: "40px 20px 50px", sm: "40px 20px 60px 20px" },
            backgroundColor: "#fff",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              pb: "26px",
              fontSize: { xs: "28px", sm: "36px" },
              fontFamily: "Montserrat",
              fontWeight: "700",
            }}
          >
           As seen on
          </Typography>
          <Slider {...settings}>
            {newsList.map((name, index) => (
              <Box key={index} sx={{ textAlign: "center" }}>
                <Typography variant="h5" fontWeight="bold">
                  {name}
                </Typography>
              </Box>
            ))}
          </Slider>
        </Box>
      </Secondsection>
      <SectionThree sectionThreeRef={sectionThreeRef} />
      <Box ref={sectionHomeScroll}>
        <SectionHome />
      </Box>
      <Sectionfive />
      <TweetCard />
      <Footer />
    </Box>
  );
}

export default LayoutDesign;
