import React,{useState} from "react";
import { Box, Button, Typography,Grid } from "@mui/material";
import restaurentimg from "../images/restaurantimg.png";
import accordionicon from "../images/accordionicon.png";
import saveicon from "../images/saveicon.png";
import restaurantlogo from "../images/restaurantlogo.png";
import nexticon from "../images/nexticon.png";
import menustar from "../images/menustar.png";
import call from "../images/call.png";
import globe from "../images/globe.png";
import thumbsup from "../images/thumbsup.png";
import menuitem from "../images/menuitem.png";
import itemimg from "../images/itemimg.png";
import smile from "../images/smile.png";
import smileemoji from "../images/smileemoji.png";
import angryemoji from "../images/angryemoji.png";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import plateimg from "../images/plateimg.png";


const categories = [
  "Starters",
  "Main Courses",
  "Beverages",
  "Desserts",
  "Fast Food",
  "Burgers",
];
const tabItems = ["Feed", "Reviews", "Map", "Photos", "Genie Ai"];
function Ingredients() {
    const [selectedTab, setSelectedTab] = useState(2); // Default selected tab (Map)

  return (
    <Box pb={"40px"}>
      <Box sx={{ position: "relative" }}>
        <Box component="img" src={restaurentimg} width={"100%"} />
        <Box sx={{ position: "absolute", top: "15px", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: "18px",
              width: "100%",
            }}
          >
            <Box
              component="img"
              src={accordionicon}
              width={"36px"}
              height={"36px"}
            />
            <Box display={"flex"} gap={2}>
              <Box
                component="img"
                src={saveicon}
                width={"32px"}
                height={"32px"}
              />
              <Box
                component="img"
                src={nexticon}
                width={"32px"}
                height={"32px"}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ position: "absolute", bottom: "-24px", left: "16px" }}>
          <Box
            component="img"
            src={restaurantlogo}
            width={"61px"}
            height={"61px"}
          />
        </Box>
      </Box>
      <Box sx={{ textAlign: "end", paddingRight: "18px", paddingTop: "12px" }}>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: "14px",
            fontWeight: "500",
            letterSpacing: "0px",
            lineHeight: "14px",
            color: "#03A616",
          }}
        >
          Open Now
        </Typography>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: "12px",
            fontWeight: "500",
            letterSpacing: "0px",
            lineHeight: "16px",
            color: "black",
          }}
        >
          4 Km away
        </Typography>
      </Box>
      <Box sx={{ paddingLeft: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Sansita",
            fontSize: "20px",
            fontWeight: "700",
            letterSpacing: "0px",
            lineHeight: "20px",
            color: "black",
          }}
        >
          Food Pleasure Cafe
        </Typography>
        <Box display={"flex"} gap={0.8} pt={"10px"}>
          <Box component="img" src={menustar} width={"14px"} height={"14px"} />
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "14px",
              fontWeight: "500",
              letterSpacing: "0px",
              lineHeight: "14px",
              color: "black",
            }}
          >
            {" "}
            4.8
            <Typography
              component={"span"}
              sx={{
                fontFamily: "Nunito",
                fontSize: "14px",
                fontWeight: "500",
                letterSpacing: "0px",
                lineHeight: "14px",
                color: "#7A7A7A",
              }}
            >
              {" "}
              (286 reviews)
            </Typography>
          </Typography>
        </Box>
        <Box sx={{display:"flex", justifyContent:"space-between"}}> 
        <Box display={"flex"} gap={0.8} pt={"8px"}>
          <Box component="img" src={call} width={"14px"} height={"14px"} />
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "14px",
              fontWeight: "500",
              letterSpacing: "0px",
              lineHeight: "14px",
              color: "#7A7A7A",
            }}
          >
            + 923160054
          </Typography>
        </Box>
        <Typography sx={{fontFamily:"Sansita", fontSize:"20px",fontWeight:"bold",lineHeight:"20px",paddingRight:"20px"}}>
        $40.00
        </Typography>
        </Box>
       
        <Box display={"flex"} gap={0.8} pt={"8px"}>
          <Box component="img" src={globe} width={"14px"} height={"14px"} />
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "14px",
              fontWeight: "500",
              letterSpacing: "0px",
              lineHeight: "14px",
              color: "#7A7A7A",
            }}
          >
            {" "}
            www.foodpleasure.com
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", my: "18px" }}>
        <Button
          sx={{
            backgroundColor: "#EAF2FF",
            py: "18px",
            width: "90%",
            borderRadius: "8px",
          }}
        >
          <Box
            component="img"
            src={thumbsup}
            width={"14px"}
            height={"14px"}
            color={"#0041A8"}
            marginRight={"8px"}
          />
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "14px",
              fontWeight: "500",
              letterSpacing: "0px",
              lineHeight: "14px",
              color: "#0041A8",
              textTransform: "capitalize",
            }}
          >
            4856 People Likes this Restaurant
          </Typography>
        </Button>
      </Box>
      <Typography
        sx={{
          fontFamily: "Sansita",
          fontSize: "16px",
          fontWeight: "700",
          letterSpacing: "0px",
          lineHeight: "16px",
          color: "#000000",
          pl: "20px",
        }}
      >
        Plate pictures
      </Typography>
   <Grid container justifyContent={"space-between"} p={"20px"}>
    <Grid items xs={5.8}>
<Box component="img" src={plateimg} width={"100%"}/>
    </Grid>
    <Grid items xs={5.8}>
    <Box component="img" src={plateimg} width={"100%"}/>
</Grid>
   </Grid>
    <Typography sx={{fontSize:"16px",fontFamily:"Sansita", lineHeight:"16px",fontWeight:"700", textTransform:"capitalize",px:"20px"}}>
    Ingredients Included
    </Typography>
    <Box sx={{border:"1px solid #EDEDED",m:"20px",py:"16px",px:"10px",borderRadius:"10px"}}>
<Box sx={{display:"flex", justifyContent:"space-between",marginBottom:"16px"}}>
    <Typography sx={{fontSize:"14px",fontFamily:"Nunito", lineHeight:"16px",fontWeight:"700", textTransform:"capitalize",}}>Ingredient Name </Typography>
    <Typography sx={{fontSize:"14px",fontFamily:"Nunito", lineHeight:"16px",fontWeight:"600",color:"#a7a7a7"}}>100 gram </Typography>
</Box>
<Box sx={{display:"flex", justifyContent:"space-between",marginBottom:"16px"}}>
    <Typography sx={{fontSize:"14px",fontFamily:"Nunito", lineHeight:"16px",fontWeight:"700", textTransform:"capitalize",}}>Ingredient Name </Typography>
    <Typography sx={{fontSize:"14px",fontFamily:"Nunito", lineHeight:"16px",fontWeight:"600",color:"#a7a7a7"}}>100 gram </Typography>
</Box>
<Box sx={{display:"flex", justifyContent:"space-between",marginBottom:"16px"}}>
    <Typography sx={{fontSize:"14px",fontFamily:"Nunito", lineHeight:"16px",fontWeight:"700", textTransform:"capitalize",}}>Ingredient Name </Typography>
    <Typography sx={{fontSize:"14px",fontFamily:"Nunito", lineHeight:"16px",fontWeight:"600",color:"#a7a7a7"}}>100 gram </Typography>
</Box>
<Box sx={{display:"flex", justifyContent:"space-between"}}>
    <Typography sx={{fontSize:"14px",fontFamily:"Nunito", lineHeight:"16px",fontWeight:"700", textTransform:"capitalize",}}>Ingredient Name </Typography>
    <Typography sx={{fontSize:"14px",fontFamily:"Nunito", lineHeight:"16px",fontWeight:"600",color:"#a7a7a7"}}>100 gram </Typography>
</Box>
    </Box>
    <Box sx={{display:"flex",flexDirection:"column", px:"20px",}}>
        <Button sx={{backgroundColor:"#03A616",py:"18px",borderRadius:"10px"}}>
            <Typography sx={{fontSize:"14px",fontFamily:"Nunito", lineHeight:"16px",fontWeight:"500", textTransform:"capitalize",color:"#FFFFFF"}}>
                Leave a Review
            </Typography>
        </Button>
         <Button sx={{backgroundColor:"#0041A8",py:"18px",borderRadius:"10px",marginTop:"13px"}}>
            <Typography sx={{fontSize:"14px",fontFamily:"Nunito", lineHeight:"16px",fontWeight:"500", textTransform:"capitalize",color:"#FFFFFF"}}>
               Check In
            </Typography>
        </Button>
    </Box>
    </Box>
  );
}

export default Ingredients;
