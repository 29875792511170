import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  Modal,
  CircularProgress,
  Menu,
  MenuItem,
  ClickAwayListener,
  Grid,
  Avatar,
  Chip,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import saveicon from "../images/saveicon.png";
import nexticon from "../images/nexticon.png";
import menustar from "../images/menustar.png";
import call from "../images/call.png";
import thumbsup from "../images/thumbsup.png";
import menuitem from "../images/menuitem.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircleIcon from "@mui/icons-material/Circle";
import axiosInstance from "./axios";
import { useMediaQuery } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import grouplogo from "../images/Grouplogo.png";
import TIKTOK from "../images/TIKTOK.png";
import StarIcon from "@mui/icons-material/Star";
import google from "../images/google.png";
import PhoneIcon from "@mui/icons-material/Phone";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axis from "../images/axis.png";
import LinkIcon from "@mui/icons-material/Link";
import HubIcon from "@mui/icons-material/Hub";
import LanIcon from "@mui/icons-material/Lan";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LanguageIcon from "@mui/icons-material/Language";

const tabItems = [
  // "Feed",
  "Reviews",
  // , "Map"
  // , "Photos"
  // , "Genie Ai"
];

const Test = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [categoriesData, setCategoriesData] = useState([]);
  const [menuItemsData, setMenuItemsData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});
  const [getBusinessData, setGetBusinessData] = useState({});
  const [placeId, setPlaceId] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [businessId, setBusinessId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:500px)");
  const [categoriesloading, setCategoriesLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuLoader, setMenuLoader] = useState(false);
  const currentUrl = window.location.href;
  const [Followusopen, setFollowusOpen] = useState(false);

  // Function to toggle modal
  const handleFollowusOpen = () => setFollowusOpen(true);
  const handleFollowusClose = () => setFollowusOpen(false);

  // const { selectedBusinessId } = useContext(BusinessContext);
  // const [images, setImages] = useState([]);
  // console.log("businessid", selectedBusinessId);


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <Box sx={{ display: "flex", justifyContent: "flex-end", pr: 2 }}>
        {dots}
      </Box>
    ),
    customPaging: (i) => (
      <Box
        sx={{
          width: 8,
          height: 8,
          bgcolor: "orange",
          borderRadius: "50%",
          opacity: 0.5,
        }}
      />
    ),
  };

  useEffect(() => {
    const fetchBusinessData = async () => {
      setLoading(true);
      setIsPageLoading(true);
      try {
        const response = await axiosInstance.post(
          "payments.php?method=fetch_business",
          { plate_code: code }
        );
        if (
          response.data?.status === "success" &&
          response.data?.place_details
        ) {
          setGetBusinessData(response.data.place_details);
        } else {
          console.error("Invalid API response structure:", response.data);
        }

        const fetchedPlaceId = response.data?.place_details?.place_id;
        if (fetchedPlaceId) {
          setPlaceId(fetchedPlaceId);
        }

        const business = response?.data?.id;
        if (business) {
          setBusinessId(business);
        }
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        setLoading(false);
        setIsPageLoading(false);
      }
    };
    fetchBusinessData();
  }, [code]);
  useEffect(() => {
    if (!businessId) return;
    const fetchCategories = async () => {
      setCategoriesLoading(true);
      try {
        const response = await axiosInstance.post(
          "payments.php?method=menu_categories",
          {
            // place_id: placeId
            business_id: businessId,
          }
        );
        setCategoriesData(response.data.categories || []);
      } catch (error) {
        console.log("error");
      } finally {
        setCategoriesLoading(false);
      }
    };

    fetchCategories();
  }, [businessId]);

  useEffect(() => {
    if (categoriesData.length > 0) {
      menuItems(categoriesData[0].category_id);
    }
  }, [categoriesData]);

  const menuItems = async (category_id) => {
    setSearchTerm("");
    setMenuLoader(true);
    try {
      const itemResponse = await axiosInstance.post(
        "payments.php?method=category_menu_items",
        {
          business_id: businessId,
          business_menu_category_id: category_id,
        }
      );
      setMenuItemsData(itemResponse.data.items || []);
    } catch {
      console.log("error");
    }
    setMenuLoader(false);
  };

  const displayedMenuItems = searchTerm
    ? menuItemsData.filter(
        (item) =>
          item.menu_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.menu_description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : menuItemsData;

  const handleToggle = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosesocialmedia = () => {
    setAnchorEl(null);
  };

  const handleSocialClick = (platform) => {
    let shareUrl = "";

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      case "instagram":
        shareUrl = `https://www.instagram.com/`; // Instagram doesn't allow direct text sharing
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      case "telegram":
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      default:
        break;
    }

    // Open the URL in a new tab
    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }

    handleClosesocialmedia(); // Close the dropdown after clicking
  };

  return (
    <Box pb={"40px"}>
      {isPageLoading ? (
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            // backgroundColor: "#f0f0f0",
            padding: "20px",
          }}
        >
          <Box sx={{ display: "flex ", justifyContent: "space-between" }}>
            <Typography
              sx={{
                width: "28px",
                height: "28px",
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.8s infinite",
                borderRadius: "25px",
                "@keyframes shimmer": {
                  "0%": { backgroundPosition: "-200% 0" },
                  "100%": { backgroundPosition: "200% 0" },
                },
              }}
            ></Typography>
            <Box display={"flex"} alignItems={"center"} gap={"3px"}>
              <Typography
                sx={{
                  width: "28px",
                  height: "28px",
                  background:
                    "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                  backgroundSize: "200% 100%",
                  animation: "shimmer 1.8s infinite",
                  borderRadius: "25px",
                  "@keyframes shimmer": {
                    "0%": { backgroundPosition: "-200% 0" },
                    "100%": { backgroundPosition: "200% 0" },
                  },
                }}
              ></Typography>
              <Typography
                sx={{
                  width: "40px",
                  height: "20px",
                  background:
                    "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                  backgroundSize: "200% 100%",
                  animation: "shimmer 1.8s infinite",
                  borderRadius: "6px",
                  "@keyframes shimmer": {
                    "0%": { backgroundPosition: "-200% 0" },
                    "100%": { backgroundPosition: "200% 0" },
                  },
                }}
              ></Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "2px solid #e0e0e0",
              borderRadius: "8px",
              p: "10px",
              mt: "10px",
            }}
          >
            <Typography
              sx={{
                width: "160px",
                height: "18px",
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.8s infinite",
                borderRadius: "6px",
                "@keyframes shimmer": {
                  "0%": { backgroundPosition: "-200% 0" },
                  "100%": { backgroundPosition: "200% 0" },
                },
              }}
            ></Typography>

            <Typography
              sx={{
                width: "150px",
                height: "20px",
                mt: "10px",
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.8s infinite",
                borderRadius: "6px",
                "@keyframes shimmer": {
                  "0%": { backgroundPosition: "-200% 0" },
                  "100%": { backgroundPosition: "200% 0" },
                },
              }}
            ></Typography>

            <Typography
              sx={{
                width: "150px",
                height: "20px",
                mt: "10px",
                background:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "shimmer 1.8s infinite",
                borderRadius: "6px",
                "@keyframes shimmer": {
                  "0%": { backgroundPosition: "-200% 0" },
                  "100%": { backgroundPosition: "200% 0" },
                },
              }}
            ></Typography>
          </Box>
          {/* Shimmer Text */}
          <Box
            sx={{
              width: "100%",
              height: "70px",
              mt: "15px",
              background:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "shimmer 1.8s infinite",
              borderRadius: "14px",
              "@keyframes shimmer": {
                "0%": { backgroundPosition: "-200% 0" },
                "100%": { backgroundPosition: "200% 0" },
              },
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "#f3f3f3",
            borderRadius: "0px 0px 28px 28px",
            pb: "20px",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{ width: "100%", height: "45px", position: "relative" }}
            ></Box>
            <Box sx={{ position: "absolute", top: "15px", width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: "18px",
                  width: "100%",
                }}
              >
                <IconButton
                  onClick={() => navigate(`/place/${code}`)}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    width: "25px",
                    height: "25px",
                    boxShadow: "0px 2px 5px rgba(0,0,0,0.2)", // Optional shadow
                    "&:hover": {
                      backgroundColor: "#f0f0f0", // Slightly darker on hover
                    },
                  }}
                >
                  <ArrowBackIosIcon sx={{ color: "black", fontSize: 20 }} />
                </IconButton>
                <Box>
                  <Box display={"flex"} gap={0.5} alignItems={"center"}>
                    <Box
                      component="img"
                      src={nexticon}
                      width={"32px"}
                      height={"32px"}
                      onClick={handleClick}
                      sx={{ cursor: "pointer" }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        fontWeight: "500",
                        letterSpacing: "0px",
                        lineHeight: "14px",
                        color: "black",
                      }}
                      onClick={handleClick}
                    >
                      Share
                    </Typography>
                    {/* Dropdown Menu */}
                    {/* <ClickAwayListener onClickAway={handleClose}> */}
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          mt: 1,
                          borderRadius: "8px",
                          boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                          width: "200px",
                          p: "5px",
                        },
                      }}
                    >
                      {/* WhatsApp */}
                      <MenuItem
                        onClick={() => handleSocialClick("whatsapp")}
                        sx={{ gap: 1 }}
                      >
                        <WhatsAppIcon sx={{ color: "#25D366" }} />
                        <Typography>WhatsApp</Typography>
                      </MenuItem>

                      {/* Instagram */}
                      <MenuItem
                        onClick={() => handleSocialClick("instagram")}
                        sx={{ gap: 1 }}
                      >
                        <InstagramIcon sx={{ color: "#E4405F" }} />
                        <Typography>Instagram</Typography>
                      </MenuItem>

                      {/* {facebook} */}

                      <MenuItem
                        onClick={() => handleSocialClick("facebook")}
                        sx={{ gap: 1 }}
                      >
                        <FacebookIcon sx={{ color: "#E4405F" }} />
                        <Typography>Facebook</Typography>
                      </MenuItem>

                      {/* Twitter */}
                      <MenuItem
                        onClick={() => handleSocialClick("twitter")}
                        sx={{ gap: 1 }}
                      >
                        <TwitterIcon sx={{ color: "#1DA1F2" }} />
                        <Typography>Twitter</Typography>
                      </MenuItem>

                      {/* Telegram */}
                      <MenuItem
                        onClick={() => handleSocialClick("telegram")}
                        sx={{ gap: 1 }}
                      >
                        <TelegramIcon sx={{ color: "#0088CC" }} />
                        <Typography>Telegram</Typography>
                      </MenuItem>
                    </Menu>
                    {/* </ClickAwayListener> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ paddingRight: "0px", paddingTop: "12px" }}></Box>
          <Card
            sx={{
              gap: "4px",
              display: "flex",
              alignItems: "start",
              mx: "18px",
              py: "14px",
              border: "1px solid rgb(236, 236, 236)",
              borderRadius: "20px",
              boxShadow: "2px 5px 6px  rgba(2, 2, 2, 0.1)",
              backgroundColor: "#fff",
              //   mb:"10px"
            }}
          >
            <Box sx={{ px: "15px", width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <Box
                    sx={{
                      width: "180px",
                      height: "18px",
                      background:
                        "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                      backgroundSize: "200% 100%",
                      animation: "shimmer 1.5s infinite",
                      borderRadius: "4px",
                      "@keyframes shimmer": {
                        "0%": { backgroundPosition: "-200% 0" },
                        "100%": { backgroundPosition: "200% 0" },
                      },
                    }}
                  />
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "Sansita",
                      fontSize: "20px",
                      fontWeight: "700",
                      letterSpacing: "0px",
                      lineHeight: "20px",
                      color: "black",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {getBusinessData?.name}
                  </Typography>
                )}
              </Box>
              <Grid container gap={"5px"} justifyContent={"space-between"}>
                <Grid xs={12}>
                  <Box display={"flex"} gap={0.8} pt={"6px"}>
                    {loading ? (
                      <Box
                        sx={{
                          width: "160px",
                          height: "16px",
                          background:
                            "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                          backgroundSize: "200% 100%",
                          animation: "shimmer 1.5s infinite",
                          borderRadius: "4px",
                          "@keyframes shimmer": {
                            "0%": { backgroundPosition: "-200% 0" },
                            "100%": { backgroundPosition: "200% 0" },
                          },
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "start",
                              justifyContent: "",
                              gap: "6px",
                            }}
                          >
                            <Box component="img" src={menustar} pt={"2px"} />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                // lineHeight: "25.88px",
                                letterSpacing: "-0.65px",
                                color: "#F97731",
                                display: "flex",
                                alignItems: "start",
                              }}
                            >
                              {getBusinessData?.rating || "0.0"}
                              <Box
                                component="span"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  fontFamily: "Roboto",
                                  // lineHeight: "25.88px",
                                  letterSpacing: "-0.3px",
                                  color: "#454343",
                                  paddingLeft: "4px",
                                }}
                              >
                                ({getBusinessData?.review_count} reviews)
                              </Box>
                            </Typography>
                          </Box>
                          {loading ? (
                            <Box
                              sx={{
                                width: "160px",
                                height: "16px",
                                background:
                                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                                backgroundSize: "200% 100%",
                                animation: "shimmer 1.5s infinite",
                                borderRadius: "4px",
                                mt: "4px",
                                "@keyframes shimmer": {
                                  "0%": { backgroundPosition: "-200% 0" },
                                  "100%": { backgroundPosition: "200% 0" },
                                },
                              }}
                            />
                          ) : (
                            <>
                              {getBusinessData?.website && (
                                <Box>
                                  <Typography
                                    sx={{
                                      fontFamily: "Roboto",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      letterSpacing: "0px",
                                      lineHeight: "14px",
                                      color: "#0041A8",
                                      wordBreak: "break-word",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    <a
                                      href={getBusinessData?.website}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                      }}
                                    >
                                      <LanguageIcon
                                        sx={{
                                          color: "#0041A8",
                                          fontSize: "18px",
                                          mr: "4px",
                                        }}
                                      />
                                      Website
                                    </a>
                                  </Typography>
                                </Box>
                              )}

                              <Box
                                display={"flex"}
                                gap={0.8}
                                pt={"4px"}
                                pb={"8px"}
                              >
                                {/* <Box component="img" src={call} width={"14px"} height={"14px"} /> */}
                                <PhoneIcon
                                  fontSize="14px"
                                  sx={{ color: "#0041A8" }}
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "Roboto",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    letterSpacing: "0px",
                                    lineHeight: "14px",
                                    color: "#0041A8",
                                    cursor: "pointer",
                                    wordBreak: "break-word",
                                    overflowWrap: "break-word",
                                  }}
                                  onClick={() =>
                                    window.open(
                                      `tel:${getBusinessData.telephone}`
                                    )
                                  }
                                >
                                  {getBusinessData?.telephone ||
                                    "No phone number available"}
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Box sx={{ textAlign: "center" }}>
                            <Button
                              sx={{
                                color: "white",
                                width: "100%",
                                fontSize: "9px",
                                fontFamily: "Roboto",
                                fontWeight: "500",
                                backgroundColor: "#0041A8",
                                textTransform: "capitalize",
                                borderRadius: "8px",
                                py: "6px",
                                "@media (max-width:367px)": {
                                  width: "100%",
                                  px: "0px",
                                },
                              }}
                              onClick={() => {
                                const url = `https://search.google.com/local/writereview?placeid=${getBusinessData?.place_id}`;
                                window.open(url, "_blank");
                              }}
                            >
                              <img
                                src={google}
                                alt="Google"
                                style={{
                                  width: "12px",
                                  height: "auto",
                                  marginRight: "6px",
                                }}
                              />
                              Leave us a Google Review
                            </Button>
                            {getBusinessData?.provider &&
                              Object.keys(getBusinessData.provider).length >
                                0 && (
                                <Button
                                  sx={{
                                    color: "white",
                                    width: "100%",
                                    fontSize: "10px",
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    backgroundColor: "#0041A8",
                                    textTransform: "capitalize",
                                    borderRadius: "8px",
                                    py: "6px",
                                    mt: "4px",
                                    "@media (max-width:367px)": {
                                      width: "100%",
                                      px: "0px",
                                    },
                                    "& > svg": {
                                      mr: "1px", // Adds space between text and first icon
                                    },
                                  }}
                                  onClick={handleFollowusOpen}
                                >
                                  <KeyboardDoubleArrowRightIcon
                                    sx={{ fontSize: "18px", ml: "0px" }}
                                  />
                                  Connect With Us
                                </Button>
                              )}
                          </Box>
                          <Dialog
                            open={Followusopen}
                            onClose={handleFollowusClose}
                            fullWidth
                            maxWidth="sm"
                          >
                            <DialogContent
                              sx={{ p: "20px", position: "relative" }}
                            >
                              {/* Close Icon at Top Right Corner */}
                              <IconButton
                                onClick={handleFollowusClose}
                                sx={{
                                  position: "absolute",
                                  top: 1,
                                  right: 1,
                                  color: "gray",
                                }}
                              >
                                <CloseIcon />
                              </IconButton>

                              {/* Heading Text */}
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "Roboto",
                                  color: "gray",
                                  mb: 2,
                                }}
                              >
                                Connect With Us and Share Your Feedback. Your
                                Opinion Matters to Us!
                              </Typography>

                              {/* Social Media Links */}
                              {getBusinessData?.provider &&
                                (() => {
                                  const validProviders = Object.entries(
                                    getBusinessData.provider
                                  )
                                    .filter(
                                      ([provider, data]) =>
                                        data.proivder_link &&
                                        provider.toLowerCase() !== "google"
                                    )
                                    .map(([provider, data]) => {
                                      let providerIcon;
                                      let providerName = data.proivder_label; // Use the label from data
                                      let providerUrl = data.proivder_link; // Use the link from data
                                      if (provider.toLowerCase() === "yelp") {
                                        providerIcon = (
                                          <StarIcon
                                            sx={{
                                              color: "#D32323",
                                              fontSize: 30,
                                            }}
                                          />
                                        );
                                      } else if (
                                        provider.startsWith("customprovider_")
                                      ) {
                                        providerIcon = (
                                          <InsertLinkIcon
                                            sx={{
                                              color: "#0041A8",
                                              fontSize: 30,
                                            }}
                                          />
                                        );
                                      } else {
                                        switch (provider.toLowerCase()) {
                                          case "facebook":
                                            providerIcon = (
                                              <FacebookIcon
                                                sx={{
                                                  color: "#1877F2",
                                                  fontSize: 30,
                                                }}
                                              />
                                            );
                                            providerName = "Facebook";
                                            break;
                                          case "instagram":
                                            providerIcon = (
                                              <InstagramIcon
                                                sx={{
                                                  color: "#E4405F",
                                                  fontSize: 30,
                                                }}
                                              />
                                            );
                                            providerName = "Instagram";
                                            break;
                                          case "tiktok":
                                            providerIcon = (
                                              <Box
                                                component="img"
                                                src={TIKTOK}
                                                sx={{ width: 30, height: 30 }}
                                              />
                                            );
                                            providerName = "TikTok";
                                            break;
                                          case "whatsapp":
                                            providerIcon = (
                                              <WhatsAppIcon
                                                sx={{
                                                  color: "#25D366",
                                                  fontSize: 30,
                                                }}
                                              />
                                            );
                                            providerName = "WhatsApp";
                                            break;
                                          case "yelp":
                                            providerIcon = (
                                              <StarIcon
                                                sx={{
                                                  color: "#D32323",
                                                  fontSize: 30,
                                                }}
                                              />
                                            );
                                            providerName = "Yelp";
                                            break;
                                          case "tripadvisor":
                                            providerIcon = (
                                              <StarIcon
                                                sx={{
                                                  color: "#00AF87",
                                                  fontSize: 30,
                                                }}
                                              />
                                            );
                                            providerName = "TripAdvisor";
                                            break;
                                          case "pointgenie":
                                            providerIcon = (
                                              <Box
                                                component="img"
                                                src={grouplogo}
                                                sx={{ width: 30, height: 30 }}
                                              />
                                            );
                                            providerName = "PointGenie";
                                            break;
                                          default:
                                            return null;
                                        }
                                      }
                                      return {
                                        provider,
                                        providerUrl,
                                        providerIcon,
                                        providerName,
                                      };
                                    })
                                    .filter(Boolean); // Remove nulls

                                  const pairs = [];
                                  for (
                                    let i = 0;
                                    i < validProviders.length;
                                    i += 2
                                  ) {
                                    pairs.push(validProviders.slice(i, i + 2));
                                  }

                                  return pairs.map((pair, index) => (
                                    <Grid
                                      container
                                      key={index}
                                      sx={{
                                        px: { xs: "", sm: "20px" },
                                        mb: "5px",
                                        gap: "4px",
                                      }}
                                    >
                                      {pair.map((item) => (
                                        <Grid
                                          item
                                          xs={
                                            pair.length === 1 &&
                                            validProviders.length > 2
                                              ? 12
                                              : 5.8
                                          }
                                          key={item.provider}
                                          sx={{
                                            border: "1px solid #0041A8",
                                            borderRadius: "8px",
                                            py: "2px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            gap: "6px",
                                          }}
                                          onClick={() => {
                                            if (
                                              item.provider.toLowerCase() ===
                                              "whatsapp"
                                            ) {
                                              const whatsappUrl = `https://wa.me/${item.url.replace(
                                                /\D/g,
                                                ""
                                              )}`;
                                              window.open(
                                                whatsappUrl,
                                                "_blank"
                                              );
                                            } else {
                                              window.open(
                                                item.providerUrl.startsWith(
                                                  "http"
                                                )
                                                  ? item.providerUrl
                                                  : `https://${item.providerUrl}`,
                                                "_blank"
                                              );
                                            }
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {item.providerIcon}
                                          </Box>
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                xs: "13px",
                                                sm: "14px",
                                              },
                                              fontWeight: "700",
                                              fontFamily: "Roboto",
                                              lineHeight: "14px",
                                              color: "#0041A8",
                                            }}
                                          >
                                            {item.providerName}
                                          </Typography>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  ));
                                })()}
                            </DialogContent>
                          </Dialog>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Box>
      )}

      {getBusinessData?.specials?.length > 0 && (
        <Box
          sx={{ borderRadius: 2, overflow: "hidden", mx: "20px", mt: "10px" }}
        >
          <Slider {...settings}>
            {getBusinessData?.specials?.map((coupon, index) => (
              <Box
                key={coupon.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  px: 2,
                  py: 2,
                  border: "1px solid #ddd",
                  borderRadius: "20px",
                  backgroundColor: "#fff",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexGrow: 1,
                  }}
                >
                  <Box sx={{ display: "flex", gap: "8px" }}>
                    <Box
                      component="img"
                      src={coupon.image}
                      width={"30px"}
                      height={"30px"}
                      borderRadius={"25px"}
                    />
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontWeight: "medium",
                          fontSize: "18px",
                          fontFamily: "Roboto",
                          lineHeight: "14px",
                        }}
                      >
                        {coupon.menu_name}
                      </Typography>
                      <Typography
  variant="caption"
  sx={{
    cursor: "pointer",
    textTransform: "capitalize",
    fontWeight: "medium",
    fontSize: "12px",
    fontFamily: "Roboto",
    lineHeight: "14px",
    color: "gray",
  }}
>
  <span style={{ color: "black" }}>{coupon.coupon_code}</span> | Before {coupon.expiration_date}
</Typography>


                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      color: "orange",
                      fontWeight: "bold",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                  >
                    {`${index + 1}/${getBusinessData?.specials?.length}`}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      )}
      {categoriesData?.length > 0 && (
        <Typography
          sx={{
            fontFamily: "Sansita",
            fontSize: "16px",
            fontWeight: "700",
            letterSpacing: "0px",
            lineHeight: "16px",
            color: "#000000",
            pl: "24px",
            mt: 2,
          }}
        >
          Menu
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          whiteSpace: "nowrap",
          gap: "5px",
          px: 1.6,
          py: 1.3,
          "&::-webkit-scrollbar": { height: "2px" }, // Show scrollbar when needed
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: " #888", // Color of the scrollbar
            borderRadius: "4px",
          },
          borderBottom: "1px solid #DDDDDD",
        }}
      >
        {categoriesloading ? (
          <Box sx={{ display: "flex", gap: 1 }}>
            {[...Array(5)].map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: "80px",
                  height: "18px",
                  background:
                    "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                  backgroundSize: "200% 100%",
                  animation: "shimmer 1.5s infinite",
                  borderRadius: "4px",
                  "@keyframes shimmer": {
                    "0%": { backgroundPosition: "-200% 0" },
                    "100%": { backgroundPosition: "200% 0" },
                  },
                }}
              />
            ))}
          </Box>
        ) : (
          categoriesData.map((category, index) => (
            <Button
              onClick={() => {
                setActiveIndex(index);
                menuItems(category.category_id, index);
              }}
              key={category.category_id}
              sx={{
                cursor: "pointer",
                textTransform: "capitalize",
                fontWeight: "medium",
                color: activeIndex === index ? "#0041A8" : "#7A7A7A",
                fontSize: "14px",
                fontFamily: "Roboto",
                lineHeight: "14px",
                flexShrink: 0,
              }}
            >
              {category.name}
            </Button>
          ))
        )}
      </Box>
      <Box
        sx={{
          padding: "22px 18px 6px 15px",
          display: "flex",
          gap: "20px",
          width: { xs: "98%", sm: "50%" },

          mx: "auto",
        }}
      >
        {menuItemsData?.length > 0 && (
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="search"
            variant="outlined"
            placeholder="Search"
            size="small"
            sx={{
              flex: 1,
              // padding: "6.5px 14px",
              borderRadius: "20px",
              "& fieldset": {
                borderWidth: "2px",
                borderColor: "#ccc",
                borderRadius: "50px",
              },
            }}
            InputProps={{
              sx: {
                paddingRight: "6px",
              },
              endAdornment: (
                <InputAdornment position="end" sx={{}}>
                  <IconButton
                    size="small"
                    sx={{
                      backgroundColor: "#ccc",
                      // color: "",
                      width: "25px",
                      height: "25px",
                    }}
                  >
                    <SearchIcon sx={{ fontSize: "18px" }} />
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: {
                sx: {
                  padding: "5px 14px", // Adjust padding as per your need
                },
              },
            }}
          />
        )}
      </Box>

      <Box sx={{ p: "18px 20px 10px 20px" }}>
        {menuLoader ? (
          Array.from({ length: 4 }).map((_, index) => (
            <Box
              key={index}
              sx={{
                border: "1px solid #EDEDED",
                borderRadius: "10px",
                px: "12px",
                py: "10px",
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Box
                  sx={{
                    width: "120px",
                    height: "16px",
                    borderRadius: "4px",
                    background:
                      "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                    backgroundSize: "200% 100%",
                    animation: "shimmer 1.5s infinite",
                  }}
                />
                <Box
                  sx={{
                    width: "80px",
                    height: "14px",
                    borderRadius: "4px",
                    background:
                      "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                    backgroundSize: "200% 100%",
                    animation: "shimmer 1.5s infinite",
                    mt: "10px",
                  }}
                />
                <Box
                  sx={{
                    width: "150px",
                    height: "14px",
                    borderRadius: "4px",
                    background:
                      "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                    backgroundSize: "200% 100%",
                    animation: "shimmer 1.5s infinite",
                    mt: "8px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "100px",
                  height: "93px",
                  borderRadius: "6px",
                  background:
                    "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                  backgroundSize: "200% 100%",
                  animation: "shimmer 1.5s infinite",
                }}
              />
            </Box>
          ))
        ) : menuItemsData === null ? (
          <></>
        ) : menuItemsData && displayedMenuItems.length > 0 ? (
          displayedMenuItems.map((item, index) => (
            <Box
              sx={{
                border: "1px solid #EDEDED",
                borderRadius: "10px",
                px: "12px",
                py: "10px",
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
              //  onClick={()=>navigate("/ingredients")}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    color: "#000000",
                  }}
                  onClick={() => handleOpen(item)}
                >
                  {item.menu_name?.charAt(0).toUpperCase() +
                    item.menu_name?.slice(1) || ""}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    pt: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "14px",
                      //   color: "#7A7A7A",
                    }}
                    onClick={() => handleOpen(item)}
                  >
                    ${item.price}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "18px",
                    color: "#7A7A7A",
                    pt: "8px",
                  }}
                >
                  {expandedItems[index] ? (
                    <>
                      {item.menu_description?.charAt(0).toUpperCase() +
                        item.menu_description?.slice(1) || ""}

                      <Typography
                        component="span"
                        onClick={() => handleToggle(index)}
                        sx={{
                          color: "blue",
                          cursor: "pointer",
                          fontSize: "12px",
                          p: "0",
                        }}
                      >
                        ...less
                      </Typography>
                    </>
                  ) : (
                    <>
                      {item.menu_description?.charAt(0).toUpperCase() +
                        item.menu_description
                          ?.slice(1)
                          .split(" ")
                          .slice(0, 6)
                          .join(" ") || ""}

                      {item.menu_description.trim().split(/\s+/).length > 6 && (
                        <Typography
                          component="span"
                          onClick={() => handleToggle(index)}
                          sx={{
                            color: "blue",
                            cursor: "pointer",
                            fontSize: "12px",
                            p: "0",
                          }}
                        >
                          ...more
                        </Typography>
                      )}
                    </>
                  )}
                </Typography>
              </Box>
              <Box
                component="img"
                src={item.image}
                width={"100px"}
                height={"93px"}
                sx={{ objectFit: "cover" }}
                onClick={() => handleOpen(item)}
              />
            </Box>
          ))
        ) : (
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "16px",
              color: "#7A7A7A",
              textAlign: "center",
            }}
          >
            No items available
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", px: "20px" }}></Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            pb: "35px",
            borderTopLeftRadius: "35px",
            borderTopRightRadius: "35px",
            maxHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "black",
              backgroundColor: "white",
              width: "28px",
              height: "28px",
            }}
          >
            <CloseIcon sx={{ fontSize: "18px" }} />
          </IconButton>
          {selectedItem && (
            <>
              <Box
                component="img"
                // src={selectedItem.image}
                src={selectedItem.image || menuitem}
                width={"100%"}
                height={"100%"}
                sx={{}}
              />
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "16px",
                  color: "#000000",
                  px: "16px",
                  pt: "16px",
                }}
              >
                {selectedItem.menu_name}
              </Typography>
              <Box
                sx={{
                  pt: "8px",
                  px: "18px",
                }}
              ></Box>
              <Box
                sx={{
                  pb: "5px",
                  px: "18px",
                }}
              ></Box>

              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "14px",
                  color: "#000000",
                  px: "16px",
                  // py: "16px",
                }}
              >
                ${selectedItem.price}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "17px",
                  color: "#7A7A7A",
                  pt: "6px",
                  px: "16px",
                }}
              >
                {selectedItem.menu_description}
              </Typography>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default Test;
