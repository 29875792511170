import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  TextField,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@mui/material";
import { LocationOn } from "@mui/icons-material"; // for the location icon
import "./Register.css";

const NewPlate = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ backgroundColor: "#122272", height: "100vh" }}>
      <Container maxWidth="sm" sx={{ padding: 0, flexGrow: 1 }}>
        <Box
          className="heading"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            pt: 5,
            pb: 3,
          }}
        >
          <i
            className="fa-sharp fa-solid fa-location-dot"
            style={{ fontSize: "30px", color: "white" }}
          ></i>
          <Typography
            variant="h2"
            sx={{ fontSize: "28px", fontFamily: "Goldplay-bold" }}
            className="fw-bold text-white  mb-0"
          >
            PointGenie
          </Typography>
        </Box>
        <Box
          className="register-card"
          sx={{
            backgroundColor: "white",
            borderRadius: "35px",
            padding: "35px 35px 40px ",
          }}
        >
          <Typography
            variant="h6"
            className="configure"
            sx={{
              fontFamily: "Goldplay-bold",
              fontSize: { xs: "16px", sm: "18px" },
              paddingBottom: "10px",
            }}
          >
            Configuring a new plate
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "14px", sm: "16px" },

              fontFamily: "Goldplay",
              lineHeight: "1.3",
            }}
          >
            Step 2: Enter the code shown under the QR Code on your Pointgenie
            Plate
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: { xs: "16px", sm: "18px" },
              mt: 2,
              fontFamily: "Goldplay-bold",
            }}
          >
            Code
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Example: Bocas Doral, Miami"
            sx={{
              borderRadius: "10px",
              fontWeight: 500,
              marginTop: 2,
              input: {
                padding: "10px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "10px",
                },
              }, '& .MuiOutlinedInput-input::placeholder': {
                fontSize: '14px',
                fontFamily:'Goldplay'
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              borderRadius: "50px",
              mt: 3,
              fontWeight: "semibold",
              fontSize: "16px",
              padding: "5px",
              fontFamily: "Goldplay-bold",
              textTransform: "capitalize",
              width: "50%",
            }}
            onClick={() => navigate("/product")}
          >
            Continue
          </Button>
          <Box>
            <Typography
              variant="h6"
              sx={{
                pt: 4,
                pb: 1,
                fontSize: { xs: "16px", sm: "18px" },
                fontFamily: "Goldplay-bold",
              }}
            >
              Your Plates
            </Typography>
            <TableContainer sx={{ borderRadius: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontFamily: "Goldplay-bold",
                        fontSize: { xs: "14px", sm: "16px" },
                        color: "rgb(155, 154, 154)",
                      }}
                    >
                      Code
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Goldplay-bold",
                        fontSize: { xs: "14px", sm: "16px" },
                        color: "rgb(155, 154, 154)",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Goldplay-bold",
                        fontSize: { xs: "14px", sm: "16px" },
                        padding: { xs: "0" },
                        color: "rgb(155, 154, 154)",
                      }}
                    >
                      Number of Scans
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow >
                  <TableCell sx={{ fontFamily:'Goldplay-bold', fontSize:{xs:'14px', sm:'16px'}, color: "rgb(51, 201, 51)", pt: 2 }}></TableCell>
                  <TableCell sx={{ fontFamily:'Goldplay-bold',fontSize:{xs:'14px', sm:'16px'},  color: "rgb(51, 201, 51)", pt: 2 }}></TableCell>
                  <TableCell sx={{ fontFamily:'Goldplay-bold', fontSize:{xs:'14px', sm:'16px'}, color: "rgb(51, 201, 51)", pt: 2 }}></TableCell>
                </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default NewPlate;
